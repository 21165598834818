/***************************************************
    LIST PAGE
***************************************************/

.ideas-sorting {
  .nav-item {
    padding: 0;

    .nav-link {
      padding: 5px 7px;
    }
  }
}

.pv-collab {
  .big-button {
    display: block;
    width: 28%;
    height: 60px;
    margin: 3rem auto;
    font-size: 20px;

    &:active {
      background-color: #00529c;
      border-color: #00529c;
    }
  }

  .tags-item,
  .theme-item {
    margin-top: 20px;

    a {
      font-size: 13px;
      display: inline-block;
      margin: 5px 5px;
      background-color: $light-gray;
      padding: 5px 15px;
      color: #fff;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;

      &.active-tag,
      &.active-theme,
      &:hover {
        background-color: $blue;
        text-decoration: none;
        color: #fff;
      }
    }
  }

  #tags-button,
  #theme-button {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;

    &.active-tag,
    &.active-theme,
    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }

  .tags-item,
  .theme-item {
    //display: none;
  }
}

/*.card-section .row:nth-of-type(2) {
  margin-top: 32px;
}*/

.idea-card,
.pv-collab {

  .card {
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px !important;
    -webkit-box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
    box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
    margin-bottom: 20px;

    &-title {
      font-size: 20px !important;
      line-height: 28px !important;
      font-weight: bold !important;
      padding: 0 15px;
      a, a:visited{
        color: #000 !important;
      }
      &:hover{
        text-decoration: underline;
        a{
          color: #4B96AD !important;
        }
      }
    }

    &-header {
    }
  }

  .col-score {
    display: flex;
    @media (max-width: $screen-xs-max) { // Bootstrap 3 was $screen-phone now $screen-xs-max
      // Help rating label on top for small devices
      > p:first-child {
        margin: 5px 0 !important;

        * {
          display: block;

        }

        > small,
        > span {
          padding: 0;
          text-align: center;
        }
      }
    }


    small {
      display: inline-block;
      font-size: 70%;
      padding-right: 0.4em;
    }
  }

  .card-image {
    position: relative;
    max-height: 200px;
    display: block;
    z-index: 10;
    overflow: hidden;

    &:hover {
      opacity: 0.9;
    }
  }

  .card-img {
    border-radius: 5px 5px 0px 0px !important;
  }

  .card-tags,
  .card-icons,
  .card-score {
    position: absolute;

  }

  .card-tags {
    bottom: 10px;
    left: 10px
  }

  .card-score {
    color: #fff;
    top: 10px;
    right: 10px;
    font-size: 12px;
    text-transform: uppercase;

    span {
      display: block;
      font-size: 24px;
      -webkit-text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
      -moz-text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
      text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
    }
  }

  .card-icons {
    bottom: 10px;
    right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    font-size: 13px;

    .icon {
      i {
        margin-right: 5px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .card-tags,
  .card-score,
  .card-icons {

    //border-radius: 5px !important;
    //background-image: radial-gradient(black, rgba(255, 255, 255, 0)) !important;
    //background-size: cover;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.65) !important;
  }

  .card-theme-tag {
    color: #fff;
    font-size: 13px;
    font-style: italic;

    &:hover {
      text-decoration: none;
      color: $blue;
    }

    &:first-child:before {
      content: "";
    }

    &:before {
      content: " | ";
    }

  }

  .card-block {
    padding: 20px;
    z-index: 999;
    height: 380px;

    .card-title:hover {
      color: #4169A4;
    }
  }

  .card-subtitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    margin-top: 10px !important;
    color: $light-gray;

    .card-author {
      a {
        //color: $blue;
        &:hover {
          opacity: .8;
          text-decoration: none;
        }
      }
    }
  }

  /*.card-date {
    margin-left: 10px;
    color: $white;

    i {
      margin-right: 5px;
    }
  }*/

  .card-text {
    color: $light-gray;
    margin-bottom: 20px !important;
  }

  .card-button {
    display: block;
    width: 120px;
    height: 40px;
    cursor: pointer;
    font-size: 13px;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;

    text-align: center;
    line-height: 40px;
    font-weight: 400;
    background-color: $blue;
    color: #fff;
    outline: none;
    border-radius: 3px;
    text-decoration: none;
    -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;

    i {
      margin-left: 5px;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      color: #fff;
      opacity: .8;
      text-decoration: none;
    }
  }

  .btn-follow {
    color: $black;

    .fa {
      //font-size: 120%;
      //margin-right: 0.5rem;
      //vertical-align: middle;
    }
  }
}

.idea-like .special-idea card {
  @extend .h-md-100;
}


.idea-donate-cont {
  background: rgba(0, 0, 0, 0.6);

  .donate-bg {
    background-image: url('/images/demo/donation_bg.svg');
    background-size: 32%;
    background-position: center;
    background-attachment: fixed;

    .don-text {
      margin: 2.5rem auto;

      .btn-light {
        background-color: #fff;
        border-color: #fff;
      }
    }
  }
}

.replies-btn.no-replies {
  vertical-align: initial !important;
}

// List page default layout
.view-ideas {
  // Required for lightbox.
  .mfp-iframe-scaler iframe {
    min-height: 600px;
  }


}

.idea-page,
#ideas {

  a {
    text-decoration: none;
  }

  .idea-container {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

#ideas-page-body {
  .stats-section {
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }
}