@import "variables";
@import "form";
@import "item";
@import "detail";
@import "list";
@import "comments";
@import "misc";
@import "modules/modules";

#askus-container {

  margin-left: auto;
  margin-right: auto;
  padding: 0;
}