// BASE
$grid-columns:                12 !default;
$grid-row-columns:            6 !default;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

$screen-md-max: map-get($grid-breakpoints, 'lg') - 1;
$screen-sm-max: map-get($grid-breakpoints, 'md') - 1;
$screen-xs-min: map-get($grid-breakpoints, 'sm') - 1;

// Borders
$border-radius-base: 5px !default;
$border-width: 1px !default;
$border-radius: 5px !default;
$border-color : #ced4da;

// COLOURS
$black : #000 !default;
$blue: #4169A4 !default;
$white : #fff !default;
$text-dark : #343a40 !default;
$text-black : #000 !default;
$grey : #707070 !default;


$primary:       #3292D8 !default;
$secondary:     #EB7D66 !default;
$success:       #4B96AD !default;
$danger:        #F4728F !default;
$light:         #FAFDFF !default;
$dark:          #707070 !default;
$greyer:        #5F5F5F !default;
$saas : #146094;

// FONTS


// ICONS
$icon-path: '/layouts/publivate/imgs/';

$icon-sizes: (
  //amount: size
        10: 80,
        20: 80,
        50: 80,
        100: 80
);

// Cards

$card-spacer-y:                     .9375rem !default;
$card-spacer-x:                     .9375rem !default;
$card-border-width:                 $border-width !default;
$card-border-radius:                $border-radius !default;
$card-border-color:                 rgba($black, .125) !default;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:                       transparent !default;
$card-cap-color:                    null !default;
$card-color:                        null !default;
$card-bg:                           $white !default;

$card-img-overlay-padding:          1.25rem !default;

$card-group-margin:                 $grid-gutter-width / 2 !default;
$card-deck-margin:                  $card-group-margin !default;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;

$enable-rounded: true;

// Mixins
@mixin border-radius($radius: $border-radius, $fallback-border-radius: false) {
  @if $enable-rounded {
    border-radius: $radius;
  }
  @else if $fallback-border-radius != false {
    border-radius: $fallback-border-radius;
  }
}

@mixin hover {
  &:hover { @content; }
}

@mixin tablet-and-up {
  @media screen and (min-width: 769px) { @content; }
}
@mixin mobile-and-up {
  @media screen and (min-width: 601px) { @content; }
}
@mixin tablet-and-down  {
  @media screen and (max-width: 768px) { @content; }
}
@mixin mobile-only {
  @media screen and (max-width: 600px) { @content; }
}

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3)
                ),
                $spacers
);
// FORMS
$form-field-border-radius : $border-radius-base !default;

// Components specific

$sidebar-1-bg-color: #26374A  !default;
$pv-collab-theme: #146094 !default;
$pv-challenge-bg-color: $pv-collab-theme !default;
$add-idea-border-color : $pv-collab-theme !default;

// LOGIN / REGISTRATION
$input-btn-padding-y-sm: 0.25rem !default;

// Optional
$bootstrap3 : true !default; // If template is bootstrap3 use bootstrap4 polyfills

$font-family-sans-serif: sans-serif;
$font-family-base : $font-family-sans-serif;
$headings-font-family: $font-family-base;
$btn-font-family: $font-family-base;