/***************************************************
    DETAIL PAGE
***************************************************/

// Askus Detail page
#askus-detail {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .btn-primary {
    background-color: $askus-color !important;
  }

  .askus {

  }

  .askus-comments {
    padding: 0 !important;

    .comment {
      margin: 1rem 0;
      padding: 1rem;

      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }

    }
  }
}