/*
  Comment
 */
/*.comment-section {
  margin-top: 50px;
  h3 {
    font-size: 24px !important;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.comment {
  margin-bottom: 30px;
  clear: both;
}*/

.comment-head,.reply-head {
  display: flex;
  justify-content: space-between;
  .avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .author {
    color: #3E65A6;
    font-weight: 600 !important;
    text-decoration: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    &:hover {
      opacity: .8;
    }
  }
  .date {
    margin-left: 10px;
  }
}

.comment-body {
  margin-left: 40px;
  p {
    margin-bottom: 10px !important;
    max-width: 500px;
    font-weight: 400;
    margin-top: 0 !important;
  }
}

.comment-button {
  button {
    border: none;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    margin-bottom: 1.2rem;
    i {
      color: #3E65A6;
      margin-right: 5px;
    }
    &:nth-child(2) {
      margin-left: 20px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: .8;
    }
  }
}

.comment-button-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .back {
    border: none;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    i {
      margin-right: 10px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: .8;
    }
  }
}

/* Submit Anonymous */
.span_pseudo, .anonymously_cb span:before, .anonymously_cb span:after {
  content: "";
  display: inline-block;
  background: #fff;
  width: 0;
  height: 0.2rem;
  position: absolute;
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.anonymously_cb {
  position: relative;
  height: 20px;
  //max-width: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}

.anonymously_cb input:checked ~ span {
  background: #3E65A6;
  border-color: #3E65A6;
}

.anonymously_cb input:checked ~ span::before {
  width: 1rem;
  height: 0.15rem;
  -webkit-transition: width 0.1s;
  -o-transition: width 0.1s;
  transition: width 0.1s;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.anonymously_cb input:checked ~ span::after {
  width: 0.4rem;
  height: 0.15rem;
  -webkit-transition: width 0.1s;
  -o-transition: width 0.1s;
  transition: width 0.1s;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.anonymously_cb input:disabled ~ span {
  background: #ececec;
  border-color: #414141;
}

.anonymously_cb input:disabled ~ label {
  color: #dcdcdc;
}

.anonymously_cb input:disabled ~ label:hover {
  cursor: default;
}

.anonymously_cb label {
  padding-left: .5rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0;
}

.anonymously_cb span {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #414141;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 1;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.anonymously_cb span::before {
  -webkit-transform: rotate(-55deg);
  -ms-transform: rotate(-55deg);
  transform: rotate(-55deg);
  top: 1rem;
  left: 0.37rem;
}

.anonymously_cb span::after {
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
  bottom: 0.35rem;
  left: 0.2rem;
}

// This affecting all forms
//.form-control {
//  border: 1px solid #808080;
//  color: #808080;
//  padding: 20px;
//  margin-bottom: 20px;
//  font-size: 1.6rem;
//}

.default-comment {
  a.report-abuse {
    font-family: Montserrat, sans-serif;
  }
  span.timestamp {
    font-size: 0.8rem;
  }
}

#comments-form {
  label[for=comment] {
    font-family: Montserrat, sans-serif;
    margin-bottom: 15px;
  }
}

.default-comment {
  a.report-abuse {
    font-family: Montserrat, sans-serif;
  }
  span.timestamp {
    font-size: 0.8rem;
  }
}

#comments-form {
  label[for=comment] {
    font-family: Montserrat, sans-serif;
    margin-bottom: 15px;
  }
}