.password-reset-confirm-page,
.password-reset-page {
  .required:before{
    content: '';
  }
}


#pv_gmap{
  width: 100%;
  height: 320px;
}