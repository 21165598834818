
.add-idea-form {

  .btn-primary {
    background-color: $pv-challenge-bg-color !important;
    border-color: $pv-challenge-bg-color !important;
    width: 100%
  }
  fieldset{
    margin: 0;
    padding: 0;
    .card-header{
      border-radius: 0;
      background-color: #146094;
    }
  }
  .form-group{
      .mce-panel,.mce-container{
        font-size: 16px !important;
      }
  }
  input, select, textarea,.mce-panel *{
    font-size: 16px !important;
  }
  .mce-panel label{
    font-size: 1rem !important;
  }
}

.abuse-improvement-type {
  .btn-primary {
    background-color: $pv-challenge-bg-color !important;
    border-color: $pv-challenge-bg-color !important;
  }

}

