.widget-type-survey {
  .embed-survey {
    iframe {
      //min-height: 400px;
      .question-text *{
        font-size: 19.2px;
      }
    }
  }
}

.view-flow.layout-survey{
  iframe.iframe-resizer {
    min-height: 500px;
  }
}

.consultation-survey-wrapper {
  &.embed-survey {

    iframe {
      min-height: 400px !important;
      .question-text *{
        font-size: 19.2px;
      }
    }
  }

  //background images
  background-color: transparent;

  iframe.first-page {
   // min-height: 400px !important;
  }
}



