// Component only window
.component.window {
  .askus-detail {
    header {
    }
  }
}

// Report page
.askus-report-abuse {
  .captcha-box {
    margin-top: 10px;

    #jform_captcha {
      min-height: 120px;
    }
  }
}