// Challenge Module

#pv-challenge-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0 !important;
  h1{
    border-bottom: 0;
  }
}

.pv-collab.mod-challenge {
  //background-color: $pv-challenge-bg-color;
  padding: 1rem;

  .page-header {
    margin-top: 0;
    border-bottom: 0;
    flex-direction: row;

    .challenge-icon-col {
      align-self: center;
      //font-size: 64px;
      //width: 64px;

      @media (max-width: $screen-xs-max) {
        align-self: initial;
        //font-size: 2rem;
      }
    }

    h1 {
      color: $challenge-text-color;
      border: 0;
    }
  }

  p {
    color: $challenge-text-color;
  }

  #idea-desc{
      resize: none;
      width: calc(100% - 45px);
      height: auto;
    &::placeholder{
      padding: 2.8rem;
      font-size: 14px;
      text-align: center;
      @include mobile-only{
        padding: 1.2rem 0;
      }
    }
  }


  #add-idea {
    box-shadow: none;
    background-color: $add-idea-background-color;
    border-color: $add-idea-border-color;
    color: $add-idea-font-color;


    &:focus,
    &:hover {
      color: darken($add-idea-font-color, 15%);
    }
  }
}

.mod-challenge,
.mod-challenge-countdown {

  // @deprecated
  @include media-breakpoint-down(xs)  {
    // Correcting issue with small screens bleeding clock outside it parent elements.
    > div.d-flex {
      display: block !important;
    }

  }

  .countdown_row {
    @extend .d-sm-flex;
  }

  .time-remaining {
    max-width: inherit !important;
  }

  a {
    color: $white;
  }
}

.mod-challenge-countdown {
  border-radius: $border-radius-base;
  border: 2px solid $pv-collab-theme;
  padding: 0.60rem;
  text-align: center;

  .d-flex-countdown-clock {
    align-items: center;
    justify-content: center;
    /*margin-left: auto;
    margin-right: auto;*/
  }

  .countdown-title {
    font-size: 15px;
    font-style: italic;
  }

  .countdown_section {
    display: block;
    font-size: 90%;
    font-style: normal;

    .countdown_amount {
      font-size: 110%;
      font-style: italic;
      padding: 0 1rem;
      @media (max-width: $screen-xs-max) {
        padding: 0.75rem;
      }
    }
  }

  &.mod-challenge-info {
    border: none;
    border: 0;
    padding: 0;
    margin: 1rem 0;
  }
}

.mod-challenge-info {
  .btn-challenge-info {
    color: $pv-collab-theme;
    font-weight: bold;

    &.collapsed {
      .fa:before {
        content: '\f0da';
      }
    }

    .fa:before {
      content: "\f0d7";
    }
  }

  .mod-challenge-detail-wrapper {
    border-radius: $border-radius-base;
    border: 2px solid $pv-collab-theme;
    margin: 1rem 0;
    padding: 1rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li.item-label,
    li.nav-label {
      border: none;
      border-radius: 0;
      color: $pv-collab-theme;
      font-weight: bold;
      padding: .375rem .75rem;
    }

    li,
    .list-item {
      border: 1px solid #eee;
      border-radius: .2rem;
      display: flex;
      padding: 0;

      .value {
        background-color: #707070;
        border-radius: .2rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: $white;
        display: block;
        padding: .375rem .75rem;
      }

      .text {
        display: block;
        padding: .375rem .75rem;
      }
    }
  }

  .mod-challenge-info-tags {
    a.tag {
      color: $black;
      display: flex;
      padding: 0;

    }
  }

  .mod-challenge-detail-info {
    margin: 1rem 0 0;

    section {
      border: 1px solid #707070;
      border-radius: $border-radius-base;
      padding: .375rem .75rem;
    }

    section:nth-child(2) {
      margin-top: 1rem;
    }
  }
}
