.flex-wrap {
  flex-wrap: wrap;
}

.p {
  &x-3 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &t-3 {
    padding-top: 1rem;
  }

  &t-4 {
    padding-top: 1.5rem;
  }

  &b-0 {
    padding-bottom: 0;
  }

  &b-1 {
    padding-bottom: .25rem;
  }

  &b-3 {
    padding-bottom: 1rem;
  }

  &y-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &t-0 {
    padding-top: 0 !important;
  }

  &l-0 {
    padding-left: 0 !important;
  }

  &r-0 {
    padding-right: 0 !important;
  }

  &-3 {
    @extend .px-3, .py-3;
  }
}

.m {
  &t-0 {
    margin-top: 0 !important;
  }

  &y-3 {
    margin-top: 1rem;
  }

  &t-3 {
    margin-top: 1rem;
  }

  &t-5 {
    margin-top: 1.75rem;
  }

  &t-6 {
    margin-top: 2.75rem;
  }

  &l-auto {
    margin-left: auto;
  }

  &x-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  &b-1 {
    margin-bottom: .25rem;
  }

  &y-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  &y-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  &y-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &b-2 {
    margin-bottom: .5rem;
  }

  &b-3 {
    margin-bottom: 1rem;
  }
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.text {
  &-dark {
    color: $text-dark !important;
  }

  &-black {
    color: #000 !important;
  }

  &-light {
    color: #fff !important;
  }

  &-muted {
    color: #757575;
  }
}

.font-weight-bold {
  font-weight: 700 !important;
}

.float-right {
  float: right !important;
}

.position-relative {
  position: relative;
}

.d-flex {
  display: flex !important;

  &.justify-content-end {
    justify-content: flex-end;
  }
}

@include media-breakpoint-up(lg) {
  .flex-lg-row {
    flex-direction: row;
  }
  .d-lg-flex {
    display: flex;
  }
  .text-lg-left{  text-align: left; }
  .text-lg-right{  text-align: right; }

}


@include media-breakpoint-up(md) {
  .flex-md-column {
    flex-direction: column
  }
  .mt-md-6 {
    margin-top: 2.75rem;
  }
  .pull-md-right{
    float: right;
  }
}

@include media-breakpoint-up(sm) {
  .d-sm-flex {
    display: flex;
  }
}

.card-icon-action {
  height: 40px;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}

@include media-breakpoint-down(sm) {
  .mt-sm-2 {
    margin-top: 1rem;
  }
  .text-sm-left{
    text-align: left;
  }
}
