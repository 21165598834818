/*.discussion-topic-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px -15px !important;
}*/
.gallery-container .carousel-inner img {
	max-height: 500px;
	margin:0 auto;
}
.gallery-container .carousel-indicators li a > div {
	width: 150px;
	border: 2px solid #26374a;
	height:150px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.gallery-container .carousel-indicators li p {
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

 /*default_widget_gallery.php*/
 .sidebar-images{
 	width:100%;
 	height:150px;
 	background-size:cover;
 	background-position:top center;
}
/*default_widget_dialoguesponsore.php*/
.list .list-inline {
        display: none;
        margin-bottom: 25px;
        align-items: flex-start;
    }
    .list  .list-inline a { text-decoration: none; }
    .list  .list-inline img {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        margin-right: 20px;
    }
    .list-inline .info span { display: block; text-align: left; line-height: 1.4; }

    .list {
    margin: 30px auto 0;
    width: 100%;
    box-sizing: border-box;
}
.list-element {
    width: 100%;
    margin: 25px 0px;
    font-family: arial;
    border-radius: 50px;
    padding: 10px 0px;
    color: #2196f3;
    font-size: 20px;
    text-align: center;
    box-shadow: 0px 0px 6px 0px #848484;
    display: none;
}
#loadmore {
    margin: 20px auto 0px;
    display: table;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    color: #2196f3;
    border-radius: 4px;
    background-color: transparent;
    text-decoration: underline;
}
.list .list-inline:nth-child(1) {
    display: flex;
}
.list .list-inline:nth-child(2) {
    display: flex;
}
.list .list-inline:last-child { margin-bottom: 0px; }
.list .list-inline:nth-child(3) {
    display: flex;
}
.list .list-inline:nth-child(4) {
    display: flex;
}
.list-inline .info {
    width: calc(100% - 80px);
    text-align: left;
    /*word-break: break-all;*/
}
/* commenting out - breaking other widgets */
/*.widget-description p {
    word-break: break-all;
}*/
/*default.php*/
.consultation-desc img {
    max-width: 100% !important;
    height: auto !important;
}
/*default_widget_socialmedia.php*/
.social-icons {
    display: flex;
}
.social-icons a {
    padding: 0 10px 0 0;
}
.social-icons a img {
    width: 47px;
    box-shadow: 0px 0px 3px rgb(0, 0, 15); // replace 3 / %20 with 15
    border-radius: 45px;
}
.social-icons a:last-child {
    padding-right: 0;
}

.widget .wrapper .consultation-resources ul>li>div.document {
    border-bottom: none !important;
    border-top: 1px solid #000;
}

.widget .wrapper .consultation-resources ul>li:first-child>div.document {
    border-bottom: none;
    border-top: none;
}


.slide-dots {width:100%;height:120px;background-size:cover;background-position:top center;}
.slide-dot-name {margin: 5px 0px 0px;font-weight: bold;font-size: 17px;}
#galleryslider {
    margin: 0;
}
.slide-big-image {width:100%;height:700px;background-size:cover;background-position:top center;}
@media (max-width:767px) {
    .slide-big-image {height:190px;}
    .slide-dots {background-size:contain;height:50px;}
}