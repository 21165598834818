.consultation{
  h2{
    margin-bottom: 1.8rem;
  }
  @include tablet-and-up{
    p{
      padding-left: 1.8rem;

    }
  }

}
.resources-container {
  @include tablet-and-up {
    display: flex;
    flex-wrap: wrap;
  }

  table {
    &.w-auto {
      width: auto;
    }
    &.files-table{
      float: none;
    }

    thead > tr > th {
      border: 0;
      width: 20%;
      cursor: pointer;

      &:first-child {
        width: 80%;
      }

      .glyphicon {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    tbody {
      > tr {
        &:first-child td {
          border: 0;
        }

        > td {
          vertical-align: middle;
          a{
            font-size: 12px;
          }
        }
      }
      &.open {
        display: table-row-group;
      }

      &.closed {
        display: none;
      }
    }
  }
}

//Program - General Resources

.general-resources{

  &.logged-in{
    margin-top: 2.3rem;
  }
  .wrapper{
    padding: 1.5rem 0;
    border: 1px solid $border-color !important;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0,0,0,.25)!important;
    border-radius: 5px;
    h3{
      margin-top: 0;
    }
    ol{
      padding: 0 1.8rem;
      margin-bottom: 0;
      > li{
        padding: .5rem 0;
        border-bottom: 1px solid #000;
        font-family: sans-serif;
        line-height: 1.4;
        font-size: 16px;
        &:last-child{
          border-bottom: 0;
        }
      }
    }
  }
}

