#carousel-video-resources {
  .list-inline {
    //white-space: nowrap;
    overflow-x: auto;

  }
  .carousel-indicators {
    position: static;
    left: initial;
    width: initial;
    margin-left: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    > li {
      width: initial;
      height: initial;
      text-indent: initial;
      border-width: 1px;
      max-width: 150px;
      &.active img {
        opacity: 0.7;
        border: 4px solid #007faa;
      }
      p.small{
        min-height: 95px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &.shadow{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  }
}


