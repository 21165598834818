/*
  Individual card item
 */

.learn-card{
  color: $learn-card-header-font-color !important;
  background-color: $learn-card-header-bg-color !important;
}

.event-card {
  color: $event-card-header-font-color !important;
  background-color: $event-card-header-bg-color !important;
}