#sidebar-right {
  @include sticky()
}

main {
  font-family: $font-family-base;
  font-size: $font-size-base;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $headings-font-family;
}

.btn {
  font-family: $btn-font-family;
  &-primary{
    border-color: #26374a;

    &:hover{
      border-color: #16446c;

    }
  }
}

a.btn-primary:hover{
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

#wb-bc{
  .container{
    border: 0;
    .breadcrumb{
      background-color: #fff;
    }
  }
}