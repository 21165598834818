
.timeline{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; //0 0 3rem;
  ul, li{
    list-style: none;
    padding: 0;
  }
  .wrapper{
    background: #f8f9fa;
    padding: 2rem;
    border-radius: 15px;
  }
  h1{
    font-size: 1.1rem;
    font-family: sans-serif;
    border-bottom: 1px solid #000;
  }
  .sessions{
    margin-top: 1.5rem;
    border-radius: 12px;
    position: relative;
  }
  li{
    padding-bottom: 1.5rem;
    border-left: 1px solid #6c757d;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
    transition: all .3s ease;
    &:last-child{
      border: 0px;
      padding-bottom: 0;
    }
    &.active{
      &:before{
        content: '';
        width: 15px;
        height: 15px;
        background: white;
        border: 1px solid #6c757d;
        box-shadow: 3px 3px 0 #6c757d;
        border-radius: 50%;
        position: absolute;
        left: -8px;
        top: 10px;
      }
    }
    &.complete{
      &:before{
        content: '';
        width: 20px;
        height: 20px;
        background: #000;
        border: 1px solid #000;
        //box-shadow: 3px 3px 0 #6c757d;
        border-radius: 50%;
        position: absolute;
        left: -11px;
        top: 10px;
      }
      &:after{
        content: "\2713" ;
        position: absolute;
        left: -9px;
        top: 2px;
        color: #adb5bd;
        font-size: 30px;
        font-weight: 700;
      }
    }
  }
  .time{
    color: #2a2839;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    @include mobile-and-up{
      font-size: 18px;
    }
    @include mobile-only{
      margin-bottom: .3rem;
      font-size: 0.85rem;
    }
  }
  small{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }
  p{
    color: #4f4f4f;
    font-family: sans-serif;
    line-height: 1.4;
    margin-top:0.4rem;
    font-size: 16px;
    @include mobile-only{
      font-size: 16px;
    }
  }
}
