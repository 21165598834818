.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;

  & > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
  }
}