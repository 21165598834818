.pv-help {
  // PV Help Base CSS class, if you need to change appear be careful as this is used in multiple places
  color: #FFF;
  margin-right: 0;
  opacity: 1;
  position: relative;
  text-shadow: 0 0 1px #fff;
  z-index: 20;

  ul {
    margin: 0;
    padding: 0 0 0 1em;
    font-size: 12px;
  }

  a:hover .pv-help-inner {
    display: block !important;
  }

  &-inner {
    background: none repeat scroll 0 0 rgba(38, 55, 74, 1);
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 8px #888888;
    color: #FFFFFF;
    display: none;
    min-width: 320px;
    opacity: 1;
    padding: 10px !important;
    position: absolute;
    text-align: left;
    text-shadow: 0 0px 1px #ffffff;
    width: inherit;
    z-index: 20;

    *{
      color: #EEE;
    }
  }

  &-right {
    .pv-help-inner{

    }
  }

  &-left {
    .pv-help-inner{
      left: inherit;
      right: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .pv-help-inner{
      font-size: 85%;
    }
  }

  &.pv-help-wide .pv-help-inner{
    width: 600px;
    @include media-breakpoint-down(sm) {
      min-width: 100%;
      width: 100% !important;
    }
  }

  &.inline{
    display:inline-block;
    padding: 0 1em;
  }
}

//.no-touch .pv-help a:focus .pv-help-inner,
//.no-touch .pv-help a:hover .pv-help-inner{
//  display: block;
//  width: inherit;
//}

/* PLACE OTHER HELP ICON CONTENT POSITIONS HERE */
/** For Help Icons on the right side of the page **/

label .pv-help{
  display: inline-block;
}
