/***************************************************
    LIST PAGE
***************************************************/

// Askus Answers layout
#askus-answers {
  font-family: $font-family-base !important;

  .askus {
    &-filter-row {
      display: flex;
      .pv-help {

      }
    }

    &-sort-by {
      flex-grow: 3;

      ul {
        margin-left: 0;
        padding: 0;
        li {
          display: inline-block;
          padding: 0.5rem 1rem 0 0;
          margin: 0;

          @include media-breakpoint-down(sm) {
            display: block;
          }

          label {
            display: inline-block;
            min-width: 80px;
            text-align: right;
          }

          select {
            display: inline-block;
            padding-right: 5px;
          }

        }

        li a {
          color: $link-color;
          font-weight: 500;
          padding-left: 1rem;
          padding-right: 1rem;

          &.active {
            font-weight: 700;
          }
        }
      }
    }

  }

  .askus-container {
    margin: 15px 0;
    background: white;
  }

  .answers-row > *:nth-child(odd) {
    clear: left;
  }

  .askus-no-questions {
    border: 1px solid $grey;
    margin: 1rem 0 ;
    padding: 1rem 1.25rem;
  }

  .paging {
    display: flex;

    .page {
      margin-right: 0.75rem;

      &.active {
        font-weight: bold;
      }
    }
  }

}

@include media-breakpoint-down(sm){
  #askus-answers .askus-sort-by {
    float: left;
    select {
      width: 100%;
    }
  }
}
