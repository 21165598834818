.page-header {
  margin: 40px 0 20px;
}

.pv-pagination{
  .paging{
    a, span{
      padding: 0.8rem;
    }
    @include media-breakpoint-down(sm) {
      a, span {
        padding: 1rem;
        font-size: 1.4rem;
      }
    }
  }
}