/** LOGIN PAGE **/
.login-page,
.cbLoginForm {
  max-width: 500px;

  .form-horizontal .form-group {
    margin-left: 0;
    margin-right: 0;

  }

  .control-label{
    @extend .pr-1 !optional;
    text-align: left;
  }

  label.required:before {
    content: '';

  }

  /** LOGIN MODULE(CB) **/
  input[type="checkbox"] {
    background: #000;
    color: #f0f0f0;
    height: auto;
  }

  .btn {

  }

  .input-group {
    width: 100%;
  }

  .input-group input {
    display: inline-block;
    max-width: 90%;
    width: 100%;
  }

  .input-group .input-group-addon {
    width: 10%;
  }

  .form-login-remember input {
    display: inline-block;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    width: 15px;
  }

  ul.unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.unstyled li:first-child {
    margin-bottom: 10px;
  }

  ul.unstyled a.btn {
    border: none;
    text-decoration: underline;
    margin: 0;
  }

  ul.unstyled a.btn:hover {
    text-decoration: none;
  }

  .input-prepend input {
    padding-left: 5px;
  }

  .cbLogoutButtonSpan,
  .cbLoginButtonSpan {
    display: block;
  }

  .cbLoginForgotButtonSpan {
    margin-top: $input-btn-padding-y-sm;
  }

  .mod_login_password-mobile {
    margin-bottom: $input-btn-padding-y-sm;
  }

  #mod_login_password-bg {
    margin-bottom: 1em;
  }

  .btn,
  .form-login-links .btn-lg,
  .login-submit .btn-lg {
    min-height: 30px;
    line-height: 30px;
    min-width: 100%;
  }

}


@media screen and (max-width: 768px) {
  .login-page  input[type="checkbox"],
  .cbLoginForm input[type="checkbox"] {
    background: #000;
    color: #f0f0f0;
    height: auto;
    position: initial;
    height: initial !important;
    width: auto;
    margin: 4px 5px 0 0;
  }

  .login-page .checkbox label ,
  .cbLoginForm .checkbox label {
    display: inline-block;
    padding-left: 0px !important;
  }
  .login-page {
    input:not([type="checkbox"]){
      width: 100%
    }

    .form-group {
      .col-xs-12 {
       padding-left: 0;
       padding-right: 0;
      }

      label.required:before {
        margin-left: 0;
      }
    }
  }
}

.cbLoginForm-mobile.cbLoginForm .cbLoginButtonSpan {
  display: inline-block !important;
  margin-left: 10px;
}

/* Cb Registration and Profile */

.cbLoginButtonSpan {
  margin-bottom: 10px;
}

// @deprecating
.cbLogoutButtonSpan {
  float: right;
}

#guestmessage .cbLoginForm {
  max-width: 100%;
}

#guestmessage .cbLoginForm .form-login-links {
}

#guestmessage .cbLoginForm .form-login-links li {
  display: inline-block;
  margin: 5px 10px 5px 0
}

#guestmessage .cbLoginForm .form-login-links li a {
  line-height: 35px;
}

#guestmessage .cbLoginForm .form-login-register {
  float: right;
}

#guestmessage .form-login-links .form-login-forgot a {
  line-height: 34px;
  box-shadow: none;
}

.login-page .btn,
.cbLoginButtonSpan .btn-default,
.cbLoginForgotButtonSpan .btn-default,
.cbLoginRegisterButtonSpan .btn-default {
  margin-top: 5px;
}

