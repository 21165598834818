.cbLoginForm,
form,
.form {
  select,
  input[type="text"],
  input[type="password"],
  texarea
  .btn,
  form-control {
    border-radius: 5px;
  }

  .input-group {
    .input-group-addon {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

    }

    input[type="password"],
    input[type="text"] {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

    }
  }

  .control-group {
    margin-bottom: 10px;
  }
}

//Registration

@include tablet-and-up {
  .d-md-flex {
    display: flex;
  }
}

#member-registration {
  .form-group {
    @extend .d-md-flex;
    @extend .align-items-center;

    #jform_com_fields_org_type {
      label {
        border: 0;
        box-shadow: none;
        display: inline-block;
        padding: 0;
        font-weight: 700;
        @include tablet-and-down {
          height: 100%;
          margin-bottom: 1.2rem;
          float: left;
        }
      }

      input[type="radio"] {
        display: inline-block;
        transform: translateY(14px);
        margin: 0 .5rem;
        position: relative;
        box-shadow: none;
        @include tablet-and-down {
          width: auto;
          float: left;
          transform: translateY(-5px);
          @supports (-webkit-touch-callout: none) {
            width: 15px;
          }
        }
      }
    }

    #jform_com_fields_show_profile {
      box-shadow: none;
    }

    #jform_com_fields_allow_notifications label, #jform_com_fields_notify label {
      font-weight: 400;
    }

    input[type="checkbox"] {
      margin: 0 .5rem !important;
    }

    @include mobile-only {
      .px-sm-3 {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      .control-label {
        margin-bottom: 15px;

        label {
          display: inline;
        }
      }
      &.show-profile {
        display: inline-flex;

        .controls {
          width: 25%;
        }
        &-fr{
          display: flex;
        }
      }
    }

    @include tablet-and-down {
      #jform_com_fields_org_type-lbl {
        margin-bottom: 1.8rem;
      }
    }
  }
}
