.card-footer__share{
  position: absolute !important;
  bottom: 100%;
  right: 0;
  background: #FFFFFF;
  /* Card Shadow */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  flex-direction: row;
  //display: inline-block;
  padding: 16px;

  .card-footer__btn-close {
    color: light($black, 10%);
    font-size: 10px;
    line-height: 1;
    min-height: 18px;
    min-width: 18px;
    position: absolute;
    right: 0;
    top: 0;
    &:hover{
      color: $black;
      text-decoration: none;

    }
  }

  // Unknow css usage
  ul,
  .first{
    flex: none;
    order: 0;
    align-self: center;
    display: flex;
    margin: 0; padding: 0;


    .social{
      flex: none; order: 1;
      align-self: flex-start;
      margin: 0 8px;
      &:first-child {
        margin-left: 0;
      }


      > a > img{ border-radius: 5px;
        height: 1.2rem;
      }
      > a:focus {
        outline-offset: 2px;
      }
    }
  }

  .second{
    flex: none; order: 1;
    display: inline-block;
    align-self: center;
    padding: 0; margin: 16px 7px 0;
    p{
      font-style: normal; font-weight: normal; font-size: 12px; font-family: 'Lato', sans-serif;
      line-height: 14px;
      color: $grey;
      margin-bottom: 0;
    }
  }
}