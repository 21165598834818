#system-message-container {
    border: 1px groove $black;
    border-radius: $border-radius-base;
    margin: 1rem;
    padding: 1rem 1rem;

    .close {
      text-decoration: none;
    }

    .alert:last-child {
      margin-bottom: 0;
    }

    ol {
      font-weight: bold;
    }
}