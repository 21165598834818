@charset "UTF-8";
@import "../../../media/jquery/jquery-ui-1.11.4/jquery-ui.css";
@import "../../../media/alertify.js/themes/alertify.core.css";
@import "../../../media/alertify.js/themes/alertify.default.css";
@import "../../../media/datetimepicker/jquery.datetimepicker.css";
@import "../../../media/editors/tinymce/plugins/wordcount/wordcount.css";
@import "../../../media/jquery/select2/css/select2.css";
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.input-group[class*=col-] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.input-group .form-control, .input-group .story-form2 .editor, .story-form2 .input-group .editor {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.input-group .form-control:focus, .input-group .story-form2 .editor:focus, .story-form2 .input-group .editor:focus {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control,
.input-group .story-form2 .editor,
.story-form2 .input-group .editor {
  display: table-cell;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child),
.input-group .story-form2 .editor:not(:first-child):not(:last-child),
.story-form2 .input-group .editor:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555555;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.input-group-addon.input-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}
.input-group-addon.input-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px;
}
.input-group-addon input[type=radio],
.input-group-addon input[type=checkbox] {
  margin-top: 0;
}

.input-group .form-control:first-child, .input-group .story-form2 .editor:first-child, .story-form2 .input-group .editor:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child, .input-group .story-form2 .editor:last-child, .story-form2 .input-group .editor:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
  z-index: 2;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center, #member-registration .form-group {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.flex-wrap {
  flex-wrap: wrap;
}

.px-3, .stories-inner, .p-3, .pv-stories-page #add-story-form-btn {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pt-3 {
  padding-top: 1rem;
}
.pt-4 {
  padding-top: 1.5rem;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-3 {
  padding-bottom: 1rem;
}
.py-3, .p-3, .pv-stories-page #add-story-form-btn {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pt-0 {
  padding-top: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.my-3 {
  margin-top: 1rem;
}
.mt-3, .pv-stories-page #pv-stories {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.75rem;
}
.mt-6 {
  margin-top: 2.75rem;
}
.ml-auto {
  margin-left: auto;
}
.mx-1, .pv-stories-page .sort-by-links a.sort-by {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mb-1, .story header {
  margin-bottom: 0.25rem;
}
.my-1, .story .card-title small, .story-tags {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-2, .story .row {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mb-2, .story-form2 {
  margin-bottom: 0.5rem;
}
.mb-3, .story-form2 .story-media-col, .story-form2 .row {
  margin-bottom: 1rem;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.text-dark {
  color: #343a40 !important;
}
.text-black {
  color: #000 !important;
}
.text-light {
  color: #fff !important;
}
.text-muted {
  color: #757575;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.float-right {
  float: right !important;
}

.position-relative {
  position: relative;
}

.d-flex {
  display: flex !important;
}
.d-flex.justify-content-end {
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row;
  }
  .d-lg-flex {
    display: flex;
  }
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
}
@media (min-width: 768px) {
  .flex-md-column {
    flex-direction: column;
  }
  .mt-md-6 {
    margin-top: 2.75rem;
  }
  .pull-md-right {
    float: right;
  }
}
@media (min-width: 576px) {
  .d-sm-flex, .mod-challenge .countdown_row,
  .mod-challenge-countdown .countdown_row {
    display: flex;
  }
}
.card-icon-action {
  height: 40px;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

@media (max-width: 767.98px) {
  .mt-sm-2 {
    margin-top: 1rem;
  }
  .text-sm-left {
    text-align: left;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex, .mod-challenge .countdown_row,
  .mod-challenge-countdown .countdown_row {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex, #member-registration .form-group {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.container, footer#wb-info .custom, .menu-bg-color .custom,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container, footer#wb-info .custom, .menu-bg-color .custom {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container, footer#wb-info .custom, .menu-bg-color .custom {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container, footer#wb-info .custom, .menu-bg-color .custom {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container, footer#wb-info .custom, .menu-bg-color .custom {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1,
.story .card-title small,
.story-tags {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1,
.pv-stories-page .sort-by-links a.sort-by {
  margin-right: 0.25rem !important;
}

.mb-1, .story header,
.my-1,
.story .card-title small,
.story-tags {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1,
.pv-stories-page .sort-by-links a.sort-by {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2,
.story .row {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2, .story-form2,
.my-2,
.story .row {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .pv-stories-page #pv-stories,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .story-form2 .story-media-col, .story-form2 .row,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1, .login-page .control-label,
.cbLoginForm .control-label,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3, .pv-stories-page #add-story-form-btn {
  padding: 1rem !important;
}

.pt-3,
.py-3,
.p-3,
.pv-stories-page #add-story-form-btn {
  padding-top: 1rem !important;
}

.pr-3,
.px-3,
.stories-inner,
.p-3,
.pv-stories-page #add-story-form-btn {
  padding-right: 1rem !important;
}

.pb-3,
.py-3,
.p-3,
.pv-stories-page #add-story-form-btn {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3,
.stories-inner,
.p-3,
.pv-stories-page #add-story-form-btn {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3,
  .menu-bg-color .custom {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3,
  .menu-bg-color .custom {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.gcweb-menu {
  margin: 0;
  border-top: 0;
}

.menu-bg-color {
  background-color: #444;
  color: #fff;
  margin: 0;
  padding: 10px 15px;
}
.menu-bg-color * {
  margin: 0 auto;
  border: 0;
  padding: 0 !important;
}
@media (max-width: 991px) {
  .menu-bg-color * {
    font-size: 1.5rem;
  }
}

#page-content {
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}
#t3-main-content {
  padding-bottom: 15px;
}

#wb-bc li {
  padding-left: 0;
  padding-right: 0;
  vertical-align: middle;
}
#wb-bc li:before {
  color: #333;
  content: "\e092";
  font-family: "Glyphicons Halflings";
  font-size: 0.7em;
  top: 0;
}
#wb-bc li:first-child a {
  padding-left: 0;
  padding-right: 0;
}
#wb-bc li.active {
  padding: 8px 0;
}

/* Back to the top */
.to-top {
  position: fixed;
  bottom: 2rem;
  right: 0;
  background-color: black;
  opacity: 0.5;
  padding: 1.5rem 2rem;
  z-index: 1000;
}
.to-top a span {
  color: white;
}

.embed-survey {
  height: 100%;
}

.view-flow .embed-survey iframe {
  min-height: 750px;
}

#search-wrapper .search .form-control, #search-wrapper .search .story-form2 .editor, .story-form2 #search-wrapper .search .editor {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

footer#wb-info .custom {
  padding-top: 0.4rem;
}

table tr.table_row_top_border td {
  border-top: 0;
}
table tr.table_row_left_border td {
  border-left: 0;
}
table tr.table_row_right_border td {
  border-right: 0;
}
table tr.table_row_bottom_border td {
  border-bottom: 0;
}
table tr.table_row_no_border td {
  border: 0;
}
table tr.table_row_side_border td {
  border-left: 0;
  border-right: 0;
}

@media (min-height: 300px) {
  #sidebar-right {
    position: sticky;
    top: 1rem;
    z-index: 10;
  }
}

main {
  font-family: sans-serif;
  font-size: 1rem;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: sans-serif;
}

.btn {
  font-family: sans-serif;
}
.btn-primary {
  border-color: #26374a;
}
.btn-primary:hover {
  border-color: #16446c;
}

a.btn-primary:hover {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

#wb-bc .container, #wb-bc .menu-bg-color .custom, .menu-bg-color #wb-bc .custom, #wb-bc footer#wb-info .custom, footer#wb-info #wb-bc .custom {
  border: 0;
}
#wb-bc .container .breadcrumb, #wb-bc .menu-bg-color .custom .breadcrumb, .menu-bg-color #wb-bc .custom .breadcrumb, #wb-bc footer#wb-info .custom .breadcrumb, footer#wb-info #wb-bc .custom .breadcrumb {
  background-color: #fff;
}

.h-32px {
  height: 32px;
}

.h-48px {
  height: 48px;
}

.h-64px {
  height: 64px;
}

.h-128px {
  height: 128px;
}

.h-256px {
  height: 256px;
}

.h-500px {
  height: 500px;
}

.h-700px {
  height: 700px;
}

@media (min-width: 767px) {
  .h-md-100, .idea-like .special-idea card {
    height: 100%;
  }
}
.w-128px {
  width: 128px;
}

.hover-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.2s !important;
  transition-property: all !important;
  transition-timing-function: ease-out;
}

.hover-float:hover, .hover-float:focus, .hover-float:active {
  transform: translateY(-2px);
}

.hover-primary:hover, .hover-primary:focus, .hover-primary:active {
  color: #3292D8 !important;
}

.page-header h1 {
  font-style: normal;
  font-weight: 700;
}

.hide {
  display: none !important;
}

.d-inline-block {
  display: inline-block;
}

.bg-primary {
  background: #3292D8;
}

@media screen and (max-width: 600px) {
  .text-sm-center {
    text-align: center;
  }
}
.font-smaller {
  font-size: smaller;
}

.font-larger {
  font-size: larger;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
  transition: 0.3s;
  margin-bottom: 1.25rem;
}
.card:hover {
  box-shadow: 5px 5px 25px rgba(203, 198, 195, 0.5);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.card-stats, .card .stat-box {
  text-align: left;
  font: normal normal 0.75rem/14px sans-serif;
  letter-spacing: 0;
  opacity: 1;
  margin: 0.5rem 0 0.5rem !important;
}
> .card-stats__value, > .card .stat-box__value {
  margin-bottom: 1rem !important;
}
.card-stats__divider, .card .stat-box__divider {
  background: #4d4d4d 0 0 no-repeat padding-box;
  width: 4px;
  height: 4px;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 4px;
}
.card-stats__link, .card .stat-box__link {
  text-decoration: none;
  font-weight: 700;
}
.card-stats__link:hover, .card .stat-box__link:hover {
  text-decoration: none;
}

.card-body {
  flex: 1 1 auto;
  padding: 0.9375rem;
}
.card-body__text {
  font-size: 14px;
}

.card-title {
  margin-bottom: 0.9375rem;
  margin-top: 0.9375rem;
}

.card-subtitle {
  margin-top: -0.46875rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 0.9375rem;
}

.card-header {
  padding: 0.9375rem 0.9375rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: calc(5px - 1px) calc(5px - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-header .pv-icon {
  font-size: 1.3rem;
  vertical-align: middle;
}

.card-footer {
  padding: 0.9375rem 0.9375rem;
  background-color: transparent;
}
.card-footer:last-child {
  border-radius: 0 0 calc(5px - 1px) calc(5px - 1px);
}
.card-footer__btn {
  flex-grow: 1;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;
  text-decoration: none;
}
.card-footer__btn:hover {
  border-radius: 5px;
  background-color: #CCC;
  text-decoration: none !important;
}
.card-footer__btn:visited, .card-footer__btn:hover, .card-footer__btn:focus {
  text-decoration: none;
}
.card-footer__btn .card-footer__label {
  text-align: center;
  font: normal normal 0.75rem/14px sans-serif;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}
.card-footer__btn .card-footer__icon {
  font-size: 1.22rem !important;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
  height: 1.2rem;
}
.card-footer__btn-tag {
  border-color: #707070 !important;
  background-color: #fff !important;
  font-weight: 700;
  color: rgba(0,0,0 0.65) !important;
}
.card-footer__btn-tag:hover, .card-footer__btn-tag.active {
  color: #fff !important;
  background-color: #707070 !important;
  border-color: #707070 !important;
}
.card-footer__btn-tag.read-only {
  cursor: default;
}

.card, .card-body, .card-footer {
  background-color: #FFF !important;
  border-width: 0 !important;
}

.card-header-tabs {
  margin-right: -0.46875rem;
  margin-bottom: -0.9375rem;
  margin-left: -0.46875rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.46875rem;
  margin-left: -0.46875rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(5px - 1px);
}

.card-img-top, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img {
  width: 100%;
  border-top-left-radius: calc(5px - 1px);
  border-top-right-radius: calc(5px - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(5px - 1px);
  border-bottom-left-radius: calc(5px - 1px);
}

.masony {
  float: left;
}
.masony img {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
@media (max-width: 991.98px) {
  .masony {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .masony {
    width: calc(50% - 16px);
  }
}
@media (min-width: 1200px) {
  .masony {
    width: calc(33% - 16px);
  }
}

.comment-section .avatar {
  flex: 0 0 50px;
  padding: 5px;
}
.comment-section .avatar span.fake, .comment-section .avatar img.fake {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.comment-section .smaller-avatar {
  flex: 0 0 40px;
  padding: 5px;
}
.comment-section .smaller-avatar span.fake, .comment-section .smaller-avatar img.fake {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 20px;
}
.comment-section .comment-inner {
  flex: 1 0 0;
  min-width: 0;
  overflow-wrap: break-word;
  margin-bottom: 10px;
}
.comment-section .comment-inner .comment-outline {
  border-radius: 0 10px 10px;
  padding: 15px;
  margin: 2px 0;
  border: 1px solid black;
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  height: 100%;
}
.comment-section .comment-inner .before-text {
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.comment-section .comment-inner .before-text .author {
  font-weight: bold;
  font-size: 14px;
}
.comment-section .comment-inner .before-text .submitted {
  color: grey;
  font-size: 14px;
}
.comment-section .comment-inner .before-text .btn-report-comment {
  text-decoration: underline;
  font-size: 12px;
  color: black;
}
.comment-section .comment-inner .before-text .btn-report-comment:hover {
  cursor: pointer;
  color: #3292D8;
}
.comment-section .comment-inner .text {
  border-radius: 0 10px 10px;
  padding: 15px;
  margin: 0;
  background-color: #f5f5f5;
  font-size: 14px;
}
.comment-section .comment-inner .after-text {
  margin-top: 4px;
  margin-bottom: 18px;
  width: max-content;
  font-size: 12px;
}
.comment-section .comment-inner .after-text img {
  position: relative;
  top: -1px;
}
.comment-section .comment-inner .after-text img.icon-16 {
  height: 1rem;
  width: 1rem;
}
.comment-section .comment-inner .comment-button-bottom {
  font-size: 12px;
  margin-left: 10px;
}
.comment-section .comment-inner .comment-edit, .comment-section .comment-inner .reply-edit {
  margin-bottom: calc(18px + 5px);
}
.comment-section .load-more {
  cursor: pointer;
  margin-bottom: 5px;
}
.comment-section .new-comment {
  align-items: flex-start;
  margin: 0 10px calc(18px + 5px) 10px;
}
.comment-section .single-comment {
  align-items: flex-start;
  margin: 0 10px 0 10px;
}
.comment-section .new-reply {
  align-items: flex-start;
  margin-top: calc(18px + 5px);
  margin-bottom: calc(18px + 5px);
}
.comment-section .single-reply {
  align-items: flex-start;
}
.comment-section form .form-group {
  margin-bottom: 1rem;
}
.comment-section form textarea {
  resize: none;
  box-shadow: none;
  overflow-x: hidden; /* for Firefox */
}
.comment-section form .comment-button-bottom {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
  display: none;
}
.comment-section form .comment-button-bottom > div, .comment-section form .comment-button-bottom .anonymously_cb {
  height: max-content;
  display: flex;
}
.comment-section form .comment-button-bottom label {
  padding-left: 0.5rem;
}
.comment-section form .comment-button-bottom button {
  border: none;
  padding: 4px 20px;
  border-radius: 5px;
  margin-right: 20px;
  color: white;
  background-color: #0073B1;
  flex-direction: row-reverse;
}
.comment-section form .comment-button-bottom button:hover {
  background-color: #707070;
}

.cbLoginForm select,
.cbLoginForm input[type=text],
.cbLoginForm input[type=password],
.cbLoginForm texarea .btn,
.cbLoginForm form-control,
form select,
form input[type=text],
form input[type=password],
form texarea .btn,
form form-control,
.form select,
.form input[type=text],
.form input[type=password],
.form texarea .btn,
.form form-control {
  border-radius: 5px;
}
.cbLoginForm .input-group .input-group-addon,
form .input-group .input-group-addon,
.form .input-group .input-group-addon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.cbLoginForm .input-group input[type=password],
.cbLoginForm .input-group input[type=text],
form .input-group input[type=password],
form .input-group input[type=text],
.form .input-group input[type=password],
.form .input-group input[type=text] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.cbLoginForm .control-group,
form .control-group,
.form .control-group {
  margin-bottom: 10px;
}

@media screen and (min-width: 769px) {
  .d-md-flex, #member-registration .form-group {
    display: flex;
  }
}
#member-registration .form-group #jform_com_fields_org_type label {
  border: 0;
  box-shadow: none;
  display: inline-block;
  padding: 0;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  #member-registration .form-group #jform_com_fields_org_type label {
    height: 100%;
    margin-bottom: 1.2rem;
    float: left;
  }
}
#member-registration .form-group #jform_com_fields_org_type input[type=radio] {
  display: inline-block;
  transform: translateY(14px);
  margin: 0 0.5rem;
  position: relative;
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  #member-registration .form-group #jform_com_fields_org_type input[type=radio] {
    width: auto;
    float: left;
    transform: translateY(-5px);
  }
  @supports (-webkit-touch-callout: none) {
    #member-registration .form-group #jform_com_fields_org_type input[type=radio] {
      width: 15px;
    }
  }
}
#member-registration .form-group #jform_com_fields_show_profile {
  box-shadow: none;
}
#member-registration .form-group #jform_com_fields_allow_notifications label, #member-registration .form-group #jform_com_fields_notify label {
  font-weight: 400;
}
#member-registration .form-group input[type=checkbox] {
  margin: 0 0.5rem !important;
}
@media screen and (max-width: 600px) {
  #member-registration .form-group .px-sm-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  #member-registration .form-group .control-label {
    margin-bottom: 15px;
  }
  #member-registration .form-group .control-label label {
    display: inline;
  }
  #member-registration .form-group.show-profile {
    display: inline-flex;
  }
  #member-registration .form-group.show-profile .controls {
    width: 25%;
  }
  #member-registration .form-group.show-profile-fr {
    display: flex;
  }
}
@media screen and (max-width: 768px) {
  #member-registration .form-group #jform_com_fields_org_type-lbl {
    margin-bottom: 1.8rem;
  }
}

.icon-16 {
  height: 1rem;
  width: 1rem;
}

.page-header {
  margin: 40px 0 20px;
}

.pv-pagination .paging a, .pv-pagination .paging span {
  padding: 0.8rem;
}
@media (max-width: 767.98px) {
  .pv-pagination .paging a, .pv-pagination .paging span {
    padding: 1rem;
    font-size: 1.4rem;
  }
}

ul.list-unstyled {
  list-style: none;
}

#wb-srch input[type=search] {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
#wb-srch .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
#wb-srch .btn-primary {
  background-image: none;
  background-color: #204a83 !important;
  border-color: #204a83 !important;
}

#pv-wb-srch-sub {
  background-color: #26374a;
}

#search-button.hide {
  display: none !important;
}
#search-button.hide span {
  display: none;
}
#search-button.hide :before {
  display: none;
}

#wb-srch-mobile {
  margin-left: 1rem;
}
#wb-srch-mobile .form-group {
  height: 86%;
  margin-top: 2%;
  margin-bottom: 4%;
}
#wb-srch-mobile input[type=search] {
  display: none;
  max-width: 160px;
}
#wb-srch-mobile button {
  display: none;
}
#wb-srch-mobile button:nth-child(n+1) {
  border-radius: 0;
}
#wb-srch-mobile button:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
#wb-srch input[type=search] {
  padding-left: 6px;
}

.card-footer__share {
  position: absolute !important;
  bottom: 100%;
  right: 0;
  background: #FFFFFF;
  /* Card Shadow */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  flex-direction: row;
  padding: 16px;
}
.card-footer__share .card-footer__btn-close {
  color: light(#000, 10%);
  font-size: 10px;
  line-height: 1;
  min-height: 18px;
  min-width: 18px;
  position: absolute;
  right: 0;
  top: 0;
}
.card-footer__share .card-footer__btn-close:hover {
  color: #000;
  text-decoration: none;
}
.card-footer__share ul,
.card-footer__share .first {
  flex: none;
  order: 0;
  align-self: center;
  display: flex;
  margin: 0;
  padding: 0;
}
.card-footer__share ul .social,
.card-footer__share .first .social {
  flex: none;
  order: 1;
  align-self: flex-start;
  margin: 0 8px;
}
.card-footer__share ul .social:first-child,
.card-footer__share .first .social:first-child {
  margin-left: 0;
}
.card-footer__share ul .social > a > img,
.card-footer__share .first .social > a > img {
  border-radius: 5px;
  height: 1.2rem;
}
.card-footer__share ul .social > a:focus,
.card-footer__share .first .social > a:focus {
  outline-offset: 2px;
}
.card-footer__share .second {
  flex: none;
  order: 1;
  display: inline-block;
  align-self: center;
  padding: 0;
  margin: 16px 7px 0;
}
.card-footer__share .second p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  line-height: 14px;
  color: #707070;
  margin-bottom: 0;
}

#system-message-container {
  border: 1px groove #000;
  border-radius: 4px;
  margin: 1rem;
  padding: 1rem 1rem;
}
#system-message-container .close {
  text-decoration: none;
}
#system-message-container .alert:last-child {
  margin-bottom: 0;
}
#system-message-container ol {
  font-weight: bold;
}

.white-popup-block {
  background: #FFF;
  padding: 20px 30px;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative;
}

.mfp-bg {
  opacity: 0.8;
}

.overlay-def header {
  background-color: #26374a;
}

.view-consultation .mfp-iframe-scaler, .view-stories .mfp-iframe-scaler, .view-story .mfp-iframe-scaler {
  min-height: 700px;
}

@media (max-width: 767.98px) {
  iframe.embed-responsive-item {
    width: 100% !important;
  }
}
@media (max-width: 991.98px) {
  iframe.embed-responsive-item {
    max-height: 991px;
  }
}

.ui-star-ratings {
  display: flex;
  flex-direction: row;
  padding: 16px;
  position: relative;
}
.ui-star-ratings .star {
  flex: none;
  order: 1;
  align-self: flex-start;
  margin: 0 4px;
  transition: all 0.2s ease-in-out;
}
.ui-star-ratings .star > a {
  text-decoration: none;
}
.ui-star-ratings .star > a:focus {
  outline-offset: 3px;
}
.ui-star-ratings .star > a > span.pv-icon:not(on):before {
  content: "\e915";
  color: #000;
}
.ui-star-ratings .star:hover > a > span.pv-icon:before, .ui-star-ratings .star.hover > a > span.pv-icon:before {
  content: "\e914";
  color: #007FAA;
}
.ui-star-ratings .star.on > a > span.pv-icon:before {
  content: "\e914";
  color: #000;
}

@CHARSET "UTF-8";
.ias_loader, .ias_trigger {
  text-align: center;
  margin: 30px 0 40px;
}

.ias_trigger a:link,
.ias_trigger a:visited {
  padding: 4px 50px;
  background-color: #f9f9f9;
  border: solid 1px #ddd;
  border-radius: 2px;
  font: bold 12px Arial, sans-serif;
  color: #555;
  text-decoration: none;
}

.ias_trigger a:hover,
.ias_trigger a:active {
  border-color: #ccc;
}

#container-isotope .ias_loader {
  display: none;
}

.pv-help {
  color: #FFF;
  margin-right: 0;
  opacity: 1;
  position: relative;
  text-shadow: 0 0 1px #fff;
  z-index: 20;
}
.pv-help ul {
  margin: 0;
  padding: 0 0 0 1em;
  font-size: 12px;
}
.pv-help a:hover .pv-help-inner {
  display: block !important;
}
.pv-help-inner {
  background: none repeat scroll 0 0 rgb(38, 55, 74);
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 0 8px #888888;
  color: #FFFFFF;
  display: none;
  min-width: 320px;
  opacity: 1;
  padding: 10px !important;
  position: absolute;
  text-align: left;
  text-shadow: 0 0px 1px #ffffff;
  width: inherit;
  z-index: 20;
}
.pv-help-inner * {
  color: #EEE;
}
.pv-help-left .pv-help-inner {
  left: inherit;
  right: 0;
}
@media (max-width: 767.98px) {
  .pv-help .pv-help-inner {
    font-size: 85%;
  }
}
.pv-help.pv-help-wide .pv-help-inner {
  width: 600px;
}
@media (max-width: 767.98px) {
  .pv-help.pv-help-wide .pv-help-inner {
    min-width: 100%;
    width: 100% !important;
  }
}
.pv-help.inline {
  display: inline-block;
  padding: 0 1em;
}

/* PLACE OTHER HELP ICON CONTENT POSITIONS HERE */
/** For Help Icons on the right side of the page **/
label .pv-help {
  display: inline-block;
}

.school-icons {
  display: none;
}

.interactive-slider {
  width: 80%;
  margin: 0 auto;
}

#slider {
  width: 100%;
  background-color: #707070 !important;
  height: 7px;
  margin: 3em 0;
  position: relative;
  background: none;
  border: none;
}
#slider .ui-slider-handle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: none;
  background-color: #3292D8;
  border: none;
  top: -7px;
  position: absolute;
  transition: left 0.1s ease-out;
}
#slider .ui-slider-handle:hover {
  cursor: pointer;
}

@keyframes bounce-grow-10 {
  0% {
    transform: scale(0.8, 0.8);
  }
  35% {
    transform: scale(1.15, 1.15);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  70% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.95, 0.95);
  }
}
@keyframes bounce-grow-20 {
  0% {
    transform: scale(0.8, 0.8);
  }
  35% {
    transform: scale(1.15, 1.15);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  70% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.95, 0.95);
  }
}
@keyframes bounce-grow-50 {
  0% {
    transform: scale(0.8, 0.8);
  }
  35% {
    transform: scale(1.15, 1.15);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  70% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.95, 0.95);
  }
}
@keyframes bounce-grow-100 {
  0% {
    transform: scale(0.8, 0.8);
  }
  35% {
    transform: scale(1.15, 1.15);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  70% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.95, 0.95);
  }
}
.icon-container {
  color: #fff;
  width: 100px;
  height: 100px;
  margin: 0.2em auto 0.2em;
  display: block;
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  font-size: 80px;
  transform: scale(0.75, 0.75);
  transition: transform 0.3s ease-in-out;
  transform-origin: center bottom;
}
.icon-container img {
  position: relative;
  top: 0.25rem;
  display: none;
  width: 80%;
  margin: 0.05rem auto;
}
.icon-container img.active {
  display: block;
}
.icon-container[data-size="10"] {
  animation: bounce-grow-10 0.5s ease-out alternate;
  transform: scale(0.95, 0.95);
}
.icon-container[data-size="20"] {
  animation: bounce-grow-20 0.5s ease-out alternate;
  transform: scale(0.95, 0.95);
}
.icon-container[data-size="50"] {
  animation: bounce-grow-50 0.5s ease-out alternate;
  transform: scale(0.95, 0.95);
}
.icon-container[data-size="100"] {
  animation: bounce-grow-100 0.5s ease-out alternate;
  transform: scale(0.95, 0.95);
}

.caption {
  display: none;
  font-size: 1.2em;
  color: #666;
  margin: 0 auto;
  line-height: 1.4em;
  text-align: center;
}
.caption .amount {
  color: #3292D8;
  font-size: 1.3em;
  font-style: normal;
}

.presets {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.presets button {
  background-color: #707070;
  color: rgb(0, 0, 0);
  font-size: 1.3em;
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 1em;
  transition: all 0.3s ease-in-out;
  border: 1px solid #707070;
}
.presets button.active, .presets button.active:hover {
  background-color: #3292D8;
  color: #fff;
  border-color: #3292D8;
}
.presets button:hover {
  cursor: pointer;
  background-color: transparent;
  color: #3292D8;
  border-color: #3292D8;
}

@keyframes dash {
  to {
    stroke-dashoffset: 200;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.1, 1.1);
  }
}
#effect {
  position: relative;
}
#effect[data-amount="1000"] #confetti {
  opacity: 1;
  visibility: visible;
}
#effect[data-amount="1000"] #confetti line {
  animation: dash 1.8s ease-out;
}
#effect[data-amount="1000"] .icon-container .shape-graduation-cap {
  animation: pulse 0.75s ease-out alternate infinite;
}

#confetti {
  position: absolute;
  top: -9.5em;
  left: calc(50% - 9.5em);
  opacity: 0;
  visibility: hidden;
}
#confetti line {
  fill: none;
  stroke: #3292D8 !important;
  stroke-width: 0.5em;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 550;
}

@media (max-width: 768px) {
  .presets button {
    font-size: 13px;
  }
}
.pv-profile {
  box-shadow: 5px 5px 25px rgba(203, 198, 195, 0.5);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  transition: 0.3s;
  margin-top: 1rem;
}
.pv-profile .pv-profile-header {
  color: #fff;
  background-image: linear-gradient(90deg, #d46a43, #c0392b);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.pv-profile .pv-profile-header .h6 {
  font-size: 1rem;
}

@media (max-width: 767px) {
  #show_hide_password {
    display: table;
  }
}

#my-contributions {
  box-shadow: 5px 5px 25px rgba(203, 198, 195, 0.5);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  transition: 0.3s;
  margin-top: 1rem;
}
#my-contributions .dashboard-sub-heading {
  color: #fff;
  background-image: linear-gradient(90deg, #d46a43, #c0392b);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1rem;
  font-size: 1rem;
  margin-top: 0;
  font-weight: 400;
}

.pv-profile input[type=checkbox].required:before,
.pv-profile .required:before,
#member-registration input[type=checkbox].required:before,
#member-registration .required:before {
  content: "";
}
.pv-profile .checkbox input[type=checkbox],
#member-registration .checkbox input[type=checkbox] {
  position: static;
  margin-left: 0;
}
.pv-profile .controls fieldset,
#member-registration .controls fieldset {
  padding-top: 0;
}
@media screen and (max-width: 768px) {
  .pv-profile input:not([type=checkbox]),
  #member-registration input:not([type=checkbox]) {
    width: 100%;
  }
  .pv-profile .form-group .col-xs-12,
  #member-registration .form-group .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .pv-profile .form-group label.required:before,
  #member-registration .form-group label.required:before {
    margin-left: 0;
  }
}

.registration .border-bottom {
  border-bottom: 1px solid #dee2e6;
}

.no-touch #sidebar:hover {
  width: 300px;
}
.no-touch #sidebar:hover h1, .no-touch #sidebar:hover h2, .no-touch #sidebar:hover h3, .no-touch #sidebar:hover h4, .no-touch #sidebar:hover h5, .no-touch #sidebar:hover h6 {
  display: block;
  text-align: left;
}
.no-touch #sidebar:hover .h4 {
  font-size: 18px !important;
}
.no-touch #sidebar:hover .menu li.current a,
.no-touch #sidebar:hover ul.nav li.current a {
  width: 96%;
}
.no-touch #sidebar:hover .menu li a,
.no-touch #sidebar:hover ul.nav li a {
  text-align: left !important;
}
@media (max-width: 767px) {
  .no-touch #sidebar:hover .menu li a,
  .no-touch #sidebar:hover ul.nav li a {
    padding: 5px 7px;
  }
  .no-touch #sidebar:hover .menu li a .fa,
  .no-touch #sidebar:hover ul.nav li a .fa {
    padding-top: 5px;
    padding-left: 0 !important;
  }
}
.no-touch #sidebar:hover .menu li a:before,
.no-touch #sidebar:hover ul.nav li a:before {
  display: block;
  text-align: left;
  width: 24px;
}
.no-touch #sidebar:hover .menu li a .item-title,
.no-touch #sidebar:hover ul.nav li a .item-title {
  display: block !important;
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
  width: 88%;
}
.no-touch #sidebar:hover .menu li.parent ul li a,
.no-touch #sidebar:hover ul.nav li.parent ul li a {
  padding-left: 25px;
}
.no-touch #sidebar:hover .icon-ideas:before {
  position: relative;
  top: -4px;
  left: -5px;
}
.no-touch #sidebar:hover .icon-globe:before {
  top: -5px;
  left: -5px;
}
.no-touch #sidebar:hover .icon-stories:before {
  top: 0;
  left: -5px;
}
.no-touch #sidebar:hover .icon-surveys:before {
  top: -1px;
  left: -4px;
}
.no-touch #sidebar:hover .icon-feedback:before {
  top: 0;
  left: -5px;
}

.touch.xxsmallview #sidebar {
  height: 100%;
  font-size: 12px;
}

@media (max-width: 767px) {
  .touch #sidebar.active {
    width: 30vw;
  }
  .touch #sidebar.active ~ #page-content {
    width: 70vw;
  }
}
@media (max-width: 575px) {
  .touch #sidebar.active {
    width: 100vw;
    z-index: 1000;
  }
  .touch #sidebar.active ~ #page-content {
    overflow: hidden;
    width: 0vw;
    z-index: 0;
  }
  .touch #sidebar.active #deactive-sidebar {
    display: block !important;
  }
}
#sidebar {
  background: #26374A;
  height: 100%;
  overflow: hidden;
  min-width: 80px;
  position: relative;
  transition: 0.5s;
  width: 140px;
}
@media (min-height: 300px) {
  #sidebar {
    position: sticky;
    top: 1rem;
    z-index: 10;
  }
}
#sidebar h3, #sidebar h3, #sidebar .h3, #sidebar .h4 {
  color: white;
}
#sidebar h1, #sidebar h2, #sidebar h3, #sidebar h4, #sidebar h5, #sidebar h6 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
#sidebar .drawer {
  height: 100%;
  width: 100%;
}
#sidebar.active {
  width: 300px;
}
#sidebar.active h1, #sidebar.active h2, #sidebar.active h3, #sidebar.active h4, #sidebar.active h5, #sidebar.active h6 {
  display: block;
  text-align: left;
}
#sidebar.active .h4 {
  font-size: 18px !important;
}
#sidebar.active .navbar {
  margin-bottom: 0;
}
#sidebar.active .menu li.current a,
#sidebar.active ul.nav li.current a {
  width: 96%;
}
#sidebar.active .menu li.parent ul a,
#sidebar.active ul.nav li.parent ul a {
  padding-left: 25px;
}
#sidebar.active .menu li .parent a,
#sidebar.active ul.nav li .parent a {
  text-align: left;
}
#sidebar.active .menu li a,
#sidebar.active ul.nav li a {
  text-align: left !important;
}
@media (max-width: 767px) {
  #sidebar.active .menu li a,
  #sidebar.active ul.nav li a {
    padding: 5px 7px;
  }
  #sidebar.active .menu li a .fa,
  #sidebar.active ul.nav li a .fa {
    padding-top: 5px;
    padding-left: 0 !important;
  }
}
#sidebar.active .menu li a:before,
#sidebar.active ul.nav li a:before {
  display: block;
  text-align: left;
  width: 24px;
}
#sidebar.active .menu li a.icon-globe:before,
#sidebar.active ul.nav li a.icon-globe:before {
  position: relative;
  top: -9px;
  left: -13px;
}
#sidebar.active .menu li a.icon-ideas:before,
#sidebar.active ul.nav li a.icon-ideas:before {
  position: relative;
  top: -4px;
  left: -5px;
}
#sidebar.active .menu li a.icon-stories:before,
#sidebar.active ul.nav li a.icon-stories:before {
  position: relative;
  top: 0;
  left: -5px;
}
#sidebar.active .menu li a.icon-surveys:before,
#sidebar.active ul.nav li a.icon-surveys:before {
  position: relative;
  top: -1px;
  left: -4px;
}
#sidebar.active .menu li a.icon-feedback:before,
#sidebar.active ul.nav li a.icon-feedback:before {
  position: relative;
  top: 0;
  left: -5px;
}
#sidebar.active .menu li a .item-title,
#sidebar.active ul.nav li a .item-title {
  display: block !important;
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
  width: 88%;
}
@media (max-width: 767px) {
  #sidebar.active .menu li.current a,
  #sidebar.active ul.nav li.current a {
    width: 98% !important;
  }
}
#sidebar .moduletable {
  position: relative;
}
#sidebar h1, #sidebar h2, #sidebar h3, #sidebar h4, #sidebar h5, #sidebar h6 {
  font-size: 90%;
  display: none;
  text-align: center;
}
#sidebar .menu,
#sidebar ul.nav {
  top: 0;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
#sidebar .menu li a:active,
#sidebar ul.nav li a:active {
  background-color: transparent;
}
#sidebar .menu li a:focus,
#sidebar ul.nav li a:focus {
  background-color: transparent;
}
#sidebar .menu li a:hover,
#sidebar ul.nav li a:hover {
  background-color: #49698d;
  background-image: url(/layouts/publivate/imgs/right-tri-hover2.png);
  background-position: right center;
  background-repeat: no-repeat;
}
#sidebar .menu li,
#sidebar ul.nav li {
  position: relative;
  float: none !important;
  width: 100%;
}
#sidebar .menu li > a > .item-title,
#sidebar ul.nav li > a > .item-title {
  display: none;
}
#sidebar .menu li.current,
#sidebar ul.nav li.current {
  background-image: url(/layouts/publivate/imgs/right-tri-gray.png);
  background-position: right center;
  background-repeat: no-repeat;
}
#sidebar .menu li.current a,
#sidebar ul.nav li.current a {
  background-color: #ccc;
  background-image: none;
  color: #000;
}
#sidebar .menu li.current .icon-globe:before,
#sidebar ul.nav li.current .icon-globe:before {
  position: relative;
  content: url("/layouts/publivate/imgs/about_black1.png");
}
#sidebar .menu li.current .icon-surveys:before,
#sidebar ul.nav li.current .icon-surveys:before {
  position: relative;
  content: url("/layouts/publivate/imgs/round-checkbox-black.svg");
}
#sidebar .menu li.current .icon-ideas:before,
#sidebar ul.nav li.current .icon-ideas:before {
  position: relative;
  content: url("/layouts/publivate/imgs/noun_idea_blk.svg");
}
#sidebar .menu li.current .icon-stories:before,
#sidebar ul.nav li.current .icon-stories:before {
  position: relative;
  content: url("/layouts/publivate/imgs/menu_stories_black.svg");
}
#sidebar .menu li.current .icon-feedback:before,
#sidebar ul.nav li.current .icon-feedback:before {
  position: relative;
  content: url("/layouts/publivate/imgs/icon_feedback_black.svg");
}
#sidebar .menu li.current:hover .icon-globe:before,
#sidebar ul.nav li.current:hover .icon-globe:before {
  position: relative;
  content: url("/layouts/publivate/imgs/about_white1.png");
}
#sidebar .menu li.current:hover .icon-surveys:before,
#sidebar ul.nav li.current:hover .icon-surveys:before {
  position: relative;
  content: url("/layouts/publivate/imgs/round-checkbox-white.svg");
}
#sidebar .menu li.current:hover .icon-ideas:before,
#sidebar ul.nav li.current:hover .icon-ideas:before {
  position: relative;
  content: url("/layouts/publivate/imgs/noun_idea.svg");
}
#sidebar .menu li.current:hover .icon-stories:before,
#sidebar ul.nav li.current:hover .icon-stories:before {
  position: relative;
  content: url("/layouts/publivate/imgs/menu_stories.svg");
}
#sidebar .menu li.current:hover .icon-feedback:before,
#sidebar ul.nav li.current:hover .icon-feedback:before {
  position: relative;
  content: url("/layouts/publivate/imgs/icon_feedback_white.svg");
}
#sidebar .menu li.divider,
#sidebar ul.nav li.divider {
  margin: 1rem 0;
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
  padding: 2px 0 !important;
  height: 2px;
}
#sidebar .menu li.divider:first-child,
#sidebar ul.nav li.divider:first-child {
  margin-top: 0;
}
#sidebar .menu li.divider .separator,
#sidebar ul.nav li.divider .separator {
  overflow: hidden;
  height: 10px;
}
#sidebar .menu li a,
#sidebar ul.nav li a {
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  display: flex;
  text-decoration: none;
  padding: 0.9375rem;
}
#sidebar .menu li a:focus .item-title, #sidebar .menu li a:hover .item-title,
#sidebar ul.nav li a:focus .item-title,
#sidebar ul.nav li a:hover .item-title {
  text-decoration: none;
}
#sidebar .menu li a:focus .fa, #sidebar .menu li a:hover .fa,
#sidebar ul.nav li a:focus .fa,
#sidebar ul.nav li a:hover .fa {
  text-decoration: none;
  padding-left: 5px;
}
#sidebar .menu li a.disabled,
#sidebar ul.nav li a.disabled {
  color: gray;
  font-weight: 100;
  background-color: initial !important;
  cursor: default;
}
#sidebar .menu li a.disabled:hover,
#sidebar ul.nav li a.disabled:hover {
  background: none;
}
#sidebar .menu li a .item-title,
#sidebar ul.nav li a .item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 84%;
}
#sidebar .menu li a img,
#sidebar ul.nav li a img {
  height: 20px;
  width: 16px;
}
#sidebar .menu li a .fa,
#sidebar ul.nav li a .fa {
  padding-top: 3px;
  text-align: right;
  width: 15%;
}
#sidebar .menu li.parent a .fa:before,
#sidebar ul.nav li.parent a .fa:before {
  content: "\f0d7";
}
#sidebar .menu li.parent a.collapsed .fa:before,
#sidebar ul.nav li.parent a.collapsed .fa:before {
  content: "\f0da";
}
#sidebar .menu li.parent > a > .item-title,
#sidebar ul.nav li.parent > a > .item-title {
  display: block;
}
#sidebar .menu li.parent ul,
#sidebar ul.nav li.parent ul {
  background-color: #26374A;
  border-radius: 0;
  padding-left: 0px;
  list-style: none;
}
#sidebar .menu li.parent ul a,
#sidebar ul.nav li.parent ul a {
  font-size: 90%;
  text-align: center;
  padding: 10px 12px;
}
@media (max-width: 767px) {
  #sidebar .menu li.parent ul a,
  #sidebar ul.nav li.parent ul a {
    padding: 5px 7px;
  }
}
#sidebar .menu li.parent ul a:before,
#sidebar ul.nav li.parent ul a:before {
  display: block;
}
#sidebar .menu li.parent ul a .item-title,
#sidebar ul.nav li.parent ul a .item-title {
  display: none;
}
.menu-icon:before {
  display: block;
  font: normal normal normal 110% FontAwesome;
  height: 24px;
  padding-top: 3px;
  text-rendering: auto;
  text-align: center;
  width: 100%;
}

.icon-resources:before {
  font-family: FontAwesome;
  content: "\f0f6";
}

.icon-surveys:before {
  content: "\f0ae";
}

.icon-questions:before {
  content: "\f128";
}

.icon-globe:before {
  position: relative;
  content: url("/layouts/publivate/imgs/about_white1.png");
}

.icon-globe:before {
  width: 24px;
  height: 24px;
}

.icon-surveys:before {
  position: relative;
  content: url("/layouts/publivate/imgs/round-checkbox-white.svg");
}

.icon-surveys:before {
  width: 24px;
  height: 24px;
}

.icon-ideas:before {
  position: relative;
  content: url("/layouts/publivate/imgs/noun_idea.svg");
}

.icon-ideas:before {
  width: 24px;
  height: 24px;
}

.icon-stories:before {
  position: relative;
  content: url("/layouts/publivate/imgs/menu_stories.svg");
}

.icon-stories:before {
  width: 24px;
  height: 24px;
}

.icon-feedback:before {
  position: relative;
  content: url("/layouts/publivate/imgs/icon_feedback_white.svg");
}

.icon-feedback:before {
  width: 24px;
  height: 24px;
}

.icon-ideas:before,
.icon-stories:before {
  position: center;
  width: 100%;
  margin-right: 2px;
}

.icon-surveys:before {
  position: center;
  width: 100%;
}

.icon-globe:before {
  position: center;
  width: 100%;
}

.icon-feedback:before {
  position: center;
  width: 100%;
}

@media (max-width: 767px) {
  #sidebar .icon-globe:before {
    top: -7px;
  }
  #sidebar .icon-surveys:before {
    top: -1px !important;
  }
  #sidebar.active .icon-globe:before {
    top: 0;
    left: -8px !important;
    margin-right: 5px;
  }
}
#sidebar ul.nav li.current:hover .icon-globe:before {
  content: url(/layouts/publivate/imgs/about_black1.png);
}
#sidebar ul.nav li.current:hover .icon-stories:before {
  content: url(/layouts/publivate/imgs/menu_stories_black.svg);
}
#sidebar ul.nav li.current:hover .icon-ideas:before {
  content: url(/layouts/publivate/imgs/noun_idea_blk.svg);
}
#sidebar ul.nav li.current:hover .icon-surveys:before {
  content: url(/layouts/publivate/imgs/round-checkbox-black.svg);
}

.btn-tag {
  background: #fff;
  border-radius: 0.2rem;
  border-color: rgb(112, 112, 112);
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
}
.btn-tag.active {
  color: #fff;
  background: #707070;
}

#carousel-video-resources .list-inline {
  overflow-x: auto;
}
#carousel-video-resources .carousel-indicators {
  position: static;
  left: initial;
  width: initial;
  margin-left: initial;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
#carousel-video-resources .carousel-indicators > li {
  width: initial;
  height: initial;
  text-indent: initial;
  border-width: 1px;
  max-width: 150px;
}
#carousel-video-resources .carousel-indicators > li.active img {
  opacity: 0.7;
  border: 4px solid #007faa;
}
#carousel-video-resources .carousel-indicators > li p.small {
  min-height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#carousel-video-resources.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.password-reset-page .form-group div:first-child {
  padding-right: 0.2rem;
}
.password-reset-page .form-group div:last-child {
  padding-left: 0.2rem;
}
@media screen and (max-width: 768px) {
  .password-reset-page {
    padding: 1rem;
  }
  .password-reset-page .form-group .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .password-reset-page .form-group label.required:before {
    margin-left: 0;
  }
}

/** LOGIN PAGE **/
.login-page,
.cbLoginForm {
  max-width: 500px;
  /** LOGIN MODULE(CB) **/
}
.login-page .form-horizontal .form-group,
.cbLoginForm .form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}
.login-page .control-label,
.cbLoginForm .control-label {
  text-align: left;
}
.login-page label.required:before,
.cbLoginForm label.required:before {
  content: "";
}
.login-page input[type=checkbox],
.cbLoginForm input[type=checkbox] {
  background: #000;
  color: #f0f0f0;
  height: auto;
}
.login-page .input-group,
.cbLoginForm .input-group {
  width: 100%;
}
.login-page .input-group input,
.cbLoginForm .input-group input {
  display: inline-block;
  max-width: 90%;
  width: 100%;
}
.login-page .input-group .input-group-addon,
.cbLoginForm .input-group .input-group-addon {
  width: 10%;
}
.login-page .form-login-remember input,
.cbLoginForm .form-login-remember input {
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  width: 15px;
}
.login-page ul.unstyled,
.cbLoginForm ul.unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}
.login-page ul.unstyled li:first-child,
.cbLoginForm ul.unstyled li:first-child {
  margin-bottom: 10px;
}
.login-page ul.unstyled a.btn,
.cbLoginForm ul.unstyled a.btn {
  border: none;
  text-decoration: underline;
  margin: 0;
}
.login-page ul.unstyled a.btn:hover,
.cbLoginForm ul.unstyled a.btn:hover {
  text-decoration: none;
}
.login-page .input-prepend input,
.cbLoginForm .input-prepend input {
  padding-left: 5px;
}
.login-page .cbLogoutButtonSpan,
.login-page .cbLoginButtonSpan,
.cbLoginForm .cbLogoutButtonSpan,
.cbLoginForm .cbLoginButtonSpan {
  display: block;
}
.login-page .cbLoginForgotButtonSpan,
.cbLoginForm .cbLoginForgotButtonSpan {
  margin-top: 5px;
}
.login-page .mod_login_password-mobile,
.cbLoginForm .mod_login_password-mobile {
  margin-bottom: 5px;
}
.login-page #mod_login_password-bg,
.cbLoginForm #mod_login_password-bg {
  margin-bottom: 1em;
}
.login-page .btn,
.login-page .form-login-links .btn-lg,
.login-page .login-submit .btn-lg,
.cbLoginForm .btn,
.cbLoginForm .form-login-links .btn-lg,
.cbLoginForm .login-submit .btn-lg {
  min-height: 30px;
  line-height: 30px;
  min-width: 100%;
}

@media screen and (max-width: 768px) {
  .login-page input[type=checkbox],
  .cbLoginForm input[type=checkbox] {
    background: #000;
    color: #f0f0f0;
    height: auto;
    position: initial;
    height: initial !important;
    width: auto;
    margin: 4px 5px 0 0;
  }
  .login-page .checkbox label,
  .cbLoginForm .checkbox label {
    display: inline-block;
    padding-left: 0px !important;
  }
  .login-page input:not([type=checkbox]) {
    width: 100%;
  }
  .login-page .form-group .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .login-page .form-group label.required:before {
    margin-left: 0;
  }
}
.cbLoginForm-mobile.cbLoginForm .cbLoginButtonSpan {
  display: inline-block !important;
  margin-left: 10px;
}

/* Cb Registration and Profile */
.cbLoginButtonSpan {
  margin-bottom: 10px;
}

.cbLogoutButtonSpan {
  float: right;
}

#guestmessage .cbLoginForm {
  max-width: 100%;
}

#guestmessage .cbLoginForm .form-login-links li {
  display: inline-block;
  margin: 5px 10px 5px 0;
}

#guestmessage .cbLoginForm .form-login-links li a {
  line-height: 35px;
}

#guestmessage .cbLoginForm .form-login-register {
  float: right;
}

#guestmessage .form-login-links .form-login-forgot a {
  line-height: 34px;
  box-shadow: none;
}

.login-page .btn,
.cbLoginButtonSpan .btn-default,
.cbLoginForgotButtonSpan .btn-default,
.cbLoginRegisterButtonSpan .btn-default {
  margin-top: 5px;
}

@media only screen and (min-width: 1024px) {
  #my-profile-contributions-page {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
#my-contributions {
  padding-bottom: 20px;
}
#my-contributions > p {
  padding-left: 10px;
}
#my-contributions .contributions-content .db-challenges {
  width: 100%;
}
#my-contributions .header-info a {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
}
#my-contributions .header-info .ui-icon {
  display: inline-block;
  vertical-align: middle;
}

.cb_template .cb-page-header-title {
  margin-bottom: 0.9rem;
  font-weight: 600;
}

.password-reset-confirm-page .required:before,
.password-reset-page .required:before {
  content: "";
}

#pv_gmap {
  width: 100%;
  height: 320px;
}

/*
  Individual card item
 */
/***************************************************
    DETAIL PAGE
***************************************************/
/*
  Individual card item
 */
.askus {
  border: 1px solid black;
  border-radius: 5px;
  min-height: 190px;
}
.askus .btn-primary {
  background-color: #146094 !important;
  border-color: #146094 !important;
}
.askus header,
.askus .askus-category,
.askus .askus-content {
  padding: 1rem 1rem 0;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .askus header,
  .askus .askus-category,
  .askus .askus-content {
    padding: 0.65rem 0.65rem 0;
  }
}
.askus header {
  background: #146094;
  color: #fff;
  display: flex;
  padding-bottom: 1rem;
  align-items: center;
}
.askus header .fa-question {
  margin: 1px 1px 0 0;
  font-size: 150%;
}
.askus header .askus-title {
  color: #fff;
  flex-grow: 3;
  font-size: 18px !important;
  margin: 0 1px 0 0;
  padding: 0 0.5rem;
}
.askus header .score {
  font-size: 95%;
  margin-top: -5px;
  padding-left: 8px;
  text-align: center;
}
.askus header .score-label {
  font-weight: 600;
}
.askus header .score-value {
  text-align: center;
}
.askus .askus-category a {
  font-weight: 700;
  color: #146094;
  text-decoration: none;
}
.askus .askus-side {
  border-bottom: 1px solid #707070;
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0;
  width: 100%;
}
.askus .askus-side .col {
  flex-grow: 1;
  padding: 0 0;
  text-align: center;
}
.askus .askus-side .col-like {
  padding: 7px 0;
}
.askus .askus-side .col-like,
.askus .askus-side .btn {
  color: #000;
  font-size: 80%;
  font-weight: 700;
  width: 100%;
}
.askus .askus-side .icon-18 {
  font-size: 1.22rem !important;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
}
.askus .askus-ratings {
  display: none;
}
.askus .askus-rate .rating {
  margin-left: 0;
}
.askus .card-content p:first-child {
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .askus .askus-tabs li a {
    padding: 0.25rem 0.65rem;
    font-size: 85%;
  }
}
.askus .askus-tabs.tabs-3 li:first-child a {
  height: 60px;
}
@media (max-width: 767.98px) {
  .askus .askus-tabs.tabs-3 li:first-child a {
    height: initial;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .askus .askus-tabs.tabs-3 li:first-child a {
    height: 48px;
  }
}
.askus .askus-tabs.nav-tabs .active .nav-link {
  color: #fff !important;
}
.askus .askus-tabs.nav-tabs .nav-link {
  color: #000 !important;
}
.askus .nav-tabs .active .nav-link {
  background: #146094;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.askus .nav-tabs .nav-link {
  color: #fff !important;
}
.askus .tab-content {
  padding: 1rem;
}
.askus .tab-content .comment-name span.h5 {
  font-size: 24px !important;
}
.askus .askus-comments .add-comment-area form .comment-formgroup {
  width: 100%;
}
.askus .askus-comments .add-comment-area form input[type=text] {
  border-radius: 4px;
  width: 100%;
}
.askus .askus-comments .add-comment-area form .comment-anonymous label {
  font-weight: normal;
}
.askus .askus-comments textarea {
  min-height: 100px;
  width: 100%;
  border-radius: 4px;
}
.askus footer {
  color: inherit;
  background-color: inherit;
  font: inherit;
  font-weight: inherit;
  text-transform: inherit;
}

/***************************************************
    DETAIL PAGE
***************************************************/
#askus-detail {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#askus-detail .btn-primary {
  background-color: #146094 !important;
}
#askus-detail .askus-comments {
  padding: 0 !important;
}
#askus-detail .askus-comments .comment {
  margin: 1rem 0;
  padding: 1rem;
}
#askus-detail .askus-comments .comment:first-child {
  margin-top: 0;
  padding-top: 0;
}

/***************************************************
    LIST PAGE
***************************************************/
#askus-answers {
  font-family: sans-serif !important;
}
#askus-answers .askus-filter-row {
  display: flex;
}
#askus-answers .askus-sort-by {
  flex-grow: 3;
}
#askus-answers .askus-sort-by ul {
  margin-left: 0;
  padding: 0;
}
#askus-answers .askus-sort-by ul li {
  display: inline-block;
  padding: 0.5rem 1rem 0 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  #askus-answers .askus-sort-by ul li {
    display: block;
  }
}
#askus-answers .askus-sort-by ul li label {
  display: inline-block;
  min-width: 80px;
  text-align: right;
}
#askus-answers .askus-sort-by ul li select {
  display: inline-block;
  padding-right: 5px;
}
#askus-answers .askus-sort-by ul li a {
  color: #337ab7;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
}
#askus-answers .askus-sort-by ul li a.active {
  font-weight: 700;
}
#askus-answers .askus-container {
  margin: 15px 0;
  background: white;
}
#askus-answers .answers-row > *:nth-child(odd) {
  clear: left;
}
#askus-answers .askus-no-questions {
  border: 1px solid #707070;
  margin: 1rem 0;
  padding: 1rem 1.25rem;
}
#askus-answers .paging {
  display: flex;
}
#askus-answers .paging .page {
  margin-right: 0.75rem;
}
#askus-answers .paging .page.active {
  font-weight: bold;
}

@media (max-width: 767.98px) {
  #askus-answers .askus-sort-by {
    float: left;
  }
  #askus-answers .askus-sort-by select {
    width: 100%;
  }
}
/*
  Comments
 */
.askus .comment {
  margin-bottom: 20px;
}
.askus .askus-comment-edit-action,
.askus .askus-report-abuse-action {
  color: #146094;
  font: inherit;
  font-size: 85%;
  font-weight: 500;
  text-decoration: none;
}

.askus-report-abuse .captcha-box {
  margin-top: 10px;
}
.askus-report-abuse .captcha-box #jform_captcha {
  min-height: 120px;
}

.mod-publivate-askus {
  color: #fff;
}
.mod-publivate-askus label {
  color: #000;
}
.mod-publivate-askus input[type=submit] {
  background-color: #146094 !important;
  border-color: #146094 !important;
  min-width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
}
.mod-publivate-askus .fa-question {
  font-size: 4rem;
  align-self: center;
  margin-right: 0px;
}
@media (max-width: 767px) {
  .mod-publivate-askus .fa-question {
    align-self: initial;
    font-size: 2rem;
  }
}
.mod-publivate-askus .page-header {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  background-color: #146094;
  margin: 1.25rem;
}
@media (max-width: 767.98px) {
  .mod-publivate-askus .page-header {
    display: block;
  }
}
.mod-publivate-askus .page-header h1 {
  border-bottom: 0;
}
.mod-publivate-askus .page-header h1 .fas,
.mod-publivate-askus .page-header h1 .fa {
  margin-right: 15px;
}
.mod-publivate-askus .page-header .icon-container {
  margin-right: 3rem;
}
@media screen and (max-width: 600px) {
  .mod-publivate-askus .page-header ul {
    padding-left: 20px;
  }
}
.mod-publivate-askus form.add-question {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin: 1.25rem;
  padding: 1.25rem;
}
.mod-publivate-askus form.add-question .prompt {
  margin-bottom: 15px;
}
.mod-publivate-askus form.add-question .prompt > * {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.mod-publivate-askus form.add-question select {
  border-radius: 4px;
  padding-right: 5px;
  width: 100%;
}
.mod-publivate-askus form.add-question input[type=text] {
  width: 100%;
  border-radius: 4px;
}
.mod-publivate-askus form.add-question .btn {
  min-width: 100%;
  height: 50px;
}
.mod-publivate-askus form.add-question .pv-autocomplete {
  position: relative;
}
.mod-publivate-askus form.add-question .pv-autocomplete .pvc_search_results {
  top: 0;
}

.askus-challenge-content ol, .askus-challenge-content ul {
  margin-left: 1.5rem;
}

.view-ideas.layout-askus .main-content-top {
  padding-left: 0;
  padding-right: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.view-ideas.layout-askus .main-content-top .page-header {
  background-color: #146094;
}

.mod-publivate-askus .card-body {
  padding: 0.9rem 0.9rem 0;
}

#askus-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.add-idea-form .btn-primary {
  background-color: #146094 !important;
  border-color: #146094 !important;
  width: 100%;
}
.add-idea-form fieldset {
  margin: 0;
  padding: 0;
}
.add-idea-form fieldset .card-header {
  border-radius: 0;
  background-color: #146094;
}
.add-idea-form .form-group .mce-panel, .add-idea-form .form-group .mce-container {
  font-size: 16px !important;
}
.add-idea-form input, .add-idea-form select, .add-idea-form textarea, .add-idea-form .mce-panel * {
  font-size: 16px !important;
}
.add-idea-form .mce-panel label {
  font-size: 1rem !important;
}

.abuse-improvement-type .btn-primary {
  background-color: #146094 !important;
  border-color: #146094 !important;
}

/*
  Individual card item
 */
.idea-card .card-header {
  background: #146094 no-repeat padding-box !important;
  color: #fff !important;
}
.idea-card .card-header .idea-title {
  font-size: 14px;
  font-weight: bold;
}
.idea-card .tags ul li {
  display: block;
}
.idea-card .stats-section,
.idea-card .stat-box {
  text-align: left;
  font: normal normal 0.75rem/14px sans-serif;
  letter-spacing: 0;
  opacity: 1;
  margin: 0.5rem 0 0.5rem !important;
}
.idea-card .stats-section > div.col-auto,
.idea-card .stat-box > div.col-auto {
  margin-bottom: 1rem !important;
}
.idea-card .stats-section .dot,
.idea-card .stat-box .dot {
  background: #4d4d4d 0 0 no-repeat padding-box;
  width: 4px;
  height: 4px;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 4px;
}
.idea-card .idea-comment-like-form {
  display: inline-block;
}
.idea-card .comment-section .catalyst > .comment-inner:first-child > .text {
  border: 1px solid #666666 !important;
  border-radius: 4px;
}
.idea-card .idea-actions > a {
  display: block;
  margin-left: 5px;
  font-size: 14px;
}
.idea-card .idea-read-more {
  font-size: 12px;
  margin-bottom: 0.5rem;
}
.idea-card .idea-ratings-section {
  background: #FFFFFF;
  border-radius: 5px;
  bottom: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  position: absolute;
  left: 0;
}

/***************************************************
    DETAIL PAGE
***************************************************/
/***************************************************
    LIST PAGE
***************************************************/
.ideas-sorting .nav-item {
  padding: 0;
}
.ideas-sorting .nav-item .nav-link {
  padding: 5px 7px;
}

.pv-collab .big-button {
  display: block;
  width: 28%;
  height: 60px;
  margin: 3rem auto;
  font-size: 20px;
}
.pv-collab .big-button:active {
  background-color: #00529c;
  border-color: #00529c;
}
.pv-collab .tags-item,
.pv-collab .theme-item {
  margin-top: 20px;
}
.pv-collab .tags-item a,
.pv-collab .theme-item a {
  font-size: 13px;
  display: inline-block;
  margin: 5px 5px;
  background-color: #707070;
  padding: 5px 15px;
  color: #fff;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.pv-collab .tags-item a.active-tag, .pv-collab .tags-item a.active-theme, .pv-collab .tags-item a:hover,
.pv-collab .theme-item a.active-tag,
.pv-collab .theme-item a.active-theme,
.pv-collab .theme-item a:hover {
  background-color: #4169A4;
  text-decoration: none;
  color: #fff;
}
.pv-collab #tags-button,
.pv-collab #theme-button {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.pv-collab #tags-button.active-tag, .pv-collab #tags-button.active-theme, .pv-collab #tags-button:hover,
.pv-collab #theme-button.active-tag,
.pv-collab #theme-button.active-theme,
.pv-collab #theme-button:hover {
  color: #4169A4;
  text-decoration: none;
}
/*.card-section .row:nth-of-type(2) {
  margin-top: 32px;
}*/
.idea-card,
.pv-collab {
  /*.card-date {
    margin-left: 10px;
    color: $white;

    i {
      margin-right: 5px;
    }
  }*/
}
.idea-card .card,
.pv-collab .card {
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px !important;
  -webkit-box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
  box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
  margin-bottom: 20px;
}
.idea-card .card-title,
.pv-collab .card-title {
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: bold !important;
  padding: 0 15px;
}
.idea-card .card-title a, .idea-card .card-title a:visited,
.pv-collab .card-title a,
.pv-collab .card-title a:visited {
  color: #000 !important;
}
.idea-card .card-title:hover,
.pv-collab .card-title:hover {
  text-decoration: underline;
}
.idea-card .card-title:hover a,
.pv-collab .card-title:hover a {
  color: #4B96AD !important;
}
.idea-card .col-score,
.pv-collab .col-score {
  display: flex;
}
@media (max-width: 767px) {
  .idea-card .col-score > p:first-child,
  .pv-collab .col-score > p:first-child {
    margin: 5px 0 !important;
  }
  .idea-card .col-score > p:first-child *,
  .pv-collab .col-score > p:first-child * {
    display: block;
  }
  .idea-card .col-score > p:first-child > small,
  .idea-card .col-score > p:first-child > span,
  .pv-collab .col-score > p:first-child > small,
  .pv-collab .col-score > p:first-child > span {
    padding: 0;
    text-align: center;
  }
}
.idea-card .col-score small,
.pv-collab .col-score small {
  display: inline-block;
  font-size: 70%;
  padding-right: 0.4em;
}
.idea-card .card-image,
.pv-collab .card-image {
  position: relative;
  max-height: 200px;
  display: block;
  z-index: 10;
  overflow: hidden;
}
.idea-card .card-image:hover,
.pv-collab .card-image:hover {
  opacity: 0.9;
}
.idea-card .card-img,
.pv-collab .card-img {
  border-radius: 5px 5px 0px 0px !important;
}
.idea-card .card-tags,
.idea-card .card-icons,
.idea-card .card-score,
.pv-collab .card-tags,
.pv-collab .card-icons,
.pv-collab .card-score {
  position: absolute;
}
.idea-card .card-tags,
.pv-collab .card-tags {
  bottom: 10px;
  left: 10px;
}
.idea-card .card-score,
.pv-collab .card-score {
  color: #fff;
  top: 10px;
  right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}
.idea-card .card-score span,
.pv-collab .card-score span {
  display: block;
  font-size: 24px;
  -webkit-text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
  -moz-text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
  text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
}
.idea-card .card-icons,
.pv-collab .card-icons {
  bottom: 10px;
  right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-size: 13px;
}
.idea-card .card-icons .icon i,
.pv-collab .card-icons .icon i {
  margin-right: 5px;
}
.idea-card .card-icons .icon:not(:last-child),
.pv-collab .card-icons .icon:not(:last-child) {
  margin-right: 10px;
}
.idea-card .card-tags,
.idea-card .card-score,
.idea-card .card-icons,
.pv-collab .card-tags,
.pv-collab .card-score,
.pv-collab .card-icons {
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.65) !important;
}
.idea-card .card-theme-tag,
.pv-collab .card-theme-tag {
  color: #fff;
  font-size: 13px;
  font-style: italic;
}
.idea-card .card-theme-tag:hover,
.pv-collab .card-theme-tag:hover {
  text-decoration: none;
  color: #4169A4;
}
.idea-card .card-theme-tag:first-child:before,
.pv-collab .card-theme-tag:first-child:before {
  content: "";
}
.idea-card .card-theme-tag:before,
.pv-collab .card-theme-tag:before {
  content: " | ";
}
.idea-card .card-block,
.pv-collab .card-block {
  padding: 20px;
  z-index: 999;
  height: 380px;
}
.idea-card .card-block .card-title:hover,
.pv-collab .card-block .card-title:hover {
  color: #4169A4;
}
.idea-card .card-subtitle,
.pv-collab .card-subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  margin-top: 10px !important;
  color: #707070;
}
.idea-card .card-subtitle .card-author a:hover,
.pv-collab .card-subtitle .card-author a:hover {
  opacity: 0.8;
  text-decoration: none;
}
.idea-card .card-text,
.pv-collab .card-text {
  color: #707070;
  margin-bottom: 20px !important;
}
.idea-card .card-button,
.pv-collab .card-button {
  display: block;
  width: 120px;
  height: 40px;
  cursor: pointer;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  text-align: center;
  line-height: 40px;
  font-weight: 400;
  background-color: #4169A4;
  color: #fff;
  outline: none;
  border-radius: 3px;
  text-decoration: none;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.idea-card .card-button i,
.pv-collab .card-button i {
  margin-left: 5px;
}
.idea-card .card-button:focus,
.pv-collab .card-button:focus {
  outline: none;
}
.idea-card .card-button:hover,
.pv-collab .card-button:hover {
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}
.idea-card .btn-follow,
.pv-collab .btn-follow {
  color: #000;
}
.idea-donate-cont {
  background: rgba(0, 0, 0, 0.6);
}
.idea-donate-cont .donate-bg {
  background-image: url("/images/demo/donation_bg.svg");
  background-size: 32%;
  background-position: center;
  background-attachment: fixed;
}
.idea-donate-cont .donate-bg .don-text {
  margin: 2.5rem auto;
}
.idea-donate-cont .donate-bg .don-text .btn-light {
  background-color: #fff;
  border-color: #fff;
}

.replies-btn.no-replies {
  vertical-align: initial !important;
}

.view-ideas .mfp-iframe-scaler iframe {
  min-height: 600px;
}

.idea-page a,
#ideas a {
  text-decoration: none;
}
@media (max-width: 991.98px) {
  .idea-page .idea-container,
  #ideas .idea-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 991.98px) {
  #ideas-page-body .stats-section {
    flex-wrap: wrap;
  }
}

/*
  Comment
 */
.single-reply .before-text {
  flex-wrap: nowrap !important;
}

.theme-collab {
  color: #707070;
}

#pv-challenge-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0 !important;
}
#pv-challenge-wrapper h1 {
  border-bottom: 0;
}

.pv-collab.mod-challenge {
  padding: 1rem;
}
.pv-collab.mod-challenge .page-header {
  margin-top: 0;
  border-bottom: 0;
  flex-direction: row;
}
.pv-collab.mod-challenge .page-header .challenge-icon-col {
  align-self: center;
}
@media (max-width: 767px) {
  .pv-collab.mod-challenge .page-header .challenge-icon-col {
    align-self: initial;
  }
}
.pv-collab.mod-challenge .page-header h1 {
  color: #fff;
  border: 0;
}
.pv-collab.mod-challenge p {
  color: #fff;
}
.pv-collab.mod-challenge #idea-desc {
  resize: none;
  width: calc(100% - 45px);
  height: auto;
}
.pv-collab.mod-challenge #idea-desc::placeholder {
  padding: 2.8rem;
  font-size: 14px;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .pv-collab.mod-challenge #idea-desc::placeholder {
    padding: 1.2rem 0;
  }
}
.pv-collab.mod-challenge #add-idea {
  box-shadow: none;
  background-color: #146094;
  border-color: #146094;
  color: #fff;
}
.pv-collab.mod-challenge #add-idea:focus, .pv-collab.mod-challenge #add-idea:hover {
  color: #d9d9d9;
}

@media (max-width: 575.98px) {
  .mod-challenge > div.d-flex,
  .mod-challenge-countdown > div.d-flex {
    display: block !important;
  }
}
.mod-challenge .time-remaining,
.mod-challenge-countdown .time-remaining {
  max-width: inherit !important;
}
.mod-challenge a,
.mod-challenge-countdown a {
  color: #fff;
}

.mod-challenge-countdown {
  border-radius: 4px;
  border: 2px solid #146094;
  padding: 0.6rem;
  text-align: center;
}
.mod-challenge-countdown .d-flex-countdown-clock {
  align-items: center;
  justify-content: center;
  /*margin-left: auto;
  margin-right: auto;*/
}
.mod-challenge-countdown .countdown-title {
  font-size: 15px;
  font-style: italic;
}
.mod-challenge-countdown .countdown_section {
  display: block;
  font-size: 90%;
  font-style: normal;
}
.mod-challenge-countdown .countdown_section .countdown_amount {
  font-size: 110%;
  font-style: italic;
  padding: 0 1rem;
}
@media (max-width: 767px) {
  .mod-challenge-countdown .countdown_section .countdown_amount {
    padding: 0.75rem;
  }
}
.mod-challenge-countdown.mod-challenge-info {
  border: none;
  border: 0;
  padding: 0;
  margin: 1rem 0;
}

.mod-challenge-info .btn-challenge-info {
  color: #146094;
  font-weight: bold;
}
.mod-challenge-info .btn-challenge-info.collapsed .fa:before {
  content: "\f0da";
}
.mod-challenge-info .btn-challenge-info .fa:before {
  content: "\f0d7";
}
.mod-challenge-info .mod-challenge-detail-wrapper {
  border-radius: 4px;
  border: 2px solid #146094;
  margin: 1rem 0;
  padding: 1rem;
}
.mod-challenge-info ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.mod-challenge-info ul li.item-label,
.mod-challenge-info ul li.nav-label {
  border: none;
  border-radius: 0;
  color: #146094;
  font-weight: bold;
  padding: 0.375rem 0.75rem;
}
.mod-challenge-info ul li,
.mod-challenge-info ul .list-item {
  border: 1px solid #eee;
  border-radius: 0.2rem;
  display: flex;
  padding: 0;
}
.mod-challenge-info ul li .value,
.mod-challenge-info ul .list-item .value {
  background-color: #707070;
  border-radius: 0.2rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
  display: block;
  padding: 0.375rem 0.75rem;
}
.mod-challenge-info ul li .text,
.mod-challenge-info ul .list-item .text {
  display: block;
  padding: 0.375rem 0.75rem;
}
.mod-challenge-info .mod-challenge-info-tags a.tag {
  color: #000;
  display: flex;
  padding: 0;
}
.mod-challenge-info .mod-challenge-detail-info {
  margin: 1rem 0 0;
}
.mod-challenge-info .mod-challenge-detail-info section {
  border: 1px solid #707070;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
}
.mod-challenge-info .mod-challenge-detail-info section:nth-child(2) {
  margin-top: 1rem;
}

#story-form {
  margin-top: 50px;
}
#story-form h4 {
  line-height: 1.225;
}
#story-form .form-group {
  /* disable auto-zoom on iphone input field focus */
}
#story-form .form-group .mce-panel {
  font-size: 16px;
}
#story-form .form-group .mce-panel label {
  font-weight: 500;
  font-size: 1rem;
}
#story-form .form-group label:not(.filepond--drop-label label) {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}
#story-form .form-group label:not(.filepond--drop-label label) span {
  color: #1071B1;
}
#story-form .form-group label:not(.filepond--drop-label label) span.filepond--label-action {
  color: #1071B1;
}
#story-form .form-group .file-loading a.filepond--credits {
  display: none;
}
#story-form .form-group .custom-file,
#story-form .form-group input:not(.form-check-input) {
  border-radius: 3px;
  color: #737373;
  font-weight: 400 !important;
  height: 40px;
  width: 100%;
  font-size: 14px;
  line-height: 23px;
}
#story-form .form-group .custom-file::-webkit-input-placeholder,
#story-form .form-group input:not(.form-check-input)::-webkit-input-placeholder {
  color: #737373;
}
#story-form .form-group .custom-file:-ms-input-placeholder,
#story-form .form-group input:not(.form-check-input):-ms-input-placeholder {
  color: #737373;
}
#story-form .form-group .custom-file::-ms-input-placeholder,
#story-form .form-group input:not(.form-check-input)::-ms-input-placeholder {
  color: #737373;
}
#story-form .form-group .custom-file::placeholder,
#story-form .form-group input:not(.form-check-input)::placeholder {
  color: #737373;
}
#story-form .form-group.form-check input {
  width: 1.2rem;
  height: 1.2rem;
  vertical-align: sub;
}
#story-form .form-group.form-check label {
  padding: 0 10px;
}
#story-form .form-group textarea {
  border: 1px solid #737373;
  border-radius: 3px;
}
#story-form .form-group input:focus, #story-form .form-group textarea:focus {
  font-size: 16px;
}
#story-form .form-group .mce-panel * {
  font-size: 16px;
}
#story-form .form-group #story-form .form-group .mce-panel .mce-container-body {
  font-size: 16px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #story-form .form-group select:focus,
  #story-form .form-group textarea:focus,
  #story-form .form-group input:focus {
    font-size: 16px;
  }
}
#story-form .custom-file,
#story-form .custom-select {
  height: 40px !important;
  color: #737373 !important;
  border: 1px solid #737373;
  border-radius: 3px !important;
  font-size: 14px;
  line-height: 23px;
  width: 100%;
}
#story-form .input-group {
  height: 50px !important;
  line-height: 50px;
}
#story-form .file-preview {
  border-radius: 3px;
  border: 1px solid #737373;
  padding: 5px;
  width: 100%;
  height: 100% !important;
  margin-bottom: 10px;
}
#story-form .file-drop-zone {
  margin: 0 !important;
  border: 1px dashed #737373 !important;
}
#story-form .file-drop-zone-title {
  color: #737373 !important;
  font-size: 24px;
  line-height: 190px;
  cursor: default;
}
#story-form .form-control, #story-form .story-form2 .editor, .story-form2 #story-form .editor {
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 40px;
  color: #737373;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #737373;
  border-radius: 3px !important;
}
#story-form .form-control.file-caption, #story-form .story-form2 .file-caption.editor, .story-form2 #story-form .file-caption.editor {
  width: auto;
  height: 50px;
}
#story-form .form-control.file-caption .file-caption-name, #story-form .story-form2 .file-caption.editor .file-caption-name, .story-form2 #story-form .file-caption.editor .file-caption-name {
  width: 100%;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: none;
  background: none;
  outline: none;
}
#story-form .btn-file input[type=file] {
  background-color: #1071B1 !important;
}
#story-form .btn {
  padding: 0.5rem 20px !important;
  cursor: pointer !important;
}
#story-form .btn-success {
  background-color: #26374a !important;
  color: #fff;
}
#story-form .btn.btn-primary.btn-file {
  background-color: #1071B1 !important;
  line-height: 50px;
  cursor: pointer !important;
}
#story-form .btn.btn-primary.btn-file .hidden-xs {
  padding: 0;
  margin: 0;
  line-height: 50px;
  cursor: pointer !important;
}
#story-form .fileinput-upload-button {
  display: none !important;
}
#story-form .fileinput-remove-button {
  background-color: red !important;
}
#story-form .button-group {
  margin-top: 30px;
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#story-form .button-group.text-center > .primary-button {
  display: inline-block;
}
#story-form .button-group .primary-button {
  background-color: #4169a4;
  border: none;
  border-radius: 3px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  line-height: 60px;
  height: 60px;
  outline: none;
  transition: all 0.2s ease;
  text-align: center;
  text-decoration: none;
  width: 270px;
  -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
#story-form .button-group .primary-button:focus {
  outline: none;
}
#story-form .button-group .primary-button:hover {
  opacity: 0.8;
}
#story-form .button-group .primary-button:disabled {
  opacity: 0.7;
}
#story-form .button-group .save-as-draft {
  border: none;
  cursor: pointer;
  margin-left: 50px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
#story-form .button-group .save-as-draft:hover {
  opacity: 0.8;
}
#story-form .select2-container .select2-search__field {
  margin-top: 0px !important;
}
#story-form .select2-container--default .select2-selection--multiple {
  border-color: #737373;
  height: auto;
}
#story-form .custom-file {
  height: 40px;
  line-height: 40px !important;
  font-size: 1rem;
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}
#story-form .custom-file .custom-file-input {
  position: relative;
  z-index: 2;
  margin: 0;
  opacity: 0;
}
#story-form .custom-file input[type=file] {
  border: none;
}
#story-form .custom-file input[lang=en] + label:after {
  content: "Browse...";
  color: #fff;
  background-color: #707070 !important;
}
#story-form .custom-file input[lang=fr] + label:after {
  content: "Feuilleter...";
  color: #fff;
  background-color: #707070 !important;
}
#story-form .custom-file label.custom-file-label {
  height: 38px;
  border: none !important;
  line-height: 40px !important;
  overflow: hidden;
  padding: 0 0.75rem;
  white-space: nowrap;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  margin-bottom: 0.5rem;
}
#story-form .custom-file label.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
  padding: 0.375rem 0.75rem;
}
#story-form .custom-file label.custom-file-label:after {
  align-items: center;
  background-color: #4169A4 !important;
  color: #fff;
  display: flex;
  font-size: initial;
  height: 40px;
  justify-content: center;
  line-height: 40px !important;
  text-align: center;
  width: 117px;
}
#story-form .custom-file label.custom-file-label .filename {
  width: 73%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0;
  color: #5F5F5F;
  vertical-align: sub;
}
@media (max-width: 767.98px) {
  #story-form .custom-file label.custom-file-label .filename {
    width: 60%;
  }
}
#story-form .custom-file label.custom-file-label button {
  position: absolute;
  z-index: 500;
}
@media (max-width: 767.98px) {
  #story-form .custom-file label.custom-file-label button {
    right: 31%;
  }
}
@media (min-width: 576px) {
  #story-form .custom-file label.custom-file-label button {
    right: 22%;
  }
}
@media (min-width: 768px) {
  #story-form .custom-file label.custom-file-label button {
    right: 30%;
  }
}
@media (min-width: 992px) {
  #story-form .custom-file label.custom-file-label button {
    right: 25%;
  }
}
@media (min-width: 1200px) {
  #story-form .custom-file label.custom-file-label button {
    right: 22%;
  }
}
#story-form #submit-btn {
  border: 0;
  background-color: transparent;
}
#story-form input, #story-form select, #story-form textarea, #story-form .mce-panel {
  font-size: 16px !important;
}
#story-form .file-remove-image img {
  width: 150px;
  height: 150px;
}
@media (max-width: 991.98px) {
  #story-form .file-remove-image img {
    max-width: 100%;
  }
}

.btn-story-primary {
  background-color: #2b7c82 !important;
  color: #fff !important;
}

@media (max-width: 575px) {
  .form-group label {
    font-size: 16px;
  }
  .form-group input:not(.form-check-input) {
    height: 35px;
  }
  .form-group textarea {
    font-size: 13px;
  }
  .form-group .custom-select,
  .form-group .my-tagsinput {
    height: 35px !important;
    font-size: 13px !important;
  }
  .form-group .tag {
    margin-right: 5px;
  }
  .form-group .file-drop-zone-title {
    font-size: 16px;
  }
  .form-group .input-group {
    height: 35px !important;
    line-height: 35px;
  }
  .form-group .btn.btn-primary.btn-file {
    background-color: #1592e6 !important;
    line-height: 35px;
    cursor: pointer !important;
  }
  .form-group .btn.btn-primary.btn-file .hidden-xs {
    font-size: 13px !important;
    padding: 0;
    margin: 0;
    line-height: 35px;
    cursor: pointer !important;
  }
  .form-group .button-group {
    margin-top: 10px;
  }
  .form-group .button-group .primary-button {
    height: 45px;
    line-height: 45px;
  }
}
/***************************************************
    DETAIL PAGE
***************************************************/
.inner {
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
}
.inner h1 {
  font-size: 3rem;
  /*margin-top: 3rem;
  margin-bottom: 2rem;*/
  line-height: 1 !important;
  font-weight: 500;
  border-bottom: 0;
}
.inner h2 {
  font-size: 3rem;
  font-family: sans-serif;
  margin-bottom: 1rem;
  letter-spacing: 0.1rem;
}
.inner h3 {
  font-size: 2rem;
  font-family: sans-serif;
  letter-spacing: 0.1rem;
}
.inner h3.author {
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1;
}
.inner h3.authors-title {
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1;
}
.inner h3 a.story-detail-author, .inner h3 span.tag-label {
  font-size: 1.3rem;
  text-decoration: none;
}
.inner h3 a {
  font-size: 1.2rem;
}
.inner h3 a:hover {
  color: #3292D8;
}
.inner h3 .story-author {
  text-transform: capitalize;
  color: #3292D8;
}
.inner ul,
.inner ol {
  list-style: initial;
  margin-left: 30px;
  width: 80%;
}
.inner ul li,
.inner ol li {
  text-align: left;
}
.inner ul li,
.inner ol li,
.inner p {
  font-family: sans-serif;
  font-size: 1rem;
  letter-spacing: 0rem;
}
.inner .smodule-maintitle {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("/images/stories/placeholders/tag_nurse_1.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
}
.inner .smodule-maintitle .smodule-content {
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  z-index: 1;
}
.inner .smodule-maintitle .smodule-content h1 {
  color: #000;
  border-bottom: 0;
}
.inner .smodule-maintitle .smodule-content a.highlight:hover {
  color: #3292D8;
}
.inner .smodule-maintitle .smodule-content__info a {
  font-weight: 400;
  font-size: 1.3rem;
  text-decoration: none;
}
.inner .smodule-maintitle .smodule-content__info a:hover {
  color: #3292D8;
}
.inner .smodule-maintitle .smodule-content__info small {
  color: #000;
  font-size: 1.25rem;
  font-weight: 400;
  display: inline-block;
}
@media screen and (max-width: 600px) {
  .inner .smodule-maintitle {
    height: auto;
  }
  .inner .smodule-maintitle .smodule-content {
    margin: 0;
  }
}
.inner .smodule-small {
  display: flex;
  justify-content: center;
  padding: 100px 0 100px 0;
  text-align: center;
  background-image: none !important;
}
.inner .smodule-small + .smodule-small {
  padding-top: 0;
}
.inner .smodule-small .smodule-content {
  width: 600px;
  max-width: 80%;
}
.inner .smodule-small .smodule-content img {
  width: 100%;
  margin: 50px 0 50px 0;
}
.inner .smodule-medium, .inner .smodule-video {
  display: flex;
  align-items: center;
  text-align: center;
  background-image: url("/images/stories/placeholders/tag_mychart_1.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
}
.inner .smodule-medium .smodule-content, .inner .smodule-video .smodule-content {
  width: 550px;
  max-width: 50%;
  margin: 6.7rem 50px 6.7rem auto;
  padding: 3.5rem;
  background-color: rgba(250, 253, 255, 0.95);
}
.inner .smodule-video {
  background-image: none;
}
.inner .smodule-video .smodule-content {
  padding: 0 !important;
  margin: 0 auto !important;
  width: 100%;
  max-width: 100%;
}
.inner .smodule-video .smodule-content .poster-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.inner .smodule-video .smodule-content .poster-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.inner .smodule-video .smodule-content .poster-wrapper #poster-image {
  background-size: cover !important;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1;
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  transition: all 0.3s ease-in;
}
.inner .smodule-video .smodule-content .poster-wrapper .play-btn {
  position: absolute;
  height: 80px;
  top: 50%;
  margin-top: -40px;
  left: 50%;
  margin-left: -40px;
  cursor: pointer;
  opacity: 0.5;
  z-index: 10;
}
.inner .smodule-video .smodule-content .poster-wrapper .play-btn:hover {
  opacity: 1;
}
.inner .smodule-video .smodule-content .poster-wrapper #video.embed-responsive {
  padding-bottom: 56.25%;
}
.inner .smodule-large {
  display: flex;
  align-items: center;
  text-align: center;
  background-image: url("/images/stories/placeholders/tag_doctor_1.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
}
.inner .smodule-large .smodule-content {
  width: 550px;
  max-width: 50%;
  margin-left: auto;
  margin-right: 50px;
  padding: 3.5rem;
  background-color: rgba(250, 253, 255, 0.95);
}
.inner .smodule-mbg-wrapper {
  z-index: -10;
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.inner .smodule-mbg-wrapper .smodule-mbg {
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
}
.inner .smodule-mbg-wrapper .smodule-mbg + .smodule-mbg {
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
}
.inner .mirrored:not(.smodule-small) {
  /*!*--- Temporary change of bg, please ignore ---*!
  &.smodule-medium {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }*/
}
.inner .mirrored:not(.smodule-small) > .smodule-content {
  margin-left: 40px;
  margin-right: auto;
}
.inner .mirrored:not(.smodule-small).smodule-large {
  position: relative;
}
@media screen and (max-width: 992px) {
  .inner .smodule-content {
    max-width: 100% !important;
    width: 100% !important;
    padding: 1.25rem !important;
  }
  .inner .smodule-content p {
    font-size: 16px;
    line-height: 1.5;
  }
  .inner .smodule-maintitle .smodule-content {
    margin: 27% 0 0;
  }
  .inner .smodule-medium .smodule-content, .inner .smodule-video .smodule-content, .inner .smodule-large .smodule-content {
    margin: 62% 0 0 !important;
    background-color: #fff;
  }
  .inner .smodule-video .smodule-content {
    margin: 0 !important;
  }
  .inner .smodule-maintitle, .inner .video-cover {
    padding: 0 2rem;
  }
  .inner .smodule-maintitle h1, .inner .video-cover h1 {
    font-size: 2.5rem;
  }
  .inner .smodule-small {
    padding: 0;
  }
  .inner .smodule-small .smodule-content {
    margin: 0 !important;
  }
  .inner .smodule-small.mirrored .smodule-content {
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media screen and (max-width: 800px) {
  .inner .smodule-medium, .inner .smodule-video, .inner .smodule-large {
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: unset;
    background-position: unset;
  }
}
@media (max-width: 575.98px) {
  .inner .smodule-content {
    text-align: left;
  }
}

.video-cover .smodule-content__info a {
  font-weight: 400;
  font-size: 1.3rem;
  text-decoration: none;
}
.video-cover .smodule-content__info a:hover {
  color: #3292D8;
}
.video-cover .smodule-content__info small {
  color: #000;
  font-size: 1.25rem;
  font-weight: 400;
  display: inline-block;
}

/***************************************************
    LIST PAGE
***************************************************/
.story-card {
  color: #fff;
  background: #146094 !important;
}

/*Updated Design*/
@media (max-width: 767.98px) {
  .stories-inner {
    padding-left: 0;
    padding-right: 0;
  }
}
.stories-inner.container-fluid .container, .stories-inner.container-sm .container, .stories-inner.container-md .container, .stories-inner.container-lg .container, .stories-inner.container-xl .container, .stories-inner.container-fluid .menu-bg-color .custom, .menu-bg-color .stories-inner.container-fluid .custom, .stories-inner.container-sm .menu-bg-color .custom, .menu-bg-color .stories-inner.container-sm .custom, .stories-inner.container-md .menu-bg-color .custom, .menu-bg-color .stories-inner.container-md .custom, .stories-inner.container-lg .menu-bg-color .custom, .menu-bg-color .stories-inner.container-lg .custom, .stories-inner.container-xl .menu-bg-color .custom, .menu-bg-color .stories-inner.container-xl .custom, .stories-inner.container-fluid footer#wb-info .custom, footer#wb-info .stories-inner.container-fluid .custom, .stories-inner.container-sm footer#wb-info .custom, footer#wb-info .stories-inner.container-sm .custom, .stories-inner.container-md footer#wb-info .custom, footer#wb-info .stories-inner.container-md .custom, .stories-inner.container-lg footer#wb-info .custom, footer#wb-info .stories-inner.container-lg .custom, .stories-inner.container-xl footer#wb-info .custom, footer#wb-info .stories-inner.container-xl .custom {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.stories-inner .story-tags {
  padding: 0 1.125rem 0.675rem;
}
.stories-inner .story-tags.tags-position {
  margin-top: -83px !important;
}
@media (max-width: 900px) {
  .stories-inner .story-tags {
    margin-top: -125px !important;
  }
}
.stories-inner .story-preview {
  bottom: 0;
  width: 100%;
  background-color: white !important;
  transition: all 0.5s ease;
}
.stories-inner .story-preview a.story-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  color: #000000;
  opacity: 1;
  text-decoration: none;
}
.stories-inner .story-preview a.story-title:hover {
  text-decoration: underline !important;
  color: #4b96ad;
}
.stories-inner .story-preview .story-description {
  max-height: 0;
  overflow: hidden;
  transition: 0.6s;
}
.stories-inner .story-preview .story-stats {
  text-align: left;
  font-size: 0.75rem;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
  opacity: 1;
  /*margin: 1.9rem auto .8rem !important;*/
  margin-left: auto;
  margin-right: auto;
}
.stories-inner .story-preview .story-stats > div.col-auto,
.stories-inner .story-preview .story-stats a.load-comments {
  margin-bottom: 1rem !important;
}
.stories-inner .story-preview .story-stats .dot {
  background: #4d4d4d 0 0 no-repeat padding-box;
  width: 4px;
  height: 4px;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 4px;
}
.stories-inner .story-preview .story-stats .read-more:hover {
  text-decoration: underline !important;
  color: #4b96ad;
}
.stories-inner .story-preview .story-stats .load-comments:hover {
  text-decoration: underline !important;
}
.stories-inner .hover-float {
  /*display: flex;*/
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.2s !important;
  transition-property: all !important;
  transition-timing-function: ease-out;
}
.stories-inner .hover-float:hover, .stories-inner .hover-float:focus, .stories-inner .hover-float:active {
  transform: translateY(-2px);
  /*.story-preview h3{
      min-height: inherit;
  }*/
}
.stories-inner .hover-primary:hover, .stories-inner .hover-primary:focus, .stories-inner .hover-primary:active {
  color: #3292D8 !important;
}

/* "you may also like" pictures don't need to be 500px high */
#my-stories.smaller-pictures .story.card img.card-img-top, .pv-stories-wrapper .section-card #my-stories.smaller-pictures .row .inner .story.card a.vimeo-popup img {
  height: 256px;
}

#my-stories img.card-img-top, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img {
  min-height: 200px;
}

.pv-stories-wrapper .hero {
  text-align: center;
  margin: 2.5rem 0;
}
.pv-stories-wrapper .shortby a {
  color: #000000;
  margin-left: 30px;
}
.pv-stories-wrapper .shortby a.active {
  color: #4169a4;
}
.pv-stories-wrapper .shortby a:hover {
  color: #4169a4;
  text-decoration: none;
}
.pv-stories-wrapper .section-card {
  margin: 30px 0;
}
.pv-stories-wrapper .section-card .sorting-cont {
  margin-bottom: 1.2rem;
}
.pv-stories-wrapper .section-card #my-stories .row {
  margin-bottom: 0;
}
.pv-stories-wrapper .section-card #my-stories .row .inner {
  margin-bottom: 1.5rem;
}
.pv-stories-wrapper .section-card #my-stories .row .inner .card {
  height: 100%;
}
.pv-stories-wrapper .section-card #my-stories .row .inner .card .card-button-group {
  position: relative;
}
.pv-stories-wrapper .section-card #my-stories .row .inner .card .card-button-group .left {
  position: absolute;
  left: 0;
  bottom: -20px;
}
.pv-stories-wrapper .section-card #my-stories .row .inner .card .card-button-group .right {
  position: absolute;
  bottom: -11px;
  right: 0;
}
.pv-stories-wrapper .video {
  position: relative;
}
.pv-stories-wrapper .video i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 60px;
  color: #fff;
}
.pv-stories-wrapper .card {
  border: none !important;
  border-radius: 4px !important;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
}
.pv-stories-wrapper .card a .card-img-top, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img {
  position: relative;
  height: 250px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.pv-stories-wrapper .card a .card-img-top:hover, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img:hover {
  opacity: 0.9;
}
.pv-stories-wrapper .card a .card-title {
  color: #000;
  margin: 0 5px 5px 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  min-height: 60px;
  max-height: 65px;
  overflow: hidden;
}
.pv-stories-wrapper .card a .card-title:hover {
  color: #4169a4;
}
.pv-stories-wrapper .card .card-block {
  padding: 30px 20px;
}
.pv-stories-wrapper .card .card-block .card-info-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 53px;
}
.pv-stories-wrapper .card .card-block .card-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
}
.pv-stories-wrapper .card .card-block .card-icons .icon:not(:last-child) {
  margin-right: 10px;
}
.pv-stories-wrapper .card .card-block .card-icons .icon {
  display: inherit;
}
.pv-stories-wrapper .card .card-block .card-icons .icon img {
  margin-top: 0;
}
.pv-stories-wrapper .card .card-block .card-icons .icon i:hover {
  color: #4169a4;
}
.pv-stories-wrapper .card .card-block .card-icons .icon span {
  margin-left: 5px;
  margin-top: -3px;
}
.pv-stories-wrapper .card .card-block .card-info {
  font-size: 12px;
  margin: 10px auto;
}
.pv-stories-wrapper .card .card-block .card-info .category {
  color: #4169a4;
}
.pv-stories-wrapper .card .card-block .card-info .author {
  color: #000000;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.pv-stories-wrapper .card .card-block .card-info .author:hover {
  color: #4169a4;
}
.pv-stories-wrapper .card .card-block .card-text {
  line-height: 20px;
  height: 80px;
  overflow: hidden;
}
.pv-stories-wrapper .card .card-block .card-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-top: 30px;
}
.pv-stories-wrapper .card .card-block .card-button-group .right {
  padding: 0 1.2rem;
}
.pv-stories-wrapper .card .card-block .card-button-group .edit-button {
  color: #000000;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-right: 10px;
  padding: 0 1.2rem;
}
.pv-stories-wrapper .card .card-block .card-button-group .edit-button:hover {
  color: #4169a4;
}
.pv-stories-wrapper .card .card-block .card-button-group .story-abuse-link {
  color: #4169a4;
}
@media (max-width: 767.98px) {
  .pv-stories-wrapper .hero {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .pv-stories-wrapper .section-card {
    margin: 20px 0;
  }
  .pv-stories-wrapper .shortby a {
    margin-left: 20px;
  }
  .pv-stories-wrapper .card a .card-img-top, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img {
    height: 180px;
  }
  .pv-stories-wrapper .card .card-block {
    padding: 20px 10px;
  }
  .pv-stories-wrapper .card .card-block .card-button-group {
    margin-top: 20px;
  }
  .pv-stories-wrapper .b-button {
    width: 100%;
    margin: 1rem auto;
  }
  .pv-stories-wrapper .left {
    bottom: -12px !important;
  }
  .pv-stories-wrapper .right {
    bottom: -3px !important;
  }
}
@media (max-width: 1199.98px) {
  .pv-stories-wrapper .row > *:not(:last-child) {
    margin-bottom: 20px;
  }
}

.banner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  width: 100%;
  height: 125px;
  margin-top: 50px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
}

.banner-img {
  position: absolute;
  display: block;
  top: -20px;
  left: 10px;
}

.banner-text {
  max-width: 500px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  font-size: 16px;
  color: #fff;
  margin-right: 20px;
  margin-left: 150px;
}

.banner-button {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-right: 20px;
}
.banner-button a {
  display: block;
  width: 180px;
  height: 50px;
  background-color: #fff;
  border-radius: 100px;
  text-align: center;
  line-height: 50px;
  color: #00529c;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.banner-button a:hover {
  -webkit-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pv-stories-page #pv-stories.card-columns {
    column-count: 2;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pv-stories-page #pv-stories.card-columns {
    column-count: 2;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pv-stories-page #pv-stories.card-columns {
    column-count: 2;
  }
}
@media (min-width: 1200px) {
  .pv-stories-page #pv-stories.card-columns {
    column-count: 2;
  }
}
.pv-stories-page .sort-by-links a.sort-by {
  display: inline-block;
}
.pv-stories-page .sort-by-links a.sort-by.active {
  text-decoration: underline;
  color: #4169a4;
}
.pv-stories-page .card-img-top.video-container, .pv-stories-page .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img.video-container, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup .pv-stories-page img.video-container {
  height: 166px;
}
.pv-stories-page .card-img-top.video-container .vimeo-popup, .pv-stories-page .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img.video-container .vimeo-popup, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup .pv-stories-page img.video-container .vimeo-popup {
  display: block;
  height: 166px;
}

@media (max-width: 575.98px) {
  .story .row {
    margin-bottom: 0 !important;
  }
  .story .row section {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
.story .card-title small {
  display: block;
}
.story .story-description p:last-child {
  margin-bottom: 0;
}

.story-category {
  font-size: 50%;
}

/** Form 2 **/
.story-form2 textarea {
  width: 100%;
}
.story-form2 .btn-toolbar {
  border: #0b55c4;
  position: absolute;
  top: 1rem;
  right: 2em;
  opacity: 0.7;
  z-index: 1060;
}
.story-form2 .btn-toolbar .btn {
  color: #fff;
  background-color: #0b70cd;
  border: #0d47a1 inset 1px;
}
.story-form2 .btn-toolbar:hover {
  opacity: 1;
}
.story-form2 .story-media-col {
  position: relative;
}
.story-form2 .story-media-col img.story-image-preview {
  width: 100%;
  height: 400px;
  position: relative;
}
.story-form2 .story-media-col .vimeo-embed {
  position: absolute;
  top: 0;
  z-index: 0;
}
.story-form2 .story-media-col .progress-container {
  padding: 0 1rem;
  position: absolute;
  top: 150px;
  width: 100%;
  display: none;
}

@media (max-width: 991.98px) {
  .banner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 350px;
  }
  .banner-img {
    position: absolute;
    display: block;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .banner-text {
    text-align: center;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
    margin-top: 150px;
  }
  .banner-button {
    margin-right: 0;
    margin-bottom: 30px;
  }
  /*.layout-single{
      font-size: 70%;
  }*/
}
@media (max-width: 575.98px) {
  .banner-text {
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 20px;
    margin-top: 120px;
  }
  .banner-img {
    top: -43px;
  }
  /*.layout-single {
      font-size: 50%;
  }*/
  .h-down {
    height: 350px !important;
  }
}
@media screen and (max-width: 400px) {
  .banner {
    height: auto;
  }
  .banner .banner-text {
    margin-top: 85px;
  }
}
.donate-cont {
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}
.donate-cont .donate-bg {
  background-image: url("/images/demo/donation_bg.svg");
  height: 250px;
  background-size: 32%;
  background-position: center;
  background-attachment: fixed;
}
.donate-cont .donate-bg .don-text {
  margin-top: 1.4rem;
}
.donate-cont .donate-bg .don-text .btn-light {
  background-color: #fff;
  border-color: #fff;
}

@media (max-width: 575.98px) {
  .add-story {
    width: 100%;
  }
}
.card-columns .story.card {
  margin-bottom: 0;
}

.video-container {
  width: 100%;
}
.video-container .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}
.video-container .play-btn:hover {
  opacity: 0.8;
}
.video-container img {
  width: 100%;
}

#story-form .form-group .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}
#story-form .form-group .custom-file .custom-file-input {
  position: relative;
  z-index: 2;
  margin: 0;
  opacity: 0;
}
#story-form .form-group .custom-file .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  margin-bottom: 0.5rem;
}
#story-form .form-group .custom-file .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
  padding: 0.375rem 0.75rem;
}
#story-form .form-group .mce-panel label {
  font-weight: 500;
  font-size: 1rem;
}

#pv-story-wrapper textarea {
  resize: none;
  width: calc(100% - 45px);
  height: auto;
  overflow: hidden;
}
#pv-story-wrapper textarea::placeholder {
  padding: 1rem 0;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  transform: translateX(-30px);
}
#pv-story-wrapper #add-story,
#pv-story-wrapper #login-link {
  box-shadow: none;
  background-color: #26374a;
  border-color: #26374a;
  /*border-color: #146094;*/
  color: #fff;
  font-size: 1.2em;
  padding: 10px 14px;
}

.btn-success:hover {
  background-color: #1c578a !important;
  border-color: #1c578a !important;
}

/*
  Comment
 */
/*.comment-section {
  margin-top: 50px;
  h3 {
    font-size: 24px !important;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.comment {
  margin-bottom: 30px;
  clear: both;
}*/
.comment-head, .reply-head {
  display: flex;
  justify-content: space-between;
}
.comment-head .avatar, .reply-head .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.comment-head .author, .reply-head .author {
  color: #3E65A6;
  font-weight: 600 !important;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.comment-head .author:hover, .reply-head .author:hover {
  opacity: 0.8;
}
.comment-head .date, .reply-head .date {
  margin-left: 10px;
}

.comment-body {
  margin-left: 40px;
}
.comment-body p {
  margin-bottom: 10px !important;
  max-width: 500px;
  font-weight: 400;
  margin-top: 0 !important;
}

.comment-button button {
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  margin-bottom: 1.2rem;
}
.comment-button button i {
  color: #3E65A6;
  margin-right: 5px;
}
.comment-button button:nth-child(2) {
  margin-left: 20px;
}
.comment-button button:focus {
  outline: none;
}
.comment-button button:hover {
  opacity: 0.8;
}

.comment-button-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.comment-button-bottom .back {
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.comment-button-bottom .back i {
  margin-right: 10px;
}
.comment-button-bottom .back:focus {
  outline: none;
}
.comment-button-bottom .back:hover {
  opacity: 0.8;
}

/* Submit Anonymous */
.span_pseudo, .anonymously_cb span:before, .anonymously_cb span:after {
  content: "";
  display: inline-block;
  background: #fff;
  width: 0;
  height: 0.2rem;
  position: absolute;
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.anonymously_cb {
  position: relative;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.anonymously_cb input:checked ~ span {
  background: #3E65A6;
  border-color: #3E65A6;
}

.anonymously_cb input:checked ~ span::before {
  width: 1rem;
  height: 0.15rem;
  -webkit-transition: width 0.1s;
  -o-transition: width 0.1s;
  transition: width 0.1s;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.anonymously_cb input:checked ~ span::after {
  width: 0.4rem;
  height: 0.15rem;
  -webkit-transition: width 0.1s;
  -o-transition: width 0.1s;
  transition: width 0.1s;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.anonymously_cb input:disabled ~ span {
  background: #ececec;
  border-color: #414141;
}

.anonymously_cb input:disabled ~ label {
  color: #dcdcdc;
}

.anonymously_cb input:disabled ~ label:hover {
  cursor: default;
}

.anonymously_cb label {
  padding-left: 0.5rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0;
}

.anonymously_cb span {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #414141;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 1;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.anonymously_cb span::before {
  -webkit-transform: rotate(-55deg);
  -ms-transform: rotate(-55deg);
  transform: rotate(-55deg);
  top: 1rem;
  left: 0.37rem;
}

.anonymously_cb span::after {
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
  bottom: 0.35rem;
  left: 0.2rem;
}

.default-comment a.report-abuse {
  font-family: Montserrat, sans-serif;
}
.default-comment span.timestamp {
  font-size: 0.8rem;
}

#comments-form label[for=comment] {
  font-family: Montserrat, sans-serif;
  margin-bottom: 15px;
}

.default-comment a.report-abuse {
  font-family: Montserrat, sans-serif;
}
.default-comment span.timestamp {
  font-size: 0.8rem;
}

#comments-form label[for=comment] {
  font-family: Montserrat, sans-serif;
  margin-bottom: 15px;
}

.btn-story {
  box-shadow: none;
  background-color: #fff !important;
  color: #146094 !important;
  font-size: 1.3rem;
}

/*
  Individual card item
 */
.learn-card {
  color: #fff !important;
  background-color: #3b783e !important;
}

.event-card {
  color: #fff !important;
  background-color: #b24748 !important;
}

/***************************************************
    DETAIL PAGE
***************************************************/
/***************************************************
    LIST PAGE
***************************************************/
/*
  Comment
 */
.timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.timeline ul, .timeline li {
  list-style: none;
  padding: 0;
}
.timeline .wrapper {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 15px;
}
.timeline h1 {
  font-size: 1.1rem;
  font-family: sans-serif;
  border-bottom: 1px solid #000;
}
.timeline .sessions {
  margin-top: 1.5rem;
  border-radius: 12px;
  position: relative;
}
.timeline li {
  padding-bottom: 1.5rem;
  border-left: 1px solid #6c757d;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  transition: all 0.3s ease;
}
.timeline li:last-child {
  border: 0px;
  padding-bottom: 0;
}
.timeline li.active:before {
  content: "";
  width: 15px;
  height: 15px;
  background: white;
  border: 1px solid #6c757d;
  box-shadow: 3px 3px 0 #6c757d;
  border-radius: 50%;
  position: absolute;
  left: -8px;
  top: 10px;
}
.timeline li.complete:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #000;
  border: 1px solid #000;
  border-radius: 50%;
  position: absolute;
  left: -11px;
  top: 10px;
}
.timeline li.complete:after {
  content: "✓";
  position: absolute;
  left: -9px;
  top: 2px;
  color: #adb5bd;
  font-size: 30px;
  font-weight: 700;
}
.timeline .time {
  color: #2a2839;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
@media screen and (min-width: 601px) {
  .timeline .time {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  .timeline .time {
    margin-bottom: 0.3rem;
    font-size: 0.85rem;
  }
}
.timeline small {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.timeline p {
  color: #4f4f4f;
  font-family: sans-serif;
  line-height: 1.4;
  margin-top: 0.4rem;
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  .timeline p {
    font-size: 16px;
  }
}

.moduletable .video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.moduletable .video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}
.moduletable .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.top-intro .p-button {
  padding: 0.8rem 3rem !important;
  border-radius: 45px;
  font-size: 1.1rem;
  line-height: 1.75;
  letter-spacing: 0.225px;
}
.top-intro .intro-section {
  background: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1.935rem 0;
}
.top-intro .intro-section h2, .top-intro .intro-section h3 {
  margin-top: 0;
}
.top-intro .intro-section h1 {
  margin: 0.8rem 0 1.8rem;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 1rem;
}
@media screen and (max-width: 600px) {
  .top-intro .intro-section iframe {
    width: 100%;
    height: 315px;
  }
}
.top-intro .intro-section img {
  max-width: 100%;
  height: auto;
}

.consultation h2 {
  margin-bottom: 1.8rem;
}
@media screen and (min-width: 769px) {
  .consultation p {
    padding-left: 1.8rem;
  }
}

@media screen and (min-width: 769px) {
  .resources-container {
    display: flex;
    flex-wrap: wrap;
  }
}
.resources-container table.w-auto {
  width: auto;
}
.resources-container table.files-table {
  float: none;
}
.resources-container table thead > tr > th {
  border: 0;
  width: 20%;
  cursor: pointer;
}
.resources-container table thead > tr > th:first-child {
  width: 80%;
}
.resources-container table thead > tr > th .glyphicon {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.resources-container table tbody > tr:first-child td {
  border: 0;
}
.resources-container table tbody > tr > td {
  vertical-align: middle;
}
.resources-container table tbody > tr > td a {
  font-size: 12px;
}
.resources-container table tbody.open {
  display: table-row-group;
}
.resources-container table tbody.closed {
  display: none;
}

.general-resources.logged-in {
  margin-top: 2.3rem;
}
.general-resources .wrapper {
  padding: 1.5rem 0;
  border: 1px solid #ced4da !important;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px;
}
.general-resources .wrapper h3 {
  margin-top: 0;
}
.general-resources .wrapper ol {
  padding: 0 1.8rem;
  margin-bottom: 0;
}
.general-resources .wrapper ol > li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #000;
  font-family: sans-serif;
  line-height: 1.4;
  font-size: 16px;
}
.general-resources .wrapper ol > li:last-child {
  border-bottom: 0;
}

.widgets {
  padding-top: calc(1.5rem + 31.675px);
}

.widget:not(:first-child) {
  margin-top: 1rem;
}
.widget h3 {
  margin-top: 0;
}
.widget .wrapper {
  padding: 2rem;
  border-radius: 15px;
}
.widget .wrapper .consultation-resources ul {
  padding: 0 1.8rem;
  margin-bottom: 0;
}
.widget .wrapper .consultation-resources ul > li > div.document {
  padding: 0.5rem 0;
  border-bottom: 1px solid #000;
  font-family: sans-serif;
  line-height: 1.4;
  font-size: 16px;
}
.widget .wrapper .consultation-resources ul > li:last-child > div.document {
  border-bottom: 0;
}
.widget .wrapper .gallery-container > ol > li {
  position: relative;
}
.widget .wrapper .gallery-container > ol > li .img-caption {
  position: absolute;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 0.775rem;
  transition: all 0.3s ease-in;
  display: none;
}
.widget .wrapper .gallery-container > ol > li:hover > .img-caption {
  display: inline-block;
}
.widget .wrapper {
  border: 1px solid #ced4da !important;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px;
}
.widget:nth-child(2) {
  padding-top: 0.25rem;
  margin-bottom: 3rem;
  margin-top: 2.5rem;
}
.widget.widget-type-survey .wrapper {
  padding: 0.5rem;
}

.hide-profile {
  display: none;
}

/*
  Individual widget item
 */
.popover {
  margin: 0;
  padding: 0;
  word-wrap: inherit;
}
.popover .popover-title {
  display: none;
}
.popover .popover-content {
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px;
  padding: 0 !important;
}
.popover .popover-content .top-bar {
  width: 100%;
  height: 64px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  background: transparent linear-gradient(96deg, #00A566 0%, #5C97BF 100%) no-repeat padding-box !important;
  left: 0;
}
.popover .popover-content .img-col .img-pad {
  padding-left: 1rem;
  display: inline;
}
.popover .popover-content .img-col img {
  height: 80px;
  width: 80px;
  z-index: 1;
  position: relative;
}
.popover .popover-content .img-col .img-background {
  position: absolute;
  height: 76px;
  width: 76px;
  background-color: white;
  border-radius: 38px;
  top: 2px;
  right: 17px;
}
.popover .popover-content .profile-name {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  padding-left: 0.8rem;
  text-transform: capitalize;
  word-wrap: break-word;
}
.popover .popover-content p {
  word-wrap: break-word;
  transform: translateX(-20px);
}
.popover .popover-content .subtext {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.popover .popover-content .territory > ul {
  list-style: none;
  text-align: left;
  color: #3292D8;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  padding: 5px;
  margin-top: 0.8rem;
}
.popover .popover-content .territory > ul:first-of-type {
  border-top: 1px solid #ccc;
}
.popover .popover-content .territory > ul > li {
  padding: 8px;
  font-size: 12px !important;
}
.popover .popover-content .territory > ul > li > * {
  font-size: 12px !important;
}

.widget-type-survey .embed-survey iframe .question-text * {
  font-size: 19.2px;
}

.view-flow.layout-survey iframe.iframe-resizer {
  min-height: 500px;
}

.consultation-survey-wrapper {
  background-color: transparent;
}
.consultation-survey-wrapper.embed-survey iframe {
  min-height: 400px !important;
}
.consultation-survey-wrapper.embed-survey iframe .question-text * {
  font-size: 19.2px;
}
.document-widget form .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.document-widget form .custom-file-label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
}
.document-widget form .custom-file-input {
  border-radius: 0.25rem;
  height: 2.5rem;
  margin: 0;
  width: 100%;
  z-index: 2;
}
.document-widget form .custom-file-input:hover, .document-widget form .custom-file-input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
}
.document-widget form .file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  user-select: none;
}
.document-widget form .file-custom.en:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: "Browse";
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: 0.075rem solid #ddd;
  border-radius: 0 0.25rem 0.25rem 0;
}
.document-widget form .file-custom.fr:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: "Parcourir";
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: 0.075rem solid #ddd;
  border-radius: 0 0.25rem 0.25rem 0;
}

/*.discussion-topic-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px -15px !important;
}*/
.gallery-container .carousel-inner img {
  max-height: 500px;
  margin: 0 auto;
}

.gallery-container .carousel-indicators li a > div {
  width: 150px;
  border: 2px solid #26374a;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.gallery-container .carousel-indicators li p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*default_widget_gallery.php*/
.sidebar-images {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: top center;
}

/*default_widget_dialoguesponsore.php*/
.list .list-inline {
  display: none;
  margin-bottom: 25px;
  align-items: flex-start;
}

.list .list-inline a {
  text-decoration: none;
}

.list .list-inline img {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  margin-right: 20px;
}

.list-inline .info span {
  display: block;
  text-align: left;
  line-height: 1.4;
}

.list {
  margin: 30px auto 0;
  width: 100%;
  box-sizing: border-box;
}

.list-element {
  width: 100%;
  margin: 25px 0px;
  font-family: arial;
  border-radius: 50px;
  padding: 10px 0px;
  color: #2196f3;
  font-size: 20px;
  text-align: center;
  box-shadow: 0px 0px 6px 0px #848484;
  display: none;
}

#loadmore {
  margin: 20px auto 0px;
  display: table;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  color: #2196f3;
  border-radius: 4px;
  background-color: transparent;
  text-decoration: underline;
}

.list .list-inline:nth-child(1) {
  display: flex;
}

.list .list-inline:nth-child(2) {
  display: flex;
}

.list .list-inline:last-child {
  margin-bottom: 0px;
}

.list .list-inline:nth-child(3) {
  display: flex;
}

.list .list-inline:nth-child(4) {
  display: flex;
}

.list-inline .info {
  width: calc(100% - 80px);
  text-align: left;
  /*word-break: break-all;*/
}

/* commenting out - breaking other widgets */
/*.widget-description p {
    word-break: break-all;
}*/
/*default.php*/
.consultation-desc img {
  max-width: 100% !important;
  height: auto !important;
}

/*default_widget_socialmedia.php*/
.social-icons {
  display: flex;
}

.social-icons a {
  padding: 0 10px 0 0;
}

.social-icons a img {
  width: 47px;
  box-shadow: 0px 0px 3px rgb(0, 0, 15);
  border-radius: 45px;
}

.social-icons a:last-child {
  padding-right: 0;
}

.widget .wrapper .consultation-resources ul > li > div.document {
  border-bottom: none !important;
  border-top: 1px solid #000;
}

.widget .wrapper .consultation-resources ul > li:first-child > div.document {
  border-bottom: none;
  border-top: none;
}

.slide-dots {
  width: 100%;
  height: 120px;
  background-size: cover;
  background-position: top center;
}

.slide-dot-name {
  margin: 5px 0px 0px;
  font-weight: bold;
  font-size: 17px;
}

#galleryslider {
  margin: 0;
}

.slide-big-image {
  width: 100%;
  height: 700px;
  background-size: cover;
  background-position: top center;
}

@media (max-width: 767px) {
  .slide-big-image {
    height: 190px;
  }
  .slide-dots {
    background-size: contain;
    height: 50px;
  }
}
.discussion-topic-cards-container {
  /*display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;*/
  margin: 30px -15px;
}
.discussion-topic-cards-container .topic-card-col.bg-image {
  color: #fff;
}
.discussion-topic-cards-container .topic-card-col.bg-image .btn-primary {
  background-color: #fff !important;
  color: #337ab7 !important;
}
.discussion-topic-cards-container .topic-card-col.bg-image .grey-box {
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.discussion-topic-cards-container .topic-card-col .grey-box {
  padding: 0;
  background-color: #f5f5f5;
  transition: all 0.3s ease;
  height: 100%;
  background-size: cover;
}
.discussion-topic-cards-container .topic-card-col .grey-box .theme-body {
  padding: 0;
  background-color: transparent;
  position: relative;
  min-height: 300px;
}
.discussion-topic-cards-container .topic-card-col .grey-box .theme-body h2 {
  left: 0;
  right: 0;
  display: table;
  width: 100%;
  position: absolute;
  padding: 0.5rem 1.5rem;
  margin-top: 1.5rem;
}
.discussion-topic-cards-container .topic-card-col .grey-box .theme-body a {
  border-radius: 45px;
  font-weight: 600;
  font-size: 13px;
  padding: 0.5rem 1.8rem;
  color: #000;
  background-color: #fff;
}
.discussion-topic-cards-container .topic-card-col .grey-box .theme-body .sub {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: 34%;
  left: 0;
  right: 0;
  display: table;
  width: 100%;
  padding: 0.5rem 1.5rem;
}
.discussion-topic-cards-container .topic-card-col .grey-box .theme-body .learn-more {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: table;
  width: 100%;
  min-height: 40px;
}

aside.coming-soon {
  margin-top: 1.9rem;
}

.p-button {
  padding: 0.8rem 2.5rem !important;
  line-height: 1.75;
  letter-spacing: 0.225px;
}
.p-button-border {
  border-radius: 45px;
}
@media screen and (min-width: 769px) {
  .p-button {
    width: 100%;
  }
}

.align-text-top {
  vertical-align: text-top;
}

.inline-share {
  flex: none;
  order: 0;
  align-self: center;
  display: flex;
  margin: 0;
  padding: 0;
}
.inline-share .card-footer__icon {
  height: 1.8rem;
  font-size: 1.22rem !important;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
}

.project .intro-section {
  background: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1.935rem 0;
  margin-top: 1.2rem;
}
.project .intro-section h1 {
  margin-top: 0;
  margin-bottom: 1rem;
}
.project .intro-section .consultation-desc ul, .project .intro-section .consultation-desc ol {
  overflow: hidden;
}

.feature-outline {
  padding: 0.8rem;
  border: 2px solid #ced4da;
}
.feature-outline-flow {
  padding: 0;
  border: 0;
}
.feature-outline:not(:first-child) {
  margin-bottom: 3rem;
}
.feature-outline .bg-border {
  border: 2px solid #ced4da;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.feature-outline details, .feature-outline summary {
  border: 0 !important;
}
@media screen and (min-width: 769px) {
  .feature-outline .collab-title {
    font-size: 32px;
  }
}
@media screen and (max-width: 600px) {
  .feature-outline .collab-title {
    font-size: 28px;
  }
}
.feature-outline .feature-description {
  margin-bottom: 1.2rem;
}
.feature-outline .feature-description p {
  padding: 0 1.2rem;
}
.feature-outline .feature-container {
  min-height: 260px;
}

/*
#program-home {
  h2.h1 {
    text-transform: uppercase;
  }

}*/
.blue-border {
  border-bottom: 1px solid #ced4da;
  padding-bottom: 1rem;
  margin-bottom: 1.8rem;
}

.book-container .video-container .play-btn {
  position: absolute;
  top: 27%;
  left: 35%;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}

.end-message .consultation {
  height: 100vh;
}

.gallery-card {
  background: #FFF;
  box-shadow: 0px 100px 80px rgba(28, 32, 37, 0.05), 0px 12.5216px 10.0172px rgba(28, 32, 37, 0.025);
  border-radius: 20px;
}
.gallery-card .gallery-img {
  border-radius: 20px;
  overflow: hidden;
}
.gallery-card .gallery-img img {
  object-fit: cover;
}
.gallery-card .gallery-img:before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(28, 32, 37, 0) 53.12%, #1c2025 100%);
  width: 100%;
  right: 0;
  height: 100%;
  top: 0;
}
.gallery-card .gallery-detail {
  right: 0;
  bottom: 0;
  border-radius: 20px;
}

.widget-media-thumbnail {
  object-fit: cover;
  height: 113px;
  width: 113px;
}

td, th {
  padding: unset;
}

html {
  font-size: 1rem;
}