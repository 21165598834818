.moduletable {
  .video-container {
    overflow: hidden;
    position: relative;
    width:100%;
    &::after {
      padding-top: 56.25%;
      display: block;
      content: '';
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}


.top-intro{
  .p-button{
    padding: .8rem 3rem!important;
    border-radius: 45px;
    font-size: 1.1rem;
    line-height: 1.75;
    letter-spacing: .225px;
  }
  .intro-section{
    background: #fff;
    //border: 2px solid #007faa!important;
    box-sizing: border-box;
    //box-shadow: 0 0 10px rgba(0,0,0,.25)!important;
    border-radius: 5px;
    padding: 1.935rem 0;
    h2, h3{
      margin-top: 0;
    }
    h1{
      margin: .8rem 0 1.8rem;
      border-bottom: 1px solid $border-color;
      padding-bottom: 1rem;
    }
    @include mobile-only{
      iframe{
        width: 100%;
        height: 315px;
      }
    }

    img{
      max-width: 100%;
      height: auto;
    }
  }

}
