.pv-profile {
  box-shadow: 5px 5px 25px rgba(203, 198, 195, .5);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 5px;
  transition: .3s;
  margin-top: 1rem;

  .pv-profile-header {
    .h6 {
      font-size: 1rem;
    }

    color: #fff;
    background-image: linear-gradient(90deg, #d46a43, #c0392b);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

}

#show_hide_password {
  @media (max-width: $screen-xs-max) {
    display: table;
  }
}


#my-contributions {
  box-shadow: 5px 5px 25px rgba(203, 198, 195, .5);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 5px;
  transition: .3s;
  margin-top: 1rem;

  .my-contributions-items {
  }

  .dashboard-sub-heading {
    color: #fff;
    background-image: linear-gradient(90deg, #d46a43, #c0392b);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 1rem;
    font-size: 1rem;
    margin-top: 0;
    font-weight: 400;

  }
}

#member-registration {

}

// Common form
.pv-profile,
#member-registration {
  input[type="checkbox"].required,
  .required {
    &:before {
      content: '';
    }

    .star {

    }
  }

  .checkbox input[type="checkbox"] {
    position: static;
    margin-left: 0;
  }

  .controls fieldset {
    padding-top: 0;
  }

  @media screen and (max-width: 768px) {
    //padding: 1rem;
    input:not([type="checkbox"]) {
      width: 100%
    }

    .form-group {
      .col-xs-12 {
        padding-left: 0;
        padding-right: 0;
      }

      label.required:before {
        margin-left: 0;
      }
    }
  }
}

.registration {
  .border-bottom {
    border-bottom: 1px solid #dee2e6;
  }

}