// Generic star ratings
.ui-star-ratings {
  display: flex;
  flex-direction: row;
  padding: 16px;
  position: relative;

  .star {
    flex: none;
    order: 1;
    align-self: flex-start;
    margin: 0 4px;
    transition: all .2s ease-in-out;

    > a {
      text-decoration: none;

      &:focus {
        outline-offset: 3px;
      }
    }

    > a > span.pv-icon:not(on):before {
      content: "\e915";
      color: $black;
    }

    &:hover,
    &.hover {
      > a > span.pv-icon:before {
        content: "\e914";
        color: #007FAA;
      }
    }

    &.on {
      > a > span.pv-icon:before {
        content: "\e914";
        color: $black;
      }
    }
  }
}
