/*
  Individual card item
 */

// Askus question/answer
.askus {
  border: 1px solid black;
  border-radius: 5px;
  min-height: 190px;

  .btn-primary {
    background-color: $askus-color !important;
    border-color: $askus-color !important;
  }

  header,
  .askus-category,
  .askus-content {
    padding: 1rem 1rem 0;
    @include media-breakpoint-only(lg) {
      padding: 0.65rem 0.65rem 0;
    }

  }

  header {
    background: $askus-color;
    color: $white;
    display: flex;
    padding-bottom: 1rem;
    align-items: center;

    .fa-question {
      //align-self: center;
      margin: 1px 1px 0 0;
      font-size: 150%;
    }

    .askus-title {
      color: $askus-title-font-color;
      flex-grow: 3;
      font-size: 18px !important;
      margin: 0 1px 0 0;
      padding: 0 0.5rem;

    }

    .score {
      font-size: 95%;
      margin-top: -5px;
      padding-left: 8px;
      text-align: center;

      &-label {
        font-weight: 600;
      }

      &-value {
        text-align: center;
      }
    }


  }

  .askus-category {
    a{
      font-weight: 700;
      color: $askus-color;
      text-decoration: none;
    }
  }

  .askus-side {
    border-bottom: 1px solid $grey;
    display: flex;
    justify-content: space-evenly;
    margin: 1rem 0;
    width: 100%;

    .col {
      flex-grow: 1;
      padding: 0 0;
      text-align: center;
    }

    .col-like{
      padding: 7px 0;
    }

    .col-like,
    .btn {
      color: $black;
      font-size: 80%;
      font-weight: 700;
      width: 100%;

      .glyphicon {

      }
    }

    .icon-18 {
      font-size: 1.22rem !important;
      vertical-align: middle;
      padding-left: 5px;
      padding-right: 5px;
      color: #000;
    }


  }

  .askus-ratings {
    display: none;
  }

  .askus-rate {
    .rating {
      margin-left: 0;
    }
  }

  .card-content {

    p:first-child {
      margin-bottom: 0;
    }

  }

  // Tabs
  // Fix for wrapping tabs
  .askus-tabs {
    li a {
      @include media-breakpoint-only(lg) {
        padding: 0.25rem .65rem;
        font-size: 85%;

      }

    }
  }

  .askus-tabs.tabs-3 li:first-child a {
    height: 60px;

    @include media-breakpoint-down(sm) {
      height: initial;
    }

    @include media-breakpoint-only(lg) {
        height: 48px;
    }
  }

  .askus-tabs.nav-tabs{
    .active {
      .nav-link {
        color: $white !important;
      }
    }

    .nav-link {
      color: $black !important;
    }
  }

  .nav-tabs {
    .active {
      .nav-link {
        background: $askus-color;
        color: $white;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    .nav-link {
      color: $white !important;
    }
  }

  .tab-content {
    padding: 1rem;

    .comment-name span.h5 {
      font-size: 24px !important;
    }

  }

  // Comments
  .askus-comments {
    .add-comment-area {
      form {
        .comment-formgroup {
          width: 100%;
        }

        input[type="text"] {
          border-radius: $form-field-border-radius;
          width: 100%;
        }

        .comment-anonymous label {
          font-weight: normal;
        }
      }
    }



    textarea {
      min-height: 100px;
      width: 100%;
      border-radius: $border-radius-base;
    }
  }

  footer {
    color: inherit;
    background-color: inherit;
    font: inherit;
    font-weight: inherit;
    text-transform: inherit;

  }
}

// End .askus