#wb-srch {
  input[type="search"] {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .btn-primary {
    background-image: none;
    background-color: #204a83 !important;
    border-color: #204a83 !important;
  }
}

#pv-wb-srch-sub {
  background-color: #26374a;
}

#search-button {
}

#search-button.hide {
  display: none !important;

  span {
    display: none;
  }

  :before {
    display: none;
  }
}

#wb-srch-mobile {

  margin-left: 1rem;

  .form-group {
    height: 86%;
    margin-top: 2%;
    margin-bottom: 4%;
  }

  input[type="search"] {
    display: none;
    max-width: 160px;
  }


  button {
    display: none;

    &:nth-child(n + 1) {
      border-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }


  @media (max-width: $screen-md-max) {

    //button {
    //  display: none;
    //}
  }
}


#wb-srch input[type=search] {
    padding-left: 6px;
}