.project .widget {

}
.widgets {
  // must look at the h2 height of an activity
  padding-top: calc(1.5rem +  31.675px) ;
}

.widget {
  &:not(:first-child) {
    margin-top: 1rem;
  }

  h3 {
    margin-top: 0;
  }

  .wrapper {
    //background: #f8f9fa;
    padding: 2rem;
    border-radius: 15px;

    .consultation-resources {
      ul {
        padding: 0 1.8rem;
        margin-bottom: 0;

        > li > div.document {
          padding: .5rem 0;
          border-bottom: 1px solid #000;
          font-family: sans-serif;
          line-height: 1.4;
          font-size: 16px;

        }

        > li {
          &:last-child > div.document {
            border-bottom: 0;
          }
        }
      }
    }

    //Image gallery
    .gallery-container {
      > ol > li {
        position: relative;
        .img-caption {
          position: absolute;
          bottom: 0;
          color: #fff;
          left: 0;
          padding: 0.5rem;
          background-color: rgba(0, 0, 0, 0.5);
          font-weight: 600;
          font-size: .775rem;
          transition: all .3s ease-in;
          display: none;
        }
        &:hover{
           > .img-caption{
             display: inline-block;
           }
        }
      }

    }

  }

  .wrapper {
    border: 1px solid $border-color !important;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 0, 0, .25) !important;
    border-radius: 5px;
  }

  &:nth-child(2){
    padding-top: .25rem;
    margin-bottom: 3rem;
    margin-top: 2.5rem;
  }

  //Survey Widget\
  &.widget-type-survey{
    .wrapper {
      padding: .5rem;
    }
  }
}

.hide-profile{
  display: none;
}

/*
  Individual widget item
 */

.popover{

  margin: 0;
  padding: 0;
  word-wrap: inherit;
  .popover-title { display: none; }

  .popover-content{
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 0, 0, .25) !important;
    border-radius: 5px;
    padding: 0 !important;
    .top-bar {
      width: 100%;
      height: 64px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      position: absolute;
      background: transparent linear-gradient(96deg, #00A566 0%, #5C97BF 100%) no-repeat padding-box !important;
      left: 0;
    }
    .img-col {
      .img-pad {
        padding-left:1rem;
        display:inline;
      }
      img {
        height:80px;
        width:80px;
        z-index:1;
        position:relative;
      }
      .img-background {
        position:absolute;
        height:76px;
        width:76px;
        background-color:white;
        border-radius:38px;
        top:2px;
        right:17px;
      }
    }
    .profile-name{
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      padding-left: .8rem;
      text-transform: capitalize;
      word-wrap: break-word;
    }
    p{
      word-wrap: break-word;
      transform: translateX(-20px);
    }

    .subtext{
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
    }

    .territory{
      > ul{
        list-style: none;
        text-align: left;
        color: $primary;
        font-family: "Lato", sans-serif; font-size: 13px; font-weight: bold;
        padding: 5px;
        margin-top: .8rem;
        &:first-of-type{
          border-top: 1px solid #ccc;
        }
        > li{
          padding: 8px;
          font-size: 12px !important;
          > *{
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
