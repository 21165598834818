/*
  Individual card item
 */

.idea-card {

  .card-header {
    background: $pv-collab-theme no-repeat padding-box !important;
    color: #fff !important;

    .idea-title{
      font-size: 14px;
      font-weight: bold;
    }
  }

  .tags ul li {
    display: block;
  }

  .stats-section,
  .stat-box {
    text-align: left;
    font: normal normal 0.75rem/14px $font-family-base;
    letter-spacing: 0;
    opacity: 1;
    margin: .5rem 0 .5rem !important;

    > div.col-auto {
      margin-bottom: 1rem !important;
    }

    .dot {
      background: #4d4d4d 0 0 no-repeat padding-box;
      width: 4px;
      height: 4px;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 4px;
    }


  }

  .idea-comment-like-form {
    display: inline-block;
  }

  .comment-section .catalyst > .comment-inner:first-child > .text {
    border: 1px solid #666666 !important;
    border-radius: $border-radius-base;
  }

  .idea-actions {
    > a {
      display: block;
      margin-left: 5px;
      font-size: 14px;
    }
  }
  .idea-read-more{
    font-size: 12px;
    margin-bottom: .5rem;
  }

  .idea-ratings-section {
    background: #FFFFFF;
    border-radius: 5px;
    bottom: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    position: absolute;
    left: 0;
  }

}

