.btn-tag {
  background: $white;
  border-radius: .2rem;
  border-color: rgb(112, 112, 112);
  font-size: .875rem;
  line-height: 1.5;
  padding: .375rem .75rem;
  &.active{
    color: $white;
    background: $dark;
  }
}