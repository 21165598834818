//Donation Slider

.school-icons {
  display: none;
}

.interactive-slider {
  width: 80%;
  margin: 0 auto;
}

#slider {
  width: 100%;
  background-color: $grey !important;
  height: 7px;
  margin: 3em 0;
  position: relative;
  background: none;
  border: none;

  .ui-slider-handle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: none;
    background-color: $primary;
    border: none;
    top: -7px;
    position: absolute;
    transition: left 0.1s ease-out;
    &:hover { cursor: pointer; }
  }
}

@each $icon, $size in $icon-sizes {
  $scale: $size/100;
  @keyframes bounce-grow-#{$icon} {
    0% {
      transform: scale($scale,$scale);
    }

    35% {
      transform: scale($scale+0.35, $scale+0.35);
    }
    50% {
      transform: scale($scale+0.15, $scale+0.15);
    }
    70% {
      transform: scale($scale+0.2, $scale+0.2);
    }
    100% {
      transform: scale($scale+0.15, $scale+0.15);
    }
  }
}

$icon-size: 100px;

.icon-container {
  //background-color: $primary;
  color: #fff;
  width: $icon-size;
  height: $icon-size;
  margin: 0.2em auto 0.2em;
  display: block;
  border-radius: 50%;
  line-height: $icon-size;
  text-align: center;
  font-size: $icon-size*0.8;
  transform: scale(0.75, 0.75);
  transition: transform 0.3s ease-in-out;
  transform-origin: center bottom;

  img {
    position: relative;
    top: 0.25rem;
    display: none;
    width: 80%;
    margin: 0.05rem auto;

    &.active {
      display: block;
    }
  }


  @each $icon, $size in $icon-sizes {
    $scale: $size/100;
    &[data-size="#{$icon}"] {
      animation: bounce-grow-#{$icon} 0.5s ease-out alternate;
      transform: scale($scale+0.15, $scale+0.15);
    }
  }

}

.caption {
  display: none;
  font-size: 1.2em;
  //font-style: italic;
  color: #666;
  margin: 0 auto;
  line-height: 1.4em;
  text-align: center;

  .amount {
    color: $primary;
    font-size: 1.3em;
    font-style: normal;
  }
}

.presets {
  text-align: center;
  //width: max-content;
  margin-left: auto;
  margin-right: auto;
  button {
    background-color: $grey;
    color: rgba(0,0,0,1);
    font-size: 1.3em;
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 1em;
    transition: all 0.3s ease-in-out;
    border: 1px solid $grey;
    &.active, &.active:hover {
      background-color: $primary;
      color: #fff;
      border-color: $primary;
    }
    &:hover {
      cursor: pointer;
      background-color: transparent;
      color: $primary;
      border-color: $primary;
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 200;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1,1);
  }

  100% {
    transform: scale(1.10,1.10);
  }
}

#effect {
  position: relative;
  &[data-amount="1000"]  {
    #confetti  {
      opacity: 1;
      visibility: visible;
      line {
        animation: dash 1.8s ease-out;
      }
    }
    .icon-container .shape-graduation-cap {
      animation: pulse 0.75s ease-out alternate infinite;
    }
  }
}

#confetti {
  position: absolute;
  top: -9.5em;
  left: calc(50% - 9.5em);
  opacity: 0;
  visibility: hidden;
  line {
    fill: none;
    stroke: $primary !important;
    stroke-width: 0.5em;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 100;
    stroke-dashoffset: 550;

  }
}

@media (max-width: 768px){
  .presets button{
    font-size: 13px;
  }
}