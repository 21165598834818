.component.window {
    body {
      //padding: 2.5rem;
    }

}

.white-popup-block {
  background: #FFF;
  padding: 20px 30px;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative;
}

.mfp-bg{
  opacity: .8;
}

.overlay-def header{
  background-color: #26374a;
}

.view-consultation, .view-stories,.view-story{
  .mfp-iframe-scaler{
    min-height: 700px;
  }
}
