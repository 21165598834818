#wb-sttl {
  @media (min-width: $screen-md-min) {
  }

  @media (max-width: $screen-sm-max){
  }

}

main {
  //@include make-row();
}

.gcweb-menu {
  margin: 0;
  border-top: 0;
}

.menu-bg-color {
  background-color: #444;
  color: #fff;
  margin: 0;
  padding: 10px 15px;
  .custom{
    @extend .container;
    @extend .px-lg-3;
  }

  * {
    margin: 0 auto;
    border: 0;
    padding: 0 !important;
    @media (max-width: $screen-sm-max) {
      font-size: 1.5rem;
    }
  }
}

#page-content {
  //padding-right: ($grid-gutter-width / 2);
  //padding-left: ($grid-gutter-width / 2);
  margin-right: 0;
  margin-left: 0;
  width: 100%;


  @media (min-width: $screen-xs-min){

  }

  @media (min-width: $screen-sm-min){
  }
  @media (min-width: $screen-md-min) {
  }
  @media (min-width: $screen-lg-min){
  }
}

#t3-main-content {
  padding-bottom: 15px;
}

#sidebar {

  @media (min-width: $screen-xs-min){
  }

  @media (max-width: $screen-sm-max){

  }

  @media (min-width: $screen-md-min){
  }

  @media (min-width: $screen-lg-min){
  }

}

#wb-bc li{
  padding-left: 0;
  padding-right: 0;
  vertical-align: middle;
  &:before{
    color: #333;
    content: "";
    font-family: "Glyphicons Halflings";
    font-size: .7em;
    top : 0;
  }
  &:first-child a{
    padding-left: 0; padding-right: 0;
  }
  &.active{ padding: 8px 0;}
}




/* Back to the top */
.to-top {
  position: fixed;
  bottom: 2rem;
  right: 0;
  background-color: black;
  opacity: 0.5;
  padding : 1.5rem 2rem;
  z-index: 1000;
  a span {
    color: white;
  }
}

.embed-survey {
  height: 100%;
}

.view-flow .embed-survey iframe{
  min-height : 750px;

}

#search-wrapper{
  .search{
    .form-control{
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
  }
}

footer#wb-info{
  .custom{
    @extend .container;
    padding-top: .4rem;
  }
}

table {
  tr {
    &.table_row_top_border td {
      border-top: 0;
    }

    &.table_row_left_border td {
      border-left: 0;
    }

    &.table_row_right_border td {
      border-right: 0;
    }

    &.table_row_bottom_border td {
      border-bottom: 0;
    }
    &.table_row_no_border td {
      border: 0;
    }
    &.table_row_side_border td {
      border-left: 0;
      border-right: 0;
    }
  }
}