@media only screen and (min-width: 1024px) {
  #my-profile-contributions-page{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}


#my-contributions {
  padding-bottom: 20px;

  > p {
    padding-left: 10px;
  }

  .contributions-content .db-challenges {
    width: 100%;
  }

  .header-info a {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
  }

  .header-info .ui-icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.cb_template .cb-page-header-title {
  margin-bottom: 0.9rem;
  font-weight: 600;
}