.h-32px {
  height: 32px;
}

.h-48px {
  height: 48px;
}

.h-64px {
  height: 64px;
}

.h-128px {
  height: 128px;
}

.h-256px {
  height: 256px;
}

.h-500px {
  height: 500px;
}

.h-700px {
  height: 700px;
}

@media (min-width: 767px) {
  .h-md-100{
    height: 100%;
  }
}



.w-128px {
  width: 128px;
}

.hover-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.2s !important;
  transition-property: all !important;
  transition-timing-function: ease-out;
}
.hover-float {
  &:hover, &:focus, &:active {
    transform: translateY(-2px);
  }
}
.hover-primary {
  &:hover, &:focus, &:active {
    color: $primary !important;
  }
}

.page-header h1 {
  font-style: normal;
  font-weight: 700;
}

.hide {
  display: none !important;
}

.d-inline-block{
  display: inline-block;
}

.bg-primary{
  background: $primary;
}

@include mobile-only{
  .text-sm-center{
    text-align: center;
  }
}

.font-smaller {
  font-size: smaller;
}

.font-larger {
  font-size: larger;
}
.font-normal {

}
