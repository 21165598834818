
.document-widget form {
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
  }

  .custom-file-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 2.5rem;

  }

  .custom-file-input {
    border-radius: 0.25rem;
    height: 2.5rem;
    margin: 0;
    //filter: alpha(opacity=0);
    //opacity: 0;
    width: 100%;
    z-index: 2;

    &:hover,
    &:focus {

      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 8%), 0 0 8px rgba(102, 175, 233, 60%);
    }
  }

  .file-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    border: 0.075rem solid #ddd;
    border-radius: 0.25rem;
    box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
    user-select: none;
  }

  .file-custom {
    &.en:before {
      position: absolute;
      top: -0.075rem;
      right: -0.075rem;
      bottom: -0.075rem;
      z-index: 6;
      display: block;
      content: "Browse";
      height: 2.5rem;
      padding: 0.5rem 1rem;
      line-height: 1.5;
      color: #555;
      background-color: #eee;
      border: 0.075rem solid #ddd;
      border-radius: 0 0.25rem 0.25rem 0;
    }

    &.fr:before {
      position: absolute;
      top: -0.075rem;
      right: -0.075rem;
      bottom: -0.075rem;
      z-index: 6;
      display: block;
      content: "Parcourir";
      height: 2.5rem;
      padding: 0.5rem 1rem;
      line-height: 1.5;
      color: #555;
      background-color: #eee;
      border: 0.075rem solid #ddd;
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }


}


