$sidebar-current-bg-color: #ccc;
$sidebar-current-font-color: $black;
$sidebar-hover-bg-color: lighten($sidebar-1-bg-color, 20);
$sidebar-hover-font-color: $black;
$sidebar-submenu-indent: 25px;

// White Icons
$icons: (
        "globe": "about_white1.png",
        "surveys": "round-checkbox-white.svg",
        "ideas": "noun_idea.svg",
        "stories": "menu_stories.svg",
        "feedback": "icon_feedback_white.svg"
);

// Black Icons
$icons-black: (
        "globe": "about_black1.png",
        "surveys": "round-checkbox-black.svg",
        "ideas": "noun_idea_blk.svg",
        "stories": "menu_stories_black.svg",
        "feedback": "icon_feedback_black.svg"
);

@mixin sibebar-icon($name, $url) {
  .icon-#{$name} {

    &:before {
      position: relative;
      content: url("#{$icon-path}#{$url}");
    }
  }
}
@mixin sidebar-icons($list: $icons){
  @each $name, $url in $list {
    @include sibebar-icon($name, $url);
  }
}

// Open sidebar with hover, non touch screen
.no-touch #sidebar {
  &:hover {
    width: 300px;

    h1, h2, h3, h4, h5, h6 {
      display: block;
      text-align: left;
    }

    .h4 {
      font-size: 18px !important;
    }

    .menu,
    ul.nav {
      li.current a {
        width: 96%;
      }

      li {

        a {
          text-align: left !important;

          @media (max-width: $screen-xs-max) {
            padding: 5px 7px;
            .fa {
              padding-top: 5px;
              padding-left: 0 !important;
            }
          }

          &:before {
            display: block;
            text-align: left;
            width: 24px;
          }

          .item-title {
            display: block !important;
            overflow: initial;
            text-overflow: initial;
            white-space: initial;
            width: 88%;
          }
        }

        &.parent {
          ul {
            li {
              a {
                padding-left: $sidebar-submenu-indent;
              }
            }
          }
        }
      }
    }

    .icon-ideas:before {
      position: relative;
      top: -4px;
      left: -5px;
    }

    // Icon positions
    .icon-globe:before {
      top: -5px;
      left: -5px;
    }

    //
    .icon-stories:before {
      top: 0;
      left: -5px;

    }
    .icon-surveys:before {
      top: -1px;
      left: -4px;
    }
    .icon-feedback:before {
      top: 0;
      left: -5px;
    }
  }

  // End Hover
}

.touch.xxsmallview #sidebar {
  height: 100%;
  font-size: 12px; // decreasing overall font size for menus
}


@media (max-width: $screen-sm-max) {
  .touch #sidebar.active {
    width: 30vw;
  }
  .touch #sidebar.active ~ #page-content {
    width: 70vw;
  }
}

@media (max-width: $screen-xs-min) {
  .touch #sidebar.active {
    width: 100vw;
    z-index: 1000;
  }
  .touch #sidebar.active ~ #page-content {
    overflow: hidden;
    width: 0vw;
    z-index: 0;
  }
  .touch #sidebar.active #deactive-sidebar{
    display: block !important;
  }

}


#sidebar {
  background: $sidebar-1-bg-color;
  height: 100%;
  overflow: hidden;
  min-width: 80px;
  position: relative;
  transition: 0.5s;
  width: 140px;

  @include sticky;


  h3, h3, .h3, .h4 {
    color: white
  }

  .moduletable {
    &:first-child {
      //border-bottom: 2px solid #fff;
      //margin-bottom: 2px;
      //padding-bottom: 5px;
    }

    &:nth-child(2) {
      //border-top: 2px solid #fff;
      //margin-top: 3px;
      //padding-top: 5px;
    }
    &:last-child {
      //margin-bottom: 0;
    }
    &.activities {
      nav ul.nav li {
        &:nth-child(3) {
          //border-bottom: 2px solid #fff;
        }

        &:nth-child(4) {
          //border-top: 2px solid #fff;
          //padding-top: 1rem;
          //padding-bottom: 1rem;
          //margin-top: 3px;
        }
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    padding-left: ($grid-gutter_width/4);
    padding-right: ($grid-gutter_width/4);
  }

  .drawer {
    height: 100%;
    width: 100%;
  }

  // Active mode
  &.active {
    width: 300px;

    h1, h2, h3, h4, h5, h6 {
      display: block;
      text-align: left;
    }

    .h4 {
      font-size: 18px !important;
    }



    .navbar {
      margin-bottom: 0;
    }
    .menu,
    ul.nav {
      li.current a {
        width: 96%;
      }

      li {

        &.parent {
          ul {
            a {
              padding-left: $sidebar-submenu-indent;
            }
          }
        }

        .parent a {
          text-align: left;
        }

        a {
          text-align: left !important;

          @media (max-width: $screen-xs-max) {
            padding: 5px 7px;
            .fa {
              padding-top: 5px;
              padding-left: 0 !important;
            }
          }

          &:before {
            display: block;
            text-align: left;
            width: 24px;
          }

          // Icon positions non-active
          &.icon-globe:before {
            position: relative;
            top: -9px;
            left: -13px;
          }

          &.icon-ideas:before {
            position: relative;
            top: -4px;
            left: -5px;
          }

         &.icon-stories:before {
            position: relative;
            top: 0;
            left: -5px;
          }

          &.icon-surveys:before {
            position: relative;
            top: -1px;
            left: -4px;
          }
          &.icon-feedback:before {
            position: relative;
            top: 0;
            left: -5px;
          }

          .item-title {
            display: block !important;

            overflow: initial;
            text-overflow: initial;
            white-space: initial;
            width: 88%;
          }
        }
        @media (max-width: $screen-xs-max) {
          &.current a{
            width: 98% !important;
          }
        }

      }

    }

  }// End Active mode


  .moduletable {
    position: relative;
    &.products{
      .nav>li>a{
      //  font-weight: 700;
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 90%;
    display:  none;
    text-align: center;
  }

  .menu,
  ul.nav {
    top: 0;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li a {
      &:active {
        background-color: transparent;
      }

      &:focus {
        background-color: transparent;
      }

      &:hover {
        background-color: $sidebar-hover-bg-color;
        background-image: url(#{$icon-path}right-tri-hover2.png);
        background-position: right center;
        background-repeat: no-repeat;
      }

    }

    li {
      position: relative;
      float: none !important;
      width: 100%;

      > a > .item-title {
        display: none;
      }

      // Select menu item
      &.current {

        background-image: url(#{$icon-path}right-tri-gray.png);
        background-position: right center;
        background-repeat: no-repeat;

        a {
          background-color: $sidebar-current-bg-color;
          background-image: none;
          color: $sidebar-current-font-color;
        }

        @include sidebar-icons($icons-black);

        &:hover {
          @include sidebar-icons();
        }
      }

      &.divider {
        &:first-child {
          margin-top: 0;
        }

        margin: 1rem 0;
        border-bottom: 2px solid $white;
        border-top: 2px solid $white;
        padding: 2px 0 !important;
        height: 2px;
        .separator {
          overflow: hidden;
          height: 10px;
        }
      }

      a {
        color: #fff;
        cursor: pointer;
        display: block;
        position: relative;
        display: flex;
        text-decoration: none;
        padding: 0.9375rem;

        &:focus,
        &:hover {
          .item-title {
            text-decoration: none;
          }

          .fa {
            text-decoration: none;
            padding-left: 5px;
          }
        }

        &.disabled {
          color: gray;
          font-weight: 100;
          background-color: initial !important;
          cursor: default;
          &:hover {
            background: none;
          }
        }

        .item-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 84%;
        }

        img {
          height: 20px;
          width: 16px;
        }

        .item-title {
        }

        .fa {
          padding-top: 3px;
          text-align: right;
          width: 15%;
        }
      }

      // Submenus
      &.parent {

        a .fa:before {
          content: "\f0d7";
        }

        a.collapsed {
          .fa:before {
            content: '\f0da';
          }
        }

        > a > .item-title {
          display: block;
        }

        ul {
          background-color: $sidebar-1-bg-color;
          border-radius: 0;
          padding-left: 0px;
          list-style: none;


          a {
            font-size: 90%;
            text-align: center;
            padding: 10px 12px;

            @media (max-width: $screen-xs-max) {
              padding: 5px 7px;
            }

            &:before {
              display: block;
            }

            .item-title {
              display: none;
            }

          }
        }

      }

      // End Submenu items
    }

    // End li parent item

  }

  @media (max-width: $screen-xs-max) {
  }

}

// Menu icons
.menu-icon {

  &:before {
    display: block;
    font: normal normal normal 110% FontAwesome;
    height: 24px;
    padding-top: 3px;
    text-rendering: auto;
    text-align: center;
    width: 100%;
  }
}

.icon-ideas:before {
}


.icon-resources:before {
  font-family: FontAwesome;
  content: "\f0f6";
}

.icon-surveys:before {
  content: "\f0ae";
}

.icon-questions:before {
  content: "\f128";
}


@each $name, $url in $icons {
  @include sibebar-icon($name, $url);
  .icon-#{$name} {
    &:before {
      width: 24px;
      height: 24px;
    }
  }
}


// Non active or mobile
.icon-ideas:before,
.icon-stories:before {
  position: center;
  width: 100%;
  margin-right: 2px;
}

.icon-surveys:before {
  position: center;
  width: 100%;
}

.icon-globe:before {
  position: center;
  width: 100%;
}
.icon-feedback:before {
  position: center;
  width: 100%;
}
@media (max-width: $screen-xs-max){
  #sidebar{
    .icon-globe:before{
      top: -7px;
    }
    .icon-surveys:before{
      top: -1px !important;
    }
    &.active {
      .icon-globe:before{
        top: 0;
        left: -8px !important;
        margin-right: 5px;
      }
    }
  }
}


// Correction for the image icons
#sidebar ul.nav li.current:hover {
  .icon-globe:before {
    content: url(#{$icon-path}about_black1.png);
  }
  .icon-stories:before {
    content: url(#{$icon-path}menu_stories_black.svg);
  }
  .icon-ideas:before{
    content: url(#{$icon-path}noun_idea_blk.svg);
  }
  .icon-surveys:before{
    content: url(#{$icon-path}round-checkbox-black.svg);
  }
}


