// Ask challenge
.mod-publivate-askus {
  color: $askus-mod-publivate-askus-font-color;

  label {
    color: $black
  }

  input[type="submit"] {
    background-color: $askus-color !important;
    border-color: $askus-color !important;
    min-width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .fa-question {
    font-size: 4rem;
    align-self: center;
    margin-right: 0px;

    @media (max-width: $screen-xs-max) {
      align-self: initial;
      font-size: 2rem;
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: center;
    background-color: $askus-header-bg;
    margin: 1.25rem;

    @include media-breakpoint-down(sm){
      //@media (max-width:767px) {
     display: block;
      //}
    }

    h1 {
      border-bottom: 0;
      .fas,
      .fa {
        margin-right: 15px
      }
    }

    .icon-container {
      margin-right: 3rem;
    }

    @media screen and (max-width: 600px) {
      ul {
        padding-left: 20px;
      }
    }
  }

  form.add-question {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin: 1.25rem;
    padding: 1.25rem;

    .prompt {
      margin-bottom: 15px;

      > * {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
      }
    }

    select {
      border-radius: $form-field-border-radius;
      padding-right: 5px;
      width: 100%;
    }

    input[type="text"] {
      width: 100%;
      border-radius: $form-field-border-radius;
    }

    .btn {
      min-width: 100%;
      height: 50px;
    }

    .pv-autocomplete {
      position: relative;

      .pvc_search_results {
        top: 0;
      }
    }
  }
}

.askus-challenge-content {
  ol, ul {
    margin-left: 1.5rem;
  }
}

.view-ideas.layout-askus {
  .main-content-top {
    padding-left: 0;
    padding-right: 0;
    margin-left: -($grid-gutter-width/2);
    margin-right: -($grid-gutter-width/2);

    .page-header {
      background-color: $askus-header-bg;
    }
  }
}

.mod-publivate-askus .card-body {
  padding: .9rem .9rem 0;
}

