@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";

@import "variables";

@import "bootstrap4/index";

@import "layout";

// Publivate generic layouts
@import "../../../layouts/publivate/scss/template";
$text-white: #FFF;
@import "../../mysaytoday/scss/pages/media"; // Image gallery
@import "fonts";
@import "client";

html {
  font-size: $font-size-base;
}