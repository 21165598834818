@import 'media';
@import 'timeline';
@import 'modules';
@import "resources";
@import "widget";
@import "flow";
@import "document";
@import "custom";

.discussion-topic-cards-container {
  /*display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;*/
  margin: 30px -15px;


  .topic-card-col {
    &.bg-image {
      //border: none;
      color: $white;

      .btn-primary {
        background-color: $white !important;
        color: $brand-primary !important;
      }

      .grey-box {
        border: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    //padding: 0 15px;
    .grey-box {
      padding: 0;
      background-color: #f5f5f5;
      //border: 8px solid #a8cfdd;
      transition: all .3s ease;
      height: 100%;
      background-size: cover;

      &:hover {
        //border: 8px solid #007faa;
      }

      .theme-body {
        padding: 0;
        background-color: transparent;
        position: relative;
        min-height: 300px;

        h2 {
          left: 0;
          right: 0;
          display: table;
          width: 100%;
          position: absolute;
          padding: .5rem 1.5rem;
          margin-top: 1.5rem;
          //min-height: 140px;
        }

        a {
          border-radius: 45px;
          font-weight: 600;
          font-size: 13px;
          padding: .5rem 1.8rem;
          color: #000;
          background-color: #fff;
        }

        .sub {
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          margin-top: 1rem;
          margin-bottom: 1rem;
          position: absolute;
          top: 34%;
          left: 0;
          right: 0;
          display: table;
          width: 100%;
          //min-height: 100px;
          padding: .5rem 1.5rem;
        }

        .learn-more {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: table;
          width: 100%;
          min-height: 40px;
        }
      }

    }
  }
}

aside {
  &.coming-soon {
    margin-top: 1.9rem;
  }
}

.p-button {
  padding: .8rem 2.5rem !important;
  line-height: 1.75;
  letter-spacing: .225px;

  &-border {
    border-radius: 45px;
  }

  @include tablet-and-up {
    width: 100%;
  }
}

.align-text-top {
  vertical-align: text-top;
}


.inline-share {
  flex: none;
  order: 0;
  align-self: center;
  display: flex;
  margin: 0;
  padding: 0;

  .card-footer__icon {
    height: 1.8rem;
    font-size: 1.22rem !important;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    color: #000;
  }
}

//Overview

.project {
  .intro-section {
    background: #fff;
    //border: 2px solid #007faa !important;
    box-sizing: border-box;
    //box-shadow: 0 0 10px rgba(0, 0, 0, .25) !important;
    border-radius: 5px;
    padding: 1.935rem 0;
    margin-top: 1.2rem;

    h1 {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .consultation-desc {
      ul, ol {
        overflow: hidden;
      }
    }
  }
}

//Application

.feature-outline {
  padding: 0.8rem;
  border: 2px solid $border-color;

  &-flow {
    padding: 0;
    border: 0;
  }

  &:not(:first-child) {
    margin-bottom: 3rem;
  }

  .bg-border {
    border: 2px solid $border-color;
    background-size: cover;
    background-position: center; // global
    background-repeat: no-repeat;
  }

  details, summary {
    border: 0 !important;
  }

  .collab-title {
    @include tablet-and-up {
      font-size: 32px;
    }
    @include mobile-only {
      font-size: 28px;
    }
  }

  .feature-description {
    p {
      padding: 0 1.2rem;
    }

    margin-bottom: 1.2rem;
  }

  .feature-container {
    min-height: 260px;
  }
}

/*
#program-home {
  h2.h1 {
    text-transform: uppercase;
  }

}*/

.blue-border {
  border-bottom: 1px solid $border-color;
  padding-bottom: 1rem;
  margin-bottom: 1.8rem;
}

.book-container {
  .video-container .play-btn {
    position: absolute;
    top: 27%;
    left: 35%;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
  }
}

.end-message {
  .consultation {
    height: 100vh;
  }
}
