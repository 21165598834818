.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
  transition: .3s;
  margin-bottom: 1.25rem;

  &:hover {
    box-shadow: 5px 5px 25px rgba(203, 198, 195, 0.5);
  }

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }

  &-stats, .stat-box {
    text-align: left;
    font: normal normal 0.75rem/14px $font-family-base;
    letter-spacing: 0;
    opacity: 1;
    margin: .5rem 0 .5rem !important;

    > &__value {
      margin-bottom: 1rem !important;
    }

    &__divider {
      background: #4d4d4d 0 0 no-repeat padding-box;
      width: 4px;
      height: 4px;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 4px;
    }

    &__link{
      text-decoration: none;
      font-weight: 700;
      &:hover{
        text-decoration: none;
      }
    }
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
  color: $card-color;

  &__text{
    font-size: 14px;
  }

}

.card-title {
  margin-bottom: $card-spacer-y;
  margin-top: $card-spacer-y;
}

.card-subtitle {
  margin-top: -$card-spacer-y / 2;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0); // Fix for spacing


  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }

  .pv-icon {
    font-size: 1.3rem;
    vertical-align: middle;
  }
}

.card-footer {
  $self: &;
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }

  &__btn {
    flex-grow: 1;
    text-align: center;
    padding-top: .5rem;
    padding-bottom: .6rem;
    text-decoration: none;

    &:hover {
      border-radius: $border-radius;
      background-color: #CCC;
      text-decoration: none !important;
    }
    &:visited,&:hover,&:focus{
      text-decoration: none;
    }

    #{$self}__label {
      text-align: center;
      font: normal normal 0.75rem/14px $font-family-base;
      letter-spacing: 0;
      color: #000000;
      opacity: 1;
    }

    #{$self}__icon {
      font-size: 1.22rem !important;
      vertical-align: middle;
      padding-left: 5px;
      padding-right: 5px;
      color: #000;
      height: 1.2rem;
    }

    &-tag {
      border-color: $grey !important;
      background-color: $white !important;
      font-weight: 700;
      color: unquote("rgba(0,0,0 0.65)") !important;

      &:hover, &.active {
        color: $white !important;
        background-color: $grey !important;
        border-color: $grey !important;
      }
      &.read-only{
        cursor: default;
      }
    }
  }
}

.card, .card-body, .card-footer {
  background-color: #FFF !important;
  border-width: 0 !important;
}

//
// Header navs
//
.card-header-tabs {
  margin-right: -$card-spacer-x / 2;
  margin-bottom: -$card-spacer-y;
  margin-left: -$card-spacer-x / 2;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -$card-spacer-x / 2;
  margin-left: -$card-spacer-x / 2;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-radius($card-inner-border-radius);
}

// Card image caps
.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-top-radius($card-inner-border-radius);
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-bottom-radius($card-inner-border-radius);
}


.masony {
  float: left;

  img {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    width: calc(50% - 16px);
  }

  @include media-breakpoint-up(xl) {
    width: calc(33% - 16px);
  }
}

