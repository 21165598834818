.comment-section {
  $m-left: 10px; // affects space between comments and their container, and between .avatar and .comment-inner
  $m-right: 10px; // only affects space between comments and their container
  $m-bottom: 18px; // aka the space between comment blocks
  $extra-new: 5px; // extra bottom space on .new-comment, and top and bottom space on .new-reply
  $small-text: 12px;
  .avatar {
    flex: 0 0 50px;
    padding: 5px;
    span.fake,img.fake {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
  .smaller-avatar {
    flex: 0 0 40px;
    padding: 5px;
    span.fake,img.fake {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }

  //.comment-right , // Changing to comment-inner, @deprecating comment-right
  .comment-inner{
    flex: 1 0 0;
    min-width: 0;
    //overflow: hidden; // Not sure this is, affects the pv-help, causing it to partially hidden
    overflow-wrap: break-word;

    margin-bottom: $m-right;

    .comment-outline {
      border-radius: 0 10px 10px;
      padding: 15px;
      margin: 2px 0;
      border: 1px solid black;
      width: 100%;
      font-size: 14px;
      line-height: 1.5;
      height: 100%;
    }

    .before-text {
      flex-wrap: wrap;

      margin-bottom: 2px;
      .author {
        font-weight: bold;
        font-size: 14px;
      }
      .submitted {
        color: grey;
        font-size: 14px;
      }

      .btn-report-comment {
        text-decoration: underline;
        font-size: $small-text;
        color: black;
        &:hover {
          cursor: pointer;
          color: #3292D8;
        }
      }
    }
    .text {
      border-radius: 0 10px 10px;
      padding: 15px;
      margin: 0;
      background-color: #f5f5f5;
      font-size: 14px;
    }
    .after-text {
      margin-top: 4px;
      margin-bottom: $m-bottom;
      width: max-content;
      font-size: $small-text;

      img {
        position: relative;
        top: -1px;

        &.icon-16 {
          height: 1rem;
          width: 1rem;
        }
      }
    }

    .comment-button-bottom {
      font-size: $small-text;
      margin-left: $m-left;
    }
    .comment-edit, .reply-edit {
      margin-bottom: calc(#{$m-bottom} + #{$extra-new});
    }
  }
  .load-more {
    cursor: pointer;
    margin-bottom: 5px;
  }
  .new-comment {
    align-items: flex-start;
    margin: 0 $m-right calc(#{$m-bottom} + #{$extra-new}) $m-left;
  }
  .single-comment {
    align-items: flex-start;
    margin: 0 $m-right 0 $m-left;
  }
  .new-reply {
    align-items: flex-start;
    margin-top: calc(#{$m-bottom} + #{$extra-new});
    margin-bottom: calc(#{$m-bottom} + #{$extra-new});
  }
  .single-reply {
    align-items: flex-start;
  }
  form {
    .form-group {
      margin-bottom: 1rem;
    }
    textarea {
      resize: none;
      box-shadow: none;
      overflow-x: hidden; /* for Firefox */
    }
    .comment-button-bottom {
      flex-wrap:wrap;
      justify-content:flex-end;
      align-items:center;
      flex-direction:row-reverse;
      display:none;
      >div, .anonymously_cb {
        height: max-content;
        display: flex;
      }
      label {
        padding-left: .5rem;
      }
      .anonymously_cb {

      }
      button {
        border: none;
        padding: 4px 20px;
        border-radius: 5px;
        margin-right: 20px;
        color:white;
        background-color:#0073B1;
        flex-direction:row-reverse;
        &:hover {
          background-color: #707070;
        }
      }
    }
  }
}