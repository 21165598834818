/* Tiny MCE word count text. */
@media screen and (max-width: 490px) and (min-width: 371px){
	.mce-container-body.mce-flow-layout .mce-wordcount{
		font-size: 80% !important;
	}
	
	.mce-container-body.mce-flow-layout .mce-path-item{
		font-size: 100%;
	}
}

@media screen and (max-width: 370px){
	.mce-container-body.mce-flow-layout .mce-wordcount{
		font-size: 64% !important;
	}
	
	.mce-container-body.mce-flow-layout .mce-path-item{
		font-size: 80%;
	}
}