/*
  Comments
 */

.askus {
  .comment {
    margin-bottom: 20px;
  }

  .askus-comment-edit-action,
  .askus-report-abuse-action {
    color: $askus-color;
    font: inherit;
    font-size: 85%;
    font-weight: 500;
    text-decoration: none;
  }
}