#story-form {
  margin-top: 50px;
  h4{
    line-height: 1.225;
  }
  .form-group {
    .mce-panel{
      font-size: 16px;
      label{
        font-weight: 500;
        font-size: 1rem;
      }
    }
    label:not(.filepond--drop-label label) {
      text-align: left;
      font-size: 1rem;
      font-weight: bold;
      line-height: 24px;
      letter-spacing: 0;
      color: #000000;
      opacity: 1;

      span {
        color: #1071B1;
        &.filepond--label-action{
          color: #1071B1;
        }
      }
    }

    .file-loading{
      a.filepond--credits{
        display: none;
      }
    }

    .custom-file,
    input:not(.form-check-input){
      //border: 1px solid $ligh-gray;
      border-radius: 3px;
      color: $ligh-gray;
      font-weight: 400 !important;
      height: $story-control-height;
      width: 100%;
      font-size: 14px;
      line-height: 23px;

      &::-webkit-input-placeholder {
        color: $ligh-gray;
      }

      &:-ms-input-placeholder {
        color: $ligh-gray;
      }

      &::-ms-input-placeholder {
        color: $ligh-gray;
      }

      &::placeholder {
        color: $ligh-gray;
      }
    }
    &.form-check{
      input{
        width: 1.2rem;
        height: 1.2rem;
        vertical-align: sub;
      }
      label{
        padding: 0 10px;
      }
    }
    textarea {
      border: 1px solid $ligh-gray;
      border-radius: 3px;
    }
    input:focus, textarea:focus {
      font-size: 16px;
    }
    .mce-panel *{
      font-size: 16px;
    }
    #story-form .form-group .mce-panel .mce-container-body{
      font-size : 16px;
    }
    /* disable auto-zoom on iphone input field focus */
    @media screen and (-webkit-min-device-pixel-ratio:0) {

      select:focus,
      textarea:focus,
      input:focus {
        font-size: 16px;
      }
    }
  }

  .custom-file,
  .custom-select {
    height: 40px !important;
    color: $ligh-gray !important;
    border: 1px solid $ligh-gray;
    border-radius: 3px !important;
    font-size: 14px;
    line-height: 23px;
    width: 100%;
  }

  .input-group {
    height: 50px !important;
    line-height: 50px;
  }

  .file-preview {
    border-radius: 3px;
    border: 1px solid $ligh-gray;
    padding: 5px;
    width: 100%;
    height: 100% !important;
    margin-bottom: 10px;
  }

  .file-drop-zone {
    margin: 0 !important;
    border: 1px dashed $ligh-gray !important;
  }

  .file-drop-zone-title {
    color: $ligh-gray !important;
    font-size: 24px;
    line-height: 190px;
    cursor: default;
  }

  .form-control {
    display: block;
    width: 100%;
    //padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: $story-control-height;
    color: $ligh-gray;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $ligh-gray;
    border-radius: 3px !important;
    &.file-caption {
      width: auto;
      height: 50px;
      .file-caption-name {
        width: 100%;
        margin: 0;
        padding: 0;
        box-shadow: none;
        border: none;
        background: none;
        outline: none;
      }
    }
  }

  .btn-file input[type=file] {
    background-color: #1071B1 !important;
  }

  .btn {
    padding: .5rem 20px !important;
    cursor: pointer !important;
  }
  .btn-success{
    background-color: $success !important;
    color: #fff;
  }

  .btn.btn-primary.btn-file {
    background-color: #1071B1 !important;
    line-height: 50px;
    cursor: pointer !important;

    .hidden-xs {
      padding: 0;
      margin: 0;
      line-height: 50px;
      cursor: pointer !important;
    }
  }

  .fileinput-upload-button {
    display: none !important;
  }

  .fileinput-remove-button {
    background-color: red !important;
  }

  .button-group {
    margin-top: 30px;
    display: block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &.text-center > .primary-button {
      display: inline-block;
    }

    .primary-button {
      background-color: #4169a4;
      border: none;
      border-radius: 3px;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
      color: #fff;
      cursor: pointer;
      font-weight: 600;
      line-height: 60px;
      height: 60px;
      outline: none;
      transition: all 0.2s ease;
      text-align: center;
      text-decoration: none;
      width: 270px;

      -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;

      &:focus {
        outline: none;
      }

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.7;

      }
    }

    .save-as-draft {
      border: none;
      cursor: pointer;
      margin-left: 50px;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  // button group end

  .select2-container .select2-search__field {
    margin-top: 0px !important;
  }

  .select2-container--default .select2-selection--multiple {
    border-color: $ligh-gray;
    height: auto;
  }

  .custom-file {
    height: $story-control-height;
    line-height: $story-control-height !important;
    font-size: 1rem;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    .custom-file-input{
      position: relative;
      z-index: 2;
      margin: 0;
      opacity: 0;
    }

    input[type="file"] {
      border: none;
    }

    input[lang="en"] + label:after{
      content: 'Browse...';
      color: #fff;
      background-color: $dark !important;
    }

    input[lang="fr"] + label:after{
      content: "Feuilleter...";
      color: #fff;
      background-color: $dark !important;
    }

    label.custom-file-label {
      height: $story-control-height - 2;
      border: none !important;
      line-height: $story-control-height !important;
      overflow: hidden;
      padding: 0 0.75rem;
      white-space: nowrap;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      border-radius: .25rem;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      display: inline-block;
      margin-bottom: .5rem;
      &::after{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        border-left: inherit;
        border-radius: 0 .25rem .25rem 0;
        padding: .375rem .75rem;
      }

      &:after {
        align-items: center;
        background-color: #4169A4 !important;
        color: #fff;
        display: flex;
        font-size: initial;
        height: $story-control-height;
        justify-content: center;
        line-height: $story-control-height !important;
        text-align: center;
        width: 117px;
      }

      .filename {
        width: 73%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0;
        color: #5F5F5F;
        vertical-align: sub;

        @include media-breakpoint-down(sm){
          width: 60%
        }
      }

      button{
        position: absolute;
        //top: 10px;
        z-index: 500;
        @include media-breakpoint-down(sm){
          right: 31%
        }
        @include media-breakpoint-up(sm) { right: 22% }
        @include media-breakpoint-up(md) { right: 30% }
        @include media-breakpoint-up(lg) { right: 25% }
        @include media-breakpoint-up(xl) { right: 22% }
      }
    }
  }
  #submit-btn{
    border: 0;
    background-color: transparent;
  }

  input, select,textarea,.mce-panel{
    font-size: 16px !important;
  }
  .file-remove-image{
    img{
      width: 150px;
      height : 150px;
    }
  }
  @include media-breakpoint-down(md){
    .file-remove-image{
      img{
        max-width: 100%;
      }
    }
  }
}

.btn-story-primary{
  background-color: #2b7c82 !important;
  color: #fff !important;
}

