.password-reset-page  {

  .form-group div:first-child{
    padding-right: 0.2rem;
  }
  .form-group div:last-child{
    padding-left: 0.2rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    .form-group {
      .col-xs-12 {
        padding-left: 0;
        padding-right: 0;
      }

      label.required:before {
        margin-left: 0;
      }
    }
  }
}